import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Issue } from '../models/issue.model';


@Component({
  selector: 'app-create-issue-tracker-dialog',
  templateUrl: './issue-tracker-detail-dialog.component.html',
  styleUrls: ['./issue-tracker-detail-dialog.component.scss']
})
export class IssueDetailDialogComponent implements OnInit {

  formattedIssue: string;
  copied: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {issue: Issue},
              private dialogRef: MatDialogRef<IssueDetailDialogComponent>) {}

  ngOnInit() {
    this.formattedIssue = `${this.data.issue.subject}\n\n` +
    `Actual Results:\n${this.data.issue.actualResults}\n\n` +
    `Expected Results:\n${this.data.issue.expectedResults}\n\n` +
    `Steps to Reproduce:\n${this.data.issue.stepsToReproduce}\n\n`;

    if (this.data.issue.operatingSystem) {
      this.formattedIssue += `Operating System: ${this.data.issue.operatingSystem.join(', ')}\n`
    }
    if (this.data.issue.browser) {
      this.formattedIssue += `Browser: ${this.data.issue.browser.join(', ')}\n`
    }
    if (this.data.issue.environmentDetails) {
      this.formattedIssue += `Environment Details: ${this.data.issue.environmentDetails}\n`
    }
    this.formattedIssue += `\nReported By: ${this.data.issue.reportedByName}\n\n`
    this.formattedIssue += `Issue Tracker: https://teneralogin.ca/issue-tracker/view/${this.data.issue.id}`
  }

  async onClickCopy() {
    navigator.clipboard.writeText(this.formattedIssue);
    this.copied = true;
  }

}
