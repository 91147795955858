import { SortObj } from '../listview-state.model';

/**
 * Action dispatched to set search filter
 */
export class SetUserFilter {
  static type = '[UserListview] SetUserFilter';
  constructor(public filter: string) {}
}

/**
 * Action dispatched to set page index
 */
export class SetUserPageIndex {
  static type = '[UserListview] SetUserPageIndex';
  constructor(public pageIndex: number) {}
}

/**
 * Action dispatched to set page size
 */
export class SetUserPageSize {
  static type = '[UserListview] SetUserPageSize';
  constructor(public pageSize: number) {}
}

/**
 * Action dispatched to set sort order
 */
export class SetUserSort {
  static type = '[UserListview] SetUserSort';
  constructor(public sort: SortObj) {}
}

/**
 * Action dispatched to reset the UserListView state to default
 */
export class ResetUserListView {
  static type = '[UserListview] ResetUserListView';
}
