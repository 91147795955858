import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-change-confirm-dialog',
  templateUrl: './change-confirm-dialog.component.html',
  styleUrls: ['./change-confirm-dialog.component.scss']
})
export class ChangeConfirmDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, message: string, confirm?: string},
    private dialogRef: MatDialogRef<ChangeConfirmDialogComponent>) {}

  onChange() {
    this.dialogRef.close(true);
  }

}


