import { AuthState } from './auth/auth.state';
import { ConsoleState } from './console/console.state';
import { IssueTrackerListViewState } from './listviews/issue-tracker/issue-tracker-listview.state';
import { LocationListViewState } from './listviews/location/location-listview.state';
import { RoleListViewState } from './listviews/role/role-listview.state';
import { SupportRequestListViewState } from './listviews/support-request/support-request-listview.state';
import { UnitListViewState } from './listviews/unit/unit-listview.state';
import { UserListViewState } from './listviews/user/user-listview.state';
import { VenueListViewState } from './listviews/venue/venue-listview.state';
import { VenuesState } from './venues/venues.state';
import { WearableListViewState } from './listviews/wearable/wearable-listview.state';
import { SensorsState } from './sensors/sensors.state';
import { NcsChannelsState } from './ncsChannels/ncsChannels.state';

export const States = [
  AuthState,
  ConsoleState,
  VenuesState,
  LocationListViewState,
  RoleListViewState,
  SupportRequestListViewState,
  IssueTrackerListViewState,
  UserListViewState,
  VenueListViewState,
  UnitListViewState,
  WearableListViewState,
  SensorsState,
  NcsChannelsState,
];
