<form [formGroup]='createOrganizationForm' (ngSubmit)='submit(createOrganizationForm)'>
  <h1 mat-dialog-title>Create Organization</h1>

  <mat-dialog-content>
    <!-- Name field -->
    <mat-form-field appearance="outline">
      <input matInput formControlName="name" placeholder="Enter an organization name" required>
    </mat-form-field>

    <!-- Parent field -->
    <mat-form-field appearance="outline">
        <mat-label>Please select a parent organization</mat-label>
        <mat-select formControlName="parent" required>
          <mat-option *ngFor="let parent of parentOptions" [value]="parent.id">
            {{parent.name}}
          </mat-option>
        </mat-select>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button type='submit' [disabled]="!createOrganizationForm.valid">Create</button>
    <button mat-button type='button' mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</form>
