import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import jwt_decode from "jwt-decode";
import { Navigate } from '@ngxs/router-plugin';
import { Router } from '@angular/router';
import { State, Selector, StateContext, Action, NgxsOnInit } from '@ngxs/store';

import * as AuthActions from './auth-state.actions';
import { AuthStateModel } from './auth-state.model';
import { AuthUtils } from './auth.utils';
import { LoginService } from '../../services/login.service';
import { OrganizationsService } from './../../services/organizations.service';
import { Permissions } from '../../models/permissions.model';
import { ResetConsoleState, SetPermissions } from '../console/console-state.actions';
import { ResetVenuesState } from '../venues/venues-state.actions';
import { ResetLocationListView } from '../listviews/location/location-listview-state.actions';
import { ResetRoleListView } from '../listviews/role/role-listview-state.actions';
import { ResetSupportRequestListView } from '../listviews/support-request/support-request-listview-state.actions';
import { ResetUserListView } from '../listviews/user/user-listview-state.actions';
import { ResetVenueListView } from '../listviews/venue/venue-listview-state.actions';


/**
 * State class for authentication details
 */
@Injectable()
@State<AuthStateModel>({
  name: 'auth'
})
export class AuthState implements NgxsOnInit  {

  /**
   * Get the JWT token
   *
   * @returns AuthState.token
   */
  @Selector()
  static token(state: AuthStateModel): string {
    return state.token;
  }

  /**
   * Get the Firebase UID of the logged in user
   *
   * @returns AuthState.userId
   */
  @Selector()
  static userId(state: AuthStateModel): string {
    return state.userId;
  }

  /**
   * Get the name of the logged in user
   *
   * @returns AuthState.name
   */
  @Selector()
  static accountName(state: AuthStateModel): string {
    return state.name;
  }

  constructor(
    private afs: AngularFirestore,
    private firebaseAuth: AngularFireAuth,
    private loginService: LoginService,
    private organizationsService: OrganizationsService,
    private route: Router) {}

  /**
   * Dispatch an AutoAuth Action on startup
   */
  ngxsOnInit(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch(new AuthActions.AutoAuth());
  }

  /**
   * Action handler - dispatched on startup to determine whether
   *  a user is already logged in
   */
  @Action(AuthActions.AutoAuth)
  async autoAuth(ctx: StateContext<AuthStateModel>) {
    const authInformation = AuthUtils.getAuthData();
    if (authInformation) { // auth data found in local storage
      const expiresIn = authInformation.expirationDate.getTime() - new Date().getTime();
      // only proceed if token has not expired
      if (expiresIn > 0 ) {
        this.loginService.setAuthTimer(authInformation.expirationDate);
        ctx.dispatch(new AuthActions.AuthSuccess({
          token: authInformation.token,
          refreshToken: authInformation.refreshToken,
          expirationDate: authInformation.expirationDate,
          userId: authInformation.userId
        }));
      } else {
        ctx.dispatch(new AuthActions.AutoAuthFailed());
      }
    }
  }

  /**
   * Action handler - initiate console login
   */
  @Action(AuthActions.Login)
  async login(ctx: StateContext<AuthStateModel>, { payload }: AuthActions.Login) {
    try {
      const userData = await this.firebaseAuth.signInWithEmailAndPassword(payload.username, payload.password);
      const idTokenResult = await userData.user.getIdTokenResult(true);
      const token = idTokenResult.token;
      const userId = userData.user.uid;
      const refreshToken = userData.user.refreshToken;
      const expirationDate = this.loginService.getExpirationDateFromDate(idTokenResult.expirationTime);
      this.loginService.setAuthTimer(expirationDate);

      ctx.dispatch(new AuthActions.AuthSuccess({
        token,
        refreshToken,
        expirationDate,
        userId
      }));
    } catch (error) {
      ctx.dispatch(new AuthActions.LoginFailed());
    }
  }

  /**
   * Action handler - initiate token refresh
   */
  @Action(AuthActions.Refresh)
  async refresh(ctx: StateContext<AuthStateModel>) {
    try {
      // console.log('refreshing token...');
      const token = await (await this.firebaseAuth.currentUser).getIdToken(true);
      const expirationDate = this.loginService.getExpirationDateFromSeconds(3600);
      this.loginService.setAuthTimer(expirationDate);
      ctx.dispatch(new AuthActions.RefreshSuccess({
        token,
        expirationDate
      }));

    } catch (error) {
      console.log(error);
      ctx.dispatch([
        new AuthActions.Logout()
      ]);
    }
  }

  /**
   * Action handler - initiate console logout
   */
  @Action(AuthActions.Logout)
  async logout(ctx: StateContext<AuthStateModel>) {
    this.loginService.logout();
    AuthUtils.clearAuthData();
    ctx.setState({});
    ctx.dispatch([
      new ResetConsoleState(),
      new ResetVenuesState(),
      new ResetLocationListView(),
      new ResetRoleListView(),
      new ResetSupportRequestListView(),
      new ResetUserListView(),
      new ResetVenueListView(),
      new AuthActions.LoginRedirect()
    ]);
    await this.firebaseAuth.signOut();
  }

  /**
   * Action handler - clean up after expired or
   * invalid data in local storage
   */
  @Action(AuthActions.AutoAuthFailed)
  async onAutoAuthFailed(ctx: StateContext<AuthStateModel>) {
    AuthUtils.clearAuthData(); // remove auth data from local storage
    this.loginService.clearStorage();
    await this.firebaseAuth.signOut(); // sign out of firebase
    ctx.dispatch(new AuthActions.LoginRedirect());
  }

  /**
   * Action handler - setup authorization context based on access token
   */
  @Action(AuthActions.AuthSuccess)
  async onAuthSuccess(ctx: StateContext<AuthStateModel>, { payload }: AuthActions.AuthSuccess) {

    AuthUtils.saveAuthData(payload.token, payload.refreshToken, payload.expirationDate, payload.userId); // save to local storage
    const decodedToken = jwt_decode(payload.token) as any;

    ctx.setState({ // set auth state
      token: payload.token,
      refreshToken: payload.refreshToken,
      userId: payload.userId,
      name: decodedToken.name
    });

    // get user custom claims
    const userOrganization = decodedToken.organization;
    const userLocationAccess = (decodedToken.locationAccess) ? decodedToken.locationAccess : [];
    let hasMonitoringAccess = true;
    let hasVenueAccess = true;
    let hasUserAccess = true;

    try {
      const roleDoc = await this.afs.firestore.collection('organizations/' + userOrganization + '/roles').doc(decodedToken.role).get();
      const permissions: Permissions = {
        permissions: roleDoc.data().permissions,
        role: decodedToken.role,
        userLocationAccess,
        userOrganization
      } as Permissions;

      // check for admin-console permission
      if (permissions.permissions.find(el => el.indexOf('admin-console') > -1) !== undefined) {
        ctx.dispatch(new SetPermissions(permissions)); // store permissions in ConsoleState
        this.organizationsService.fetchOrganizations(userOrganization, userLocationAccess);
      } else {
        ctx.dispatch([
          new AuthActions.Logout(),
          new AuthActions.LoginFailed()
        ]);
      }

      hasMonitoringAccess = (permissions.permissions.find(el => el.indexOf('monitoring-editor') > -1)) ? true : false;
      hasVenueAccess = (permissions.permissions.find(el => el.indexOf('venue-editor') > -1)) ? true : false;
      hasUserAccess = (permissions.permissions.find(el => el.indexOf('venue-editor') > -1)) ? true : false;

    } catch (error) {
      console.log(error);
      ctx.dispatch(new AuthActions.Logout());
    }
    // redirect after successful login
    if (this.route.url === '/login' || this.route.url.indexOf('/authorize') === 0) {
      // set default url based on permissions
      let defaultUrl = '/account';
      if (hasMonitoringAccess) {
        defaultUrl = '/monitoring';
      } else if (hasVenueAccess) {
        defaultUrl = '/venues/list';
      } else if (hasUserAccess) {
        defaultUrl = '/users';
      }
      // if a deep link was passed grab it from storage and redirect to it
      // (this is set in the auth guard)
      let redirectUrl = localStorage.getItem('redirectUrl');
      if (!redirectUrl || redirectUrl === '/login')  {
        redirectUrl = defaultUrl;
      } else {
        localStorage.removeItem('redirectUrl');
      }
      ctx.dispatch(new Navigate([redirectUrl]));
    }
  }

  /**
   * Action handler - refresh authorization context based on access token
   */
@Action(AuthActions.RefreshSuccess)
  async onRefreshSuccess(ctx: StateContext<AuthStateModel>, { payload }: AuthActions.RefreshSuccess) {
    AuthUtils.refreshAuthData(payload.token, payload.expirationDate); // update local storage
    ctx.setState({
      ...ctx.getState(),
      token: payload.token
    }); // update auth state
  }

  /**
   * Action handler - redirect the user to the console Login view
   */
@Action(AuthActions.LoginRedirect)
onLoginRedirect(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch(new Navigate(['/login']));
  }



}
