import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Select } from '@ngxs/store';

import { Geofence } from './../models/geofence.model';
import { GeofencesState } from './../state/geofences.state';

@Component({
  selector: 'app-modify-geofence-dialog',
  templateUrl: './modify-geofence-dialog.component.html',
  styleUrls: ['./modify-geofence-dialog.component.scss']
})
export class ModifyGeofenceDialogComponent {

  // Observables on state selectors
  @Select(GeofencesState.selectedGeofences) selectedGeofences$: Observable<Geofence[]>;

  constructor(private dialogRef: MatDialogRef<ModifyGeofenceDialogComponent>) { }

  /**
   * Respond to edit details button pressed on the modify geofence dialog
   *
   * @param geofenceId The unique database object identifier
   * @param geofenceName The geofence name
   * @param geofenceType The geofence type
   * @param activity The geofence activity
   */
  onEditDetails(geofenceId: number, geofenceName: string, geofenceType: string, activity: string): void {
    this.dialogRef.close({
      action: 'details',
      geofenceId,
      geofenceName,
      geofenceType,
      activity
    });
  }

  /**
   * Respond to edit geometry button pressed on the modify geofence dialog
   *
   * @param geofenceId The unique database object identifier
   */
  onEditGeometry(geofenceId: number): void {
    this.dialogRef.close({
      action: 'geometry',
      geofenceId
    });
  }
  

  /**
   * Respond to duplicate geometry button pressed on the modify geofence dialog
   *
   * @param geofenceId The unique database object identifier
   */
  onDuplicateGeometry(geofenceId: number): void {
    this.dialogRef.close({
      action: 'duplicate',
      geofenceId
    });
  }

  /**
   * Respond to delete button pressed on the modify geofence dialog
   *
   * @param geofenceId The unique database object identifier
   */
  onDeleteGeofence(geofenceId: number): void {
    this.dialogRef.close({
      action: 'delete',
      geofenceId
    });
  }


}
