import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SettingsService {
  private app: any;
  private layout: any;
  private sidebarChangedListener = new Subject<boolean>();

  constructor() {

    // App Settings
    // -----------------------------------
    this.app = {
      name: 'Tenera Admin Console',
      description: 'Tenera Admin Console',
      year: ((new Date()).getFullYear())
    };

    // Layout Settings
    // -----------------------------------
    this.layout = {
      isFixed: true,
      isCollapsed: false,
      isBoxed: false,
      isRTL: false,
      isFloat: false,
      asideHover: false,
      theme: null,
      asideScrollbar: false,
      isCollapsedText: false,
      useFullLayout: false,
      hiddenFooter: false,
      asideToggled: true,
      viewAnimation: 'ng-fadeInUp'
    };
  }

  getSidebarChangedListener() {
    return this.sidebarChangedListener.asObservable();
  }

  getAppSetting(name) {
    return name ? this.app[name] : this.app;
  }

  getLayoutSetting(name) {
    return name ? this.layout[name] : this.layout;
  }

  setAppSetting(name, value) {
    if (typeof this.app[name] !== 'undefined') {
      this.app[name] = value;
    }
  }

  setLayoutSetting(name, value) {
    if (typeof this.layout[name] !== 'undefined') {
      return this.layout[name] = value;
    }
  }

  toggleSidebar() {
    this.layout.isCollapsed = !this.layout.isCollapsed;
    this.sidebarChangedListener.next({...this.layout.isCollapsed});
  }

  toggleLayoutSetting(name) {
    return this.setLayoutSetting(name, !this.getLayoutSetting(name));
  }

}
