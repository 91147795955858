import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import * as UserListViewActions from './user-listview-state.actions';
import { ListViewStateModel } from '../listview-state.model';

/**
 * State class for list views
 */
@Injectable()
@State<ListViewStateModel>({
  name: 'userListview',
  defaults: {
    filter: '',
    pageIndex: 0,
    pageSize: 10,
    sort: { active: 'displayName', direction: 'asc' }
  }
})
export class UserListViewState {

  /**
   * Get the filter string applied to the user list
   *
   * @returns UserListViewState.filter
   */
  @Selector()
  static filter(state: ListViewStateModel): string {
    return state.filter;
  }

  /**
   * Get the sort property applied to the user list
   *
   * @returns UserListViewState.sort.active
   */
  @Selector()
  static sortActive(state: ListViewStateModel): string {
    return state.sort.active;
  }

  /**
   * Get the sort direction applied to the user list
   *
   * @returns UserListViewState.sort.direction
   */
  @Selector()
  static sortDirection(state: ListViewStateModel): string {
    return state.sort.direction;
  }

  /**
   * Get the page index of the user list
   *
   * @returns UserListViewState.pageIndex
   */
  @Selector()
  static pageIndex(state: ListViewStateModel): number {
    return state.pageIndex;
  }

  /**
   * Get the page size of the user list
   *
   * @returns UserListViewState.pageIndex
   */
  @Selector()
  static pageSize(state: ListViewStateModel): number {
    return state.pageSize;
  }

  /**
   * Action handler - set filter
   */
  @Action(UserListViewActions.SetUserFilter)
  onSetUserFilter(ctx: StateContext<ListViewStateModel>, { filter }: UserListViewActions.SetUserFilter) {
    ctx.patchState({ filter });
  }

  /**
   * Action handler - set page index
   */
  @Action(UserListViewActions.SetUserPageIndex)
  onSetUserPageIndex(ctx: StateContext<ListViewStateModel>, { pageIndex }: UserListViewActions.SetUserPageIndex) {
    ctx.patchState({ pageIndex });
  }

  /**
   * Action handler - set page size
   */
  @Action(UserListViewActions.SetUserPageSize)
  onSetUserPageSize(ctx: StateContext<ListViewStateModel>, { pageSize }: UserListViewActions.SetUserPageSize) {
    ctx.patchState({ pageSize });
  }

  /**
   * Action handler - set sort
   */
  @Action(UserListViewActions.SetUserSort)
  onSetUserSort(ctx: StateContext<ListViewStateModel>, { sort }: UserListViewActions.SetUserSort) {
    ctx.patchState({ sort });
  }

  /**
   * Action handler - reset listview
   */
  @Action(UserListViewActions.ResetUserListView)
  onResetUserListView(ctx: StateContext<ListViewStateModel>) {
    ctx.setState({
      filter: '',
      pageIndex: 0,
      pageSize: 10,
      sort: { active: 'displayName', direction: 'asc' }
    });
  }

}
