import { SortObj } from '../listview-state.model';

/**
 * Action dispatched to set search filter
 */
export class SetLocationFilter {
  static type = '[LocationListView] SetLocationFilter';
  constructor(public filter: string) {}
}

/**
 * Action dispatched to set page index
 */
export class SetLocationPageIndex {
  static type = '[LocationListView] SetLocationPageIndex';
  constructor(public pageIndex: number) {}
}

/**
 * Action dispatched to set page size
 */
export class SetLocationPageSize {
  static type = '[LocationListView] SetLocationPageSize';
  constructor(public pageSize: number) {}
}

/**
 * Action dispatched to set sort order
 */
export class SetLocationSort {
  static type = '[LocationListView] SetLocationSort';
  constructor(public sort: SortObj) {}
}

/**
 * Action dispatched to reset the LocationListView state to default
 */
export class ResetLocationListView {
  static type = '[LocationListView] ResetLocationListView';
}
