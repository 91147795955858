import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';

import { ResetSensorsState } from './../../state/sensors/sensors-state.actions';
import { Sensor } from '../../models/sensor.model';
import { SensorsService } from './../../services/sensors.service';
import { SensorsState } from './../../state/sensors/sensors.state';
import { ConsoleState } from '../../state/console/console.state';

const FIRMWARE_PERMISSION = 'sensor-firmware-viewer';
const LATEST_FIRMWARE_VERSION = '2.1.2';

@Component({
  selector: 'app-auto-assign-sensor-dialog',
  templateUrl: './auto-assign-sensor-dialog.component.html',
  styleUrls: ['./auto-assign-sensor-dialog.component.scss']
})
export class AutoAssignSensorDialogComponent implements OnInit, OnDestroy {

  // references used in MatTable
  dataSource = new MatTableDataSource<Sensor>();
  displayedColumns: string[];
  stableFirmwareVersion: string;
  latestFirmwareVersion: string;
  displayFirmwareVersion: boolean = false;
  firmwareDisplayNames: Array<{label: string, value: string}>;

  // data subscriptions
  private activatedSensorListSubscription: Subscription;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, message: string},
    private dialogRef: MatDialogRef<AutoAssignSensorDialogComponent>,
    private sensorsService: SensorsService,
    private store: Store,) {}


  async ngOnInit() {
    this.displayedColumns = ['sensorId', 'reset'];
    if (this.displayFirmwareVersion) {
      this.displayedColumns = ['sensorId', 'firmware', 'reset'];
    }
    this.activatedSensorListSubscription = this.store.select(SensorsState.activatedSensors)
    .subscribe((sensorsList: Sensor[]) => {
      if (this.displayFirmwareVersion) {
        const sensorsWithFw = [];
        for (const sensor of sensorsList) {
          const fwVersion = this.firmwareDisplayNames.find(display => display.value === sensor.metadata?.fw_ver)?.label;
          const updatedSensor = 
          {...sensor, 
            metadata: {...sensor.metadata, fw: fwVersion ? fwVersion : sensor.metadata?.fw_ver === this.stableFirmwareVersion ? '1.x.x' : sensor.metadata?.fw_ver}
          };
          sensorsWithFw.push(updatedSensor);
        }
        this.dataSource.data = sensorsWithFw;
      } else {
        this.dataSource.data = sensorsList;
      }
    });
  }

  /**
   * Highlight the sensor ID if the sensor button
   * was pressed within last 30 seconds
   *
   * @param activationTime The notification priority
   */
  setSensorStyle(activationTime: number) {
    let styles = {};
    const currentTime = new Date().getTime();
    if (currentTime - activationTime < 30000) { // 30000 milliseconds = 30 seconds
      styles = { 'background-color': 'magenta', 'color': 'white'  };
    }
    return styles;
  }

  /**
   * Enable the RESET button if sensor button
   * was pressed within last 30 seconds
   *
   * @param activationTime The notification priority
   */
  setResetButtonVisibile(activationTime: number) {
    const currentTime = new Date().getTime();
    return (currentTime - activationTime) >= 30000;
  }

  /**
   * Reset the sensor data
   *
   * @param sensorId The ID of the selected sensor
   */
  resetSensor(sensorId: string) {
    this.sensorsService.resetSensorData(sensorId);
  }

  /**
   * Respond to a sensor being selected in the dialog
   *
   * @param sensorId The ID of the selected sensor
   */
  onSelect(sensorId) {
    this.dialogRef.close(sensorId);
  }

  ngOnDestroy() {
    if (this.activatedSensorListSubscription) {
      this.activatedSensorListSubscription.unsubscribe();
    }
    this.store.dispatch(new ResetSensorsState());
  }


}
