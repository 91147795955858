import { Location } from './../../models/location.model';
import { Permissions } from '../../models/permissions.model';

/**
 * Action dispatched to set the 'isLoading' attribute
 */
export class SetIsLoading {
  static type = '[Console] SetIsLoading';
  constructor(public isLoading: boolean) {}
}

/**
 * Action dispatched to set the console state with user permissions
 */
export class SetPermissions {
  static type = '[Console] SetPermissions';
  constructor(public permissions: Permissions) {}
}

/**
 * Action dispatched to process a list of organizations
 */
export class ProcessOrganizations {
  static type = '[Console] ProcessOrganizations';
  constructor(public organizationList: string[]) {}
}

/**
 * Action dispatched to set the organizations the user may access
 */
export class SetOrganizations {
  static type = '[Console] SetOrganizations';
  constructor(public organizations: Location[]) {}
}

/**
 * Action dispatched to set the selected organization
 */
export class SetSelectedOrganization {
  static type = '[Console] SetSelectedOrganization';
  constructor(public organizationId: string) {}
}

/**
 * Action dispatched to reset the console state to default
 */
export class ResetConsoleState {
  static type = '[Console] ResetConsoleState';
}
