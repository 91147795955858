import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { AddActivatedSensor, DeleteActivatedSensor, UpdateActivatedSensor } from '../state/sensors/sensors-state.actions';
import { Logout } from '../state/auth/auth-state.actions';
import { Sensor } from '../models/sensor.model';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { VenuesState } from '../state/venues/venues.state';

const API_URL_PREFIX = environment.apiUrl;
const LATEST_FIRMWARE_VERSION = '2.1.2';

@Injectable({providedIn: 'root'})
export class SensorsService implements OnDestroy {

  // Subscription to Logout Action being dispateched
  private logoutSubscription: Subscription;

  // Data subscription used by profile detail components
  private activatedSensorsSubscription: Subscription;

  constructor(
    private actions: Actions,
    private afs: AngularFirestore,
    private http: HttpClient,
    private store: Store) {
      // set up subscription to Logout action
    this.logoutSubscription = this.actions.pipe(ofActionDispatched(Logout)).subscribe(() => {
      this.cancelActivatedSensorsSubscription();
    });
  }

  /**
   * Subscribe to activated sensors for a venue
   *
   * @param venueId The venue ID used to query sensors
   */
  fetchActivatedSensors(venueId: string, excludedCancelDeviceIds: string[]) {
    if (!this.activatedSensorsSubscription || this.activatedSensorsSubscription.closed) {
      const tagsCollection = this.afs.collection<Sensor>('tags',
      ref => ref.where('autoprovision', '==', true).where('metadata.venue', '==', venueId));
    this.activatedSensorsSubscription = tagsCollection.stateChanges()
    .pipe( map( sensors => sensors.map (currentSensor => {
      const data = currentSensor.payload.doc.data() as Sensor;
      const action = currentSensor.type;
      const id = currentSensor.payload.doc.id;
      return { id, action, ...data } ;
    }))).subscribe( (sensors: Sensor[]) => {
      sensors.forEach(sensor => {
        if (!excludedCancelDeviceIds.includes(sensor.id)) {
          if (sensor.action === 'added') {
            this.store.dispatch(new AddActivatedSensor(sensor));
          } else if (sensor.action === 'removed') {
            this.store.dispatch(new DeleteActivatedSensor(sensor.id));
          } else if (sensor.action === 'modified') {
            this.store.dispatch(new UpdateActivatedSensor(sensor));
          }
        }
      });
    }, error => {
      console.error(error);
    });
    }
  }

  /**
   * Get a sensor document from the root tags collection
   *
   * @param docId The ID of the sensor document
   */
  fetchSensorDocument(docId: string) {
    return this.afs.collection('tags').doc(docId).get();
  }

  /**
   * Get the stable sensor firmware version
   */
  async fetchFirmwareData() {
    const metadataDocs = await this.afs.collection<{fw_num: string, fw_url: string}>('firmware-metadata', ref => ref.orderBy('fw_num')).get().toPromise();
    const stableVersion = await this.afs.collection<{fw_ver: string, fw_url: string}>('firmware-metadata').doc('tag-stable').get().toPromise();
    
    const metadata = metadataDocs.docs.map(doc => {
      const fwDisplayName = doc.id.replace('tag-', '').replace('-rc', '').replace('-dev', '');
      return {label: fwDisplayName, value: doc.data().fw_num }
    });
    return {
      stableVersion: stableVersion.exists ? stableVersion.data() : null, 
      metadata: metadata
    };
  }

  /**
   * Get a sensor document from the root tags collection
   *
   * @param sensorId The ID of the sensor document
   */
  async compareFirmwareVersion(sensorId: string) {
    const sensorDoc = await this.afs.collection<{metadata: {fw_ver: string}}>('tags').doc(sensorId).get().toPromise();
    const metadataDocs = await this.afs.collection<{fw_num: string, fw_url: string}>('firmware-metadata', ref => ref.orderBy('fw_num')).get().toPromise();
    const stableVersion = await this.afs.collection<{fw_ver: string, fw_url: string}>('firmware-metadata').doc('tag-stable').get().toPromise();
    
    const metadata = metadataDocs.docs.map(doc => {
      const fwDisplayName = doc.id.replace('tag-', '').replace('-rc', '').replace('-dev', '');
      return {label: fwDisplayName, value: doc.data().fw_num }
    });
    const version = metadata.find(data => data.value === sensorDoc.data()?.metadata?.fw_ver);
    const isStableVersion = stableVersion.exists && sensorDoc.data()?.metadata?.fw_ver === stableVersion.data()?.fw_ver;
    const isLatestVersion = version?.label?.includes(LATEST_FIRMWARE_VERSION);
    return {
      stableVersion: isStableVersion,
      latestVersion: isLatestVersion, 
      fw: version ? version.label : isStableVersion ? '1.x.x' : sensorDoc.data()?.metadata?.fw_ver
    };
  }

  /**
   * Assign a sensor
   *
   * @param docId The sensor document ID
   */
  async assignSensor(docId: string) {
    try {
      const assignURL = API_URL_PREFIX + '/sensors/' + docId + '/assign';
      await this.http.put(assignURL, {}).toPromise();
    } catch (error) {
      console.error('Failed to assign sensor:', error);
    }
  }

  /**
   * Reset assigned sensor fields
   *
   * @param sensorId The sensor document ID
   */
  async resetSensorData(sensorId: string) {
    const venueId = this.store.selectSnapshot(VenuesState.selectedVenueId);
    if (venueId !== 'Playground' && venueId !== 'Development') {
      try {
        const resetUrl = API_URL_PREFIX + '/sensors/' + sensorId + '/reset';
        await this.http.put(resetUrl, {}).toPromise();
      } catch (error) {
        console.error('Failed to assign sensor:', error);
      }
    }
  }

  /**
   * Set sensor document data
   *
   * @param docId The sensor document ID
   * @param sensorData The sensor document content
   */
  private async setSensorData(docId: string, sensorData: {}) {
    try {
      const setUrl = API_URL_PREFIX + '/sensors/' + docId;
      this.http.put(setUrl, sensorData).toPromise();
    } catch (error) {
      console.error('Failed to set sensor data:', error);
    }
  }

  /**
   * Unsubscribe from changes to activated sensors
   */
  cancelActivatedSensorsSubscription() {
    return new Promise((resolve) => {
      if (this.activatedSensorsSubscription && !this.activatedSensorsSubscription.closed) {
        this.activatedSensorsSubscription.unsubscribe();
      }
      resolve(true);
    });
  }

  /**
   * Unsubscribe from all service subscriptions
   */
  ngOnDestroy() {
    this.cancelActivatedSensorsSubscription();
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }


}

