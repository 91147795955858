import { HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + authToken,
    });
    // use this to send binary file
    if (req.headers.has('Content-Type')) {
      headers.append('Content-Type', req.headers.get('Content-Type'));
    } else {
      headers.append('Content-Type', 'application/json; charset=utf-8');
    }

    const cloneReq = req.clone({headers});

    return next.handle(cloneReq);
  }
}
