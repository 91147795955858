import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA,  MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { FloorListItem } from '../../models/floor-list-item.model';
import { Select, Store } from '@ngxs/store';

import { UnitDetailsAddFloorsFormService } from './unit-details-add-floors-form.service';
import { VenuesState } from '../../../../shared/state/venues/venues.state';
import { UnitDetailsState } from '../unit-details/state/unit-details.state';


@Component({
  selector: 'unit-details-add-floors-dialog',
  templateUrl: './unit-details-add-floors-dialog.component.html',
  styleUrls: ['./unit-details-add-floors-dialog.component.scss'],
  providers: [UnitDetailsAddFloorsFormService]
})
export class UnitDetailsAddFloorsDialogComponent implements OnInit {

  @Select(VenuesState.floors) floors$: Observable<FloorListItem[]>;

  form: UntypedFormGroup;
  geofenceTypes: string[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: {geofenceName: string, geofenceType: string},
              private geofenceFormService: UnitDetailsAddFloorsFormService,
              private dialogRef: MatDialogRef<UnitDetailsAddFloorsDialogComponent>,
              private store: Store) {}

  ngOnInit() {
    this.form = this.geofenceFormService.form;
    if (this.data) {
      this.geofenceFormService.populateGeofenceDetail(this.data.geofenceName);
    }
  }

  submit(form) {
    this.dialogRef.close(form.value);
  }


  isFloorAvailable(floorId: string) {
    const available = (!this.store.selectSnapshot(UnitDetailsState.unsavedFloors).find(item => item.floorId === floorId));
    return available;
  }

}
