<form [formGroup]='form' (ngSubmit)='submit(form)'>
  <h1 mat-dialog-title>Unit Floors</h1>

  <mat-dialog-content>
    <mat-form-field appearance="outline">
      <mat-label>Select floors for this unit</mat-label>
      <mat-select 
        formControlName="selectedFloors" 
        multiple
        required>
          <mat-option *ngFor="let listFloor of (floors$ | async)" 
          [disabled]="!isFloorAvailable(listFloor.floorId)"
          [value]="listFloor.floorId"
          >
            {{ listFloor.floorName }}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </mat-dialog-content>
  <br /><br />

  <mat-dialog-actions>
    <button mat-button type='submit' [disabled]="!form.dirty || !form.valid">Add Floors</button>
    <button mat-button type='button' mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</form>
