<div class="image-cropper-container">
  <div class="row">
    <div class="col-lg-4">
      <div class="card">
        <div class="card-body">
          <div class="result" *ngIf="data1.image">
            <p class="pv">Cropped image</p>
            <img class="img-fluid img-thumbnail" [src]="data1.image" [width]="cropperSettings.croppedWidth" [height]="cropperSettings.croppedHeight">
          </div>
        </div>
      </div>
      <div class="btn btn-primary btn-block" *ngIf="data1.image" (click)="onSelect(data1.image)">SELECT</div>
    </div>
    <div class="col-lg-8">
      <div class="card">
        <div class="card-body">
          <img-cropper #cropper [image]="data1" [settings]="cropperSettings" (onCrop)="cropped($event)"></img-cropper>
        </div>
      </div>
    </div>
  </div>
</div>
