import { SortObj } from '../listview-state.model';

/**
 * Action dispatched to set search filter
 */
export class SetRoleFilter {
  static type = '[RoleListView] SetRoleFilter';
  constructor(public filter: string) {}
}

/**
 * Action dispatched to set page index
 */
export class SetRolePageIndex {
  static type = '[RoleListView] SetRolePageIndex';
  constructor(public pageIndex: number) {}
}

/**
 * Action dispatched to set page size
 */
export class SetRolePageSize {
  static type = '[RoleListView] SetRolePageSize';
  constructor(public pageSize: number) {}
}

/**
 * Action dispatched to set sort order
 */
export class SetRoleSort {
  static type = '[RoleListView] SetRoleSort';
  constructor(public sort: SortObj) {}
}

/**
 * Action dispatched to reset the RoleListView state to default
 */
export class ResetRoleListView {
  static type = '[RoleListView] ResetRoleListView';
}
