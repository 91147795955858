import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import * as WearablesListViewActions from './wearable-listview-state.actions';
import { ListViewStateModel } from '../listview-state.model';

/**
 * State class for list views
 */
@Injectable()
@State<ListViewStateModel>({
  name: 'wearableListview',
  defaults: {
    filter: '',
    pageIndex: 0,
    pageSize: 10,
    sort: { active: 'name', direction: 'asc' }
  }
})
export class WearableListViewState {

  /**
   * Get the filter string applied to the wearable list
   *
   * @returns WearableListViewState.filter
   */
  @Selector()
  static filter(state: ListViewStateModel): string {
    return state.filter;
  }

  /**
   * Get the sort property applied to the wearable list
   *
   * @returns WearableListViewState.sort.active
   */
  @Selector()
  static sortActive(state: ListViewStateModel): string {
    return state.sort.active;
  }

  /**
   * Get the sort direction applied to the wearable list
   *
   * @returns WearableListViewState.sort.direction
   */
  @Selector()
  static sortDirection(state: ListViewStateModel): string {
    return state.sort.direction;
  }

  /**
   * Get the page index of the wearable list
   *
   * @returns WearableListViewState.pageIndex
   */
  @Selector()
  static pageIndex(state: ListViewStateModel): number {
    return state.pageIndex;
  }

  /**
   * Get the page size of the wearable list
   *
   * @returns WearableListViewState.pageIndex
   */
  @Selector()
  static pageSize(state: ListViewStateModel): number {
    return state.pageSize;
  }

  /**
   * Action handler - set filter
   */
  @Action(WearablesListViewActions.SetWearableFilter)
  onSetWearablesFilter(ctx: StateContext<ListViewStateModel>, { filter }: WearablesListViewActions.SetWearableFilter) {
    ctx.patchState({ filter });
  }

  /**
   * Action handler - set page index
   */
  @Action(WearablesListViewActions.SetWearablePageIndex)
  onSetWearablesPageIndex(ctx: StateContext<ListViewStateModel>, { pageIndex }: WearablesListViewActions.SetWearablePageIndex) {
    ctx.patchState({ pageIndex });
  }

  /**
   * Action handler - set page size
   */
  @Action(WearablesListViewActions.SetWearablePageSize)
  onSetWearablesPageSize(ctx: StateContext<ListViewStateModel>, { pageSize }: WearablesListViewActions.SetWearablePageSize) {
    ctx.patchState({ pageSize });
  }

  /**
   * Action handler - set sort
   */
  @Action(WearablesListViewActions.SetWearableSort)
  onSetWearablesSort(ctx: StateContext<ListViewStateModel>, { sort }: WearablesListViewActions.SetWearableSort) {
    ctx.patchState({ sort });
  }

  /**
   * Action handler - reset listview
   */
  @Action(WearablesListViewActions.ResetWearableListView)
  onResetWearablesListView(ctx: StateContext<ListViewStateModel>) {
    ctx.setState({
      filter: '',
      pageIndex: 0,
      pageSize: 10,
      sort: { active: 'id', direction: 'asc' }
    });
  }

}
