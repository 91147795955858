import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import * as VenueListViewActions from './venue-listview-state.actions';
import { ListViewStateModel } from '../listview-state.model';

/**
 * State class for list views
 */
@Injectable()
@State<ListViewStateModel>({
  name: 'venueListview',
  defaults: {
    filter: '',
    pageIndex: 0,
    pageSize: 10,
    sort: { active: 'name', direction: 'asc' }
  }
})
export class VenueListViewState {

  /**
   * Get the filter string applied to the venue list
   *
   * @returns VenueListViewState.filter
   */
  @Selector()
  static filter(state: ListViewStateModel): string {
    return state.filter;
  }

  /**
   * Get the sort property applied to the venue list
   *
   * @returns VenueListViewState.sort.active
   */
  @Selector()
  static sortActive(state: ListViewStateModel): string {
    return state.sort.active;
  }

  /**
   * Get the sort direction applied to the user list
   *
   * @returns UserListViewState.sort.direction
   */
  @Selector()
  static sortDirection(state: ListViewStateModel): string {
    return state.sort.direction;
  }

  /**
   * Get the page index of the venue list
   *
   * @returns VenueListViewState.pageIndex
   */
  @Selector()
  static pageIndex(state: ListViewStateModel): number {
    return state.pageIndex;
  }

  /**
   * Get the page size of the venue list
   *
   * @returns VenueListViewState.pageIndex
   */
  @Selector()
  static pageSize(state: ListViewStateModel): number {
    return state.pageSize;
  }

  /**
   * Action handler - set filter
   */
  @Action(VenueListViewActions.SetVenueFilter)
  onSetVenueFilter(ctx: StateContext<ListViewStateModel>, { filter }: VenueListViewActions.SetVenueFilter) {
    ctx.patchState({ filter });
  }

  /**
   * Action handler - set page index
   */
  @Action(VenueListViewActions.SetVenuePageIndex)
  onSetVenuePageIndex(ctx: StateContext<ListViewStateModel>, { pageIndex }: VenueListViewActions.SetVenuePageIndex) {
    ctx.patchState({ pageIndex });
  }

  /**
   * Action handler - set page size
   */
  @Action(VenueListViewActions.SetVenuePageSize)
  onSetVenuePageSize(ctx: StateContext<ListViewStateModel>, { pageSize }: VenueListViewActions.SetVenuePageSize) {
    ctx.patchState({ pageSize });
  }

  /**
   * Action handler - set sort
   */
  @Action(VenueListViewActions.SetVenueSort)
  onSetVenueSort(ctx: StateContext<ListViewStateModel>, { sort }: VenueListViewActions.SetVenueSort) {
    ctx.patchState({ sort });
  }

  /**
   * Action handler - reset listview
   */
  @Action(VenueListViewActions.ResetVenueListView)
  onResetVenueListView(ctx: StateContext<ListViewStateModel>) {
    ctx.setState({
      filter: '',
      pageIndex: 0,
      pageSize: 10,
      sort: { active: 'name', direction: 'asc' }
    });
  }

}
