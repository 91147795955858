import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { CropperSettings, ImageCropperComponent, Bounds } from 'ngx-img-cropper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-image-cropper-dialog',
  templateUrl: './image-cropper-dialog.component.html',
  styleUrls: ['./image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent implements OnInit {
  data1: any;
  cropperSettings: CropperSettings;

  @ViewChild('cropper') cropper: ImageCropperComponent;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {image: File, type: string},
    private dialogRef: MatDialogRef<ImageCropperDialogComponent>) {

    // initiaize cropper settings
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.noFileInput = true;
    this.cropperSettings.rounded = false;

    this.cropperSettings.width = 200;
    this.cropperSettings.height = 200;

    this.cropperSettings.croppedWidth = 200;
    this.cropperSettings.croppedHeight = 200;

    this.cropperSettings.canvasWidth = 460;
    this.cropperSettings.canvasHeight = 400;

    this.cropperSettings.minWidth = 100;
    this.cropperSettings.minHeight = 100;

    this.cropperSettings.cropperDrawSettings.strokeColor = 'rgba(0,0,0,.25)';
    this.cropperSettings.cropperDrawSettings.strokeWidth = 2;

    this.data1 = {};
  }

  ngOnInit() {
    const image: any = new Image();
    const file: File = this.data.image;
    const myReader: FileReader = new FileReader();
    const that = this;
    myReader.onloadend = function(loadEvent: any) {
        image.src = loadEvent.target.result;
        that.cropper.setImage(image);
    };
    myReader.readAsDataURL(file);
  }

  onSelect(imageData) {
    this.dialogRef.close({
      image: imageData,
      type: this.data.type
    });
  }

  cropped(bounds: Bounds) {
  }


}
