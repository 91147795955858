<!-- <form [formGroup]='floorForm' (ngSubmit)='submit()'> -->
  <h1 mat-dialog-title>{{ data.mode }} Floor</h1>

  


   <mat-radio-group aria-labelledby="radio-group-label" class="radio-group-endpoint">
    <mat-radio-button name="endpoint-type" value="geojson" [checked]="endpointType === 'geojson'"
    (click)="onChangeEndpoint('geojson')"  
     >
        <span *ngIf="data.mode === 'Update'">Edit extents and geoJSON</span>
        <span *ngIf="data.mode === 'Create'">With a geoJSON file</span>
    </mat-radio-button>
    <mat-radio-button name="endpoint-type" value="dxf" [checked]="endpointType === 'dxf'"
    (click)="onChangeEndpoint('dxf')"   
    >
        <span *ngIf="data.mode === 'Update'">Process new Magic Plan files</span>
        <span *ngIf="data.mode === 'Create'">From Magic Plan files</span>
    </mat-radio-button>
  </mat-radio-group>

  <hr>

  <ng-container *ngIf="endpointType === 'geojson'">
    <form [formGroup]='floorForm' (ngSubmit)='submit()'>
    <mat-dialog-content>
    <mat-form-field appearance="outline">
      <mat-label>Floor name</mat-label>
      <input matInput formControlName="floorName" autocorrect="off" autocomplete="off" spellcheck="false" required [readonly]="submitting">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Floor number</mat-label>
      <input matInput formControlName="floorNumber" type="number" autocomplete="off" required [readonly]="submitting">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>West extent</mat-label>
      <input matInput formControlName="xMinExtent" type="number" autocomplete="off" required [readonly]="submitting">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>East extent</mat-label>
      <input matInput formControlName="xMaxExtent" type="number" autocomplete="off" required [readonly]="submitting">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>North extent</mat-label>
      <input matInput formControlName="yMaxExtent" type="number" autocomplete="off" required [readonly]="submitting">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>South extent</mat-label>
      <input matInput formControlName="yMinExtent" type="number" autocomplete="off" required [readonly]="submitting">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Floor elevation</mat-label>
      <input matInput formControlName="z_ref" type="number" autocomplete="off" required [readonly]="submitting">
    </mat-form-field>

    <!-- START GeoJSON File Picker -->
    <mat-form-field appearance="outline">
      <mat-icon *ngIf="fileName && !submitting" 
      mat-icon-button type='button' 
      (click)="onRemoveGeoJSON()" 
      class="mat-icon notranslate material-icons mat-icon-no-color mat-warn align-right" 
      mat-icon aria-label="Delete geojson button">delete_forever</mat-icon>
      
      <mat-label>GeoJSON File *</mat-label>
      <input matInput disabled value="{{fileName}}" class="text-field" required>
      
    </mat-form-field>
      <mat-error class="error-msg" *ngIf="!floorForm.controls['geojson'].value && (floorForm.controls['geojson'].dirty || floorForm.controls['geojson'].touched)">
        GeoJSON is required
      </mat-error>

    <div class="btn btn-info btn-block" (click)="filePicker.click()" [ngStyle]="{'pointer-events': submitting ? 'none' : 'auto'}">
      <i *ngIf="submitting" class="fas fa-circle-notch fa-spin submitting"></i> 
      <span *ngIf="data.mode === 'Create' || !floorForm.controls['geojson'].value">Upload Floorplan GeoJSON</span>
      <span *ngIf="data.mode === 'Update' && floorForm.controls['geojson'].value">Replace Floorplan GeoJSON</span>
    </div>
    <input type="file" accept=".geojson" #filePicker (change)="onFilePicked($event)" required>
    <!-- END GeoJSON File Picker -->
    
    <!-- START FloorPlan File Picker -->
    <mat-form-field appearance="outline">
      <mat-icon *ngIf="floorplanFileName && !submitting" 
      mat-icon-button type='button' 
      (click)="onRemoveFloorPlan()" 
      class="mat-icon notranslate material-icons mat-icon-no-color mat-warn align-right" 
      mat-icon aria-label="Delete floorplan button">delete_forever</mat-icon>
      
      <mat-label>Floorplan Image</mat-label>
      <input matInput disabled value="{{floorplanFileName}}" class="text-field">
      
    </mat-form-field>

    <div class="btn btn-info btn-block" (click)="fileFloorplanPicker.click()" [ngStyle]="{'pointer-events': submitting ? 'none' : 'auto'}">
      <i *ngIf="submitting" class="fas fa-circle-notch fa-spin submitting"></i> 
      <span *ngIf="this.data.mode === 'Create' || !(floorplanExists && floorplanFileName)">Upload Floorplan Image</span>
      <span *ngIf="this.data.mode === 'Update' && floorplanExists && floorplanFileName">Replace Floorplan Image</span>
    </div>
    <input type="file" accept=".png" #fileFloorplanPicker (change)="onFloorplanFilePicked($event)">
    <!-- END FloorPlan File Picker -->
   </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button type='submit' [class.spinner]="submitting" [disabled]="!this.floorForm.dirty || !this.floorForm.valid || submitting"><i *ngIf="submitting" class="fas fa-circle-notch fa-spin"></i> {{ data.mode }}</button>
      <button mat-button type='button' (click)="resetForm()" mat-dialog-close [disabled]="submitting">Cancel</button>
    </mat-dialog-actions>
  </form>
  </ng-container>


  <ng-container *ngIf="endpointType === 'dxf'">
    
    <form [formGroup]='floorDxfForm' (ngSubmit)='submitDxf()'>
      <mat-dialog-content>
    <mat-form-field appearance="outline">

      <mat-label>Floor name</mat-label>
      <input matInput formControlName="floorName" autocorrect="off" autocomplete="off" spellcheck="false" required [readonly]="submitting">
    </mat-form-field>
  
    <mat-form-field appearance="outline">
      <mat-label>Floor number</mat-label>
      <input matInput formControlName="floorNumber" type="number" autocomplete="off" required [readonly]="submitting">
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Floor elevation</mat-label>
      <input matInput formControlName="z_ref" type="number" autocomplete="off" required [readonly]="submitting">
    </mat-form-field>
  
    <!-- START dxf File Picker -->
    <mat-form-field appearance="outline">
      <mat-icon *ngIf="dxfFileName && !submitting" 
      mat-icon-button type='button' 
      (click)="onRemoveDxf()" 
      class="mat-icon notranslate material-icons mat-icon-no-color mat-warn align-right" 
      mat-icon aria-label="Delete dxf button">delete_forever</mat-icon>
      
      <mat-label>dxf File *</mat-label>
      <input matInput disabled value="{{dxfFileName}}" class="text-field" required>
      
    </mat-form-field>
      <mat-error class="error-msg" *ngIf="!floorDxfForm.controls['dxf'].value && (floorDxfForm.controls['dxf'].dirty || floorDxfForm.controls['dxf'].touched)">
        dxf is required
      </mat-error>
  
    <div class="btn btn-info btn-block" (click)="fileDxfPicker.click()" [ngStyle]="{'pointer-events': submitting ? 'none' : 'auto'}">
      <i *ngIf="submitting" class="fas fa-circle-notch fa-spin submitting"></i> 
      <span *ngIf="data.mode === 'Create' || !floorDxfForm.controls['dxf'].value">Upload Floorplan dxf</span>
      <span *ngIf="data.mode === 'Update' && floorDxfForm.controls['dxf'].value">Replace Floorplan dxf</span>
    </div>
    <input type="file" accept=".dxf" #fileDxfPicker (change)="onDxfFilePicked($event)" required>
    <!-- END dxf File Picker -->
    
    <!-- START FloorPlan File Picker -->
    <mat-form-field appearance="outline">
      <mat-icon *ngIf="magicPlanPngFileName && !submitting" 
      mat-icon-button type='button' 
      (click)="onRemoveMagicplanFloorPlan()" 
      class="mat-icon notranslate material-icons mat-icon-no-color mat-warn align-right" 
      mat-icon aria-label="Delete floorplan button">delete_forever</mat-icon>
      
      <mat-label>Floorplan Image *</mat-label>
      <input matInput disabled value="{{magicPlanPngFileName}}" class="text-field" required>
      
    </mat-form-field>

    <div class="btn btn-info btn-block" (click)="magicPlanPngPicker.click()" [ngStyle]="{'pointer-events': submitting ? 'none' : 'auto'}">
      <i *ngIf="submitting" class="fas fa-circle-notch fa-spin submitting"></i> 
      <span *ngIf="this.data.mode === 'Create' || !(magicPlanPngExists && magicPlanPngFileName)">Upload Floorplan Png</span>
      <span *ngIf="this.data.mode === 'Update' && magicPlanPngExists && magicPlanPngFileName">Replace Floorplan Png</span>
    </div>
    <input type="file" accept=".png" #magicPlanPngPicker (change)="onMagicPlanPngFilePicked($event)">
    <!-- END FloorPlan File Picker -->
  
    </mat-dialog-content>

    <hr>

    <mat-dialog-actions>
      <button mat-button type='submit' [class.spinner]="submitting" [disabled]="!this.floorDxfForm.dirty || !this.floorDxfForm.valid || submitting"><i *ngIf="submitting" class="fas fa-circle-notch fa-spin"></i> {{ data.mode }}</button>
      <button mat-button type='button' (click)="resetForm()" mat-dialog-close [disabled]="submitting">Cancel</button>
    </mat-dialog-actions>
  </form>
  </ng-container>
