import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { environment } from '../../../environments/environment';
import { Refresh } from '../state/auth/auth-state.actions';

const REVOKE_ENDPOINT = environment.apiUrl + '/authorize/revoke';
const REFRESH_BUFFER_SECONDS = 10;

@Injectable()
export class LoginService {
  private tokenTimer: any;

  constructor(
    private http: HttpClient,
    private store: Store) { }

  /**
   * Force refresh when JWT token expires
   *
   * @param expirationDate The time at which to run a refresh
   */
  setAuthTimer(expirationDate: Date) {
    const duration = (expirationDate.getTime() - (new Date()).getTime()) / 1000;
    // console.log('setting timeout to: ' + duration);
    this.tokenTimer = setTimeout( () => {
      this.store.dispatch(new Refresh());
    }, duration * 1000); // convert to milliseconds
  }

  /**
   * Perform logout cleanup tasks
   */
  logout() {
    // clear the token expiration timer
    if (this.tokenTimer) {
      clearTimeout(this.tokenTimer);
    }

    // revoke tokens from auth services
    this.http.get<any>(REVOKE_ENDPOINT).subscribe();

    // clear local storage
    this.clearStorage();
  }

  /**
   * Change date into expected date format and subtract latency buffer
   */
  getExpirationDateFromDate(date: any) {
    return new Date((new Date(date)).getTime() - REFRESH_BUFFER_SECONDS * 1000);
  }

  /**
   * Change seconds into expected date format and subtract latency buffer
   */
  getExpirationDateFromSeconds(seconds: number) {
    return new Date((new Date()).getTime() + (seconds - REFRESH_BUFFER_SECONDS) * 1000);
  }

  clearStorage() {
    localStorage.removeItem('redirectUrl');
    localStorage.removeItem('selectedOrganization');
    localStorage.removeItem('selectedVenue');
    localStorage.removeItem('selectedFloor');
    localStorage.removeItem('selectedUnit');
  }
}
