import { State, Selector, Action, StateContext, Store } from '@ngxs/store';

import * as GeofencesActions from './geofences-state.actions';
import { Geofence } from './../models/geofence.model';
import { GeofencesStateModel } from './geofences-state.model';
import { Injectable } from '@angular/core';

/**
 * State class for geofences
 */
@State<GeofencesStateModel>({
  name: 'geofences',
  defaults: {
    geofenceMode: 'display',
    geofences: [],
    selectedGeofences: []
  }
})
@Injectable()
export class GeofencesState {

  /**
   * Get the geofence mode
   *
   * @returns GeofencesState.geofenceMode
   */
  @Selector()
  static geofenceMode(state: GeofencesStateModel): string {
    return state.geofenceMode;
  }

  /**
   * Get the selected geofence
   *
   * @returns GeofencesState.selectedGeofence
   */
  @Selector()
  static selectedGeofence(state: GeofencesStateModel): Geofence {
    return state.selectedGeofence;
  }

  /**
   * Get the selected geofences
   *
   * @returns GeofencesState.selectedGeofences
   */
  @Selector()
  static selectedGeofences(state: GeofencesStateModel): Geofence[] {
    return state.selectedGeofences;
  }

  constructor(private store: Store) { }

  /**
   * Action handler - add a geofence
   */
  @Action(GeofencesActions.AddGeofence)
  onAddGeofence(ctx: StateContext<GeofencesStateModel>, action: GeofencesActions.AddGeofence) {
    const state = ctx.getState();
    const geofences = [...state.geofences];
    geofences.push(action.geofence);
    ctx.patchState({
      geofences
    });
  }

  /**
   * Action handler - add a selected geofence
   */
  @Action(GeofencesActions.AddSelectedGeofence)
  onAddSelectedGeofence(ctx: StateContext<GeofencesStateModel>, action: GeofencesActions.AddSelectedGeofence) {
    // Use the geofence ID to fetch a reference to the geofence
    const state = ctx.getState();
    const geofences = [...state.geofences];
    const index = geofences.findIndex(x => x.geofenceId === action.geofenceId);
    const selectedGeofence = geofences[index];
    // add the geofence to selected geofences
    const selectedGeofences = [...state.selectedGeofences];
    selectedGeofences.push(selectedGeofence);
    ctx.patchState({
      selectedGeofences
    });
  }

  /**
   * Action handler - delete a geofence
   */
  @Action(GeofencesActions.DeleteGeofence)
  onDeleteGeofence(ctx: StateContext<GeofencesStateModel>, action: GeofencesActions.DeleteGeofence) {
    const state = ctx.getState();
    const geofences = [...state.geofences];
    const index = geofences.findIndex(x => x.geofenceId === action.geofenceId);
    geofences.splice(index, 1);
    ctx.patchState({
      geofences
    });
  }

  /**
   * Action handler - update geofence geofence details
   */
  @Action(GeofencesActions.UpdateGeofenceDetails)
  onUpdateGeofenceDetails(ctx: StateContext<GeofencesStateModel>, { payload }: GeofencesActions.UpdateGeofenceDetails) {
    const state = ctx.getState();
    const geofences = [...state.geofences];
    const index = geofences.findIndex(x => x.geofenceId === payload.geofenceId);
    const name = payload.name;
    const type = payload.type;
    const activity = payload.activity;
    geofences[index] = { ...geofences[index], name, type, activity };
    ctx.patchState({
      geofences
    });
  }

  /**
   * Action handler - update geofence geofence geometry
   */
  @Action(GeofencesActions.UpdateGeofenceGeometry)
  onUpdateGeofenceGeometry(ctx: StateContext<GeofencesStateModel>, { payload }: GeofencesActions.UpdateGeofenceGeometry) {
    const state = ctx.getState();
    const geofences = [...state.geofences];
    const index = geofences.findIndex(x => x.geofenceId === payload.geofenceId);
    const geometry = payload.geometry;
    geofences[index] = { ...geofences[index], geometry };
    ctx.patchState({
      geofences
    });
  }

  /**
   * Action handler - set the Geofence mode
   */
  @Action(GeofencesActions.SetGeofenceMode)
  onSetGeofenceMode(ctx: StateContext<GeofencesStateModel>, { geofenceMode }: GeofencesActions.SetGeofenceMode) {
    ctx.patchState({
      geofenceMode
    });
  }

  /**
   * Action handler - set the selected geofence. This
   *  is set when a geofence geometry is being edited.
   */
  @Action(GeofencesActions.SetSelectedGeofence)
  onSetSelectedGeofence(ctx: StateContext<GeofencesStateModel>, { selectedGeofenceId }: GeofencesActions.SetSelectedGeofence) {
    const state = ctx.getState();
    const geofences = [...state.geofences];
    const index = geofences.findIndex(x => x.geofenceId === selectedGeofenceId);
    const selectedGeofence = {...geofences[index]};
    ctx.patchState({
      selectedGeofence
    });
  }

  /**
   * Action handler - reset geofence data
   */
  @Action(GeofencesActions.ResetGeofenceData)
  onResetGeofenceData(ctx: StateContext<GeofencesStateModel>) {
    ctx.patchState({
      geofences: [],
      selectedGeofence: null,
      selectedGeofences: []
    });
  }


  /**
   * Action handler - reset the selected geofence
   */
  @Action(GeofencesActions.ResetSelectedGeofence)
  onResetSelectedGeofence(ctx: StateContext<GeofencesStateModel>) {
    ctx.patchState({
      selectedGeofence: null
    });
  }

  /**
   * Action handler - reset the selected geofences to default
   */
  @Action(GeofencesActions.ResetSelectedGeofences)
  onResetSelectedGeofences(ctx: StateContext<GeofencesStateModel>) {
    ctx.patchState({
      selectedGeofences: []
    });
  }

  /**
   * Action handler - reset the geofences state to default
   */
  @Action(GeofencesActions.ResetGeofencesState)
  onResetGeofencesState(ctx: StateContext<GeofencesStateModel>) {
    ctx.setState({
      geofenceMode: 'display',
      geofences: [],
      selectedGeofences: []
    });
  }

}
