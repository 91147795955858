import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import * as ConsoleActions from './console-state.actions';
import { ConsoleStateModel } from './console-state.model';
import { Location } from './../../models/location.model';
import { LocationsService } from './../../services/locations.service';
import { Logout } from './../auth/auth-state.actions';


const GLOBAL_ORG = 'Tenera';

/**
 * State class for console permissions and access
 */
@Injectable()
@State<ConsoleStateModel>({
  name: 'console',
  defaults: {
    isLoading: false,
    orgAccessList: []
  }
})
@Injectable()
export class ConsoleState {

  /**
   * Get whether the console is in the loading state
   *
   * @returns ConsoleState.isLoading
   */
  @Selector()
  static isLoading(state: ConsoleStateModel): boolean {
    return state.isLoading;
  }

  /**
   * Get the organizations access list
   *
   * @returns ConsoleState.orgAccess
   */
  @Selector()
  static orgAccessList(state: ConsoleStateModel): Location[] {
    return state.orgAccessList;
  }

  /**
   * Get the user permissions
   *
   * @returns ConsoleState.permissions
   */
  @Selector()
  static permissions(state: ConsoleStateModel): string[] {
    return state.permissions;
  }

  /**
   * Get the role of the logged in user
   *
   * @returns ConsoleState.role
   */
  @Selector()
  static role(state: ConsoleStateModel): string {
    return state.role;
  }

  /**
   * Get the ID of the selected organization
   *
   * @returns ConsoleState.selectedOrganization.id
   */
  @Selector()
  static selectedOrganizationId(state: ConsoleStateModel): string {
    return state.selectedOrganization.id;
  }

  /**
   * Get the name of the selected Organization
   *
   * @returns ConsoleState.selectedOrganization.name
   */
   @Selector()
   static selectedOrganizationName(state: ConsoleStateModel): string {
     return state.selectedOrganization.name;
   }

  /**
   * Get the location access for the logged in user
   *
   * @returns ConsoleState.userLocationAccess
   */
  @Selector()
  static userLocationAccess(state: ConsoleStateModel): string[] {
    return state.userLocationAccess;
  }

  /**
   * Get the organization of the logged in user
   *
   * @returns ConsoleState.userOrganization
   */
  @Selector()
  static userOrganization(state: ConsoleStateModel): string {
    return state.userOrganization;
  }

  /**
   * 
   * @returns ConsoleState.permissions.includes('monitoring-editor')
   */
   @Selector()
   static isMonitoringEditor(state: ConsoleStateModel): boolean {
     return state.permissions.includes('monitoring-editor');
   }

  constructor(
    private locationsService: LocationsService) {}

  /**
   * Action handler - set the isLoading attribute
   */
  @Action(ConsoleActions.SetIsLoading)
  onSetIsLoading(ctx: StateContext<ConsoleStateModel>, { isLoading }: ConsoleActions.SetIsLoading) {
    ctx.patchState({
      isLoading
    });
  }

  /**
   * Action handler - set the console state user permissions
   */
  @Action(ConsoleActions.SetPermissions)
  onSetPermissions(ctx: StateContext<ConsoleStateModel>, { permissions }: ConsoleActions.SetPermissions) {
    ctx.patchState({
      permissions: permissions.permissions,
      role: permissions.role,
      userLocationAccess: permissions.userLocationAccess,
      userOrganization: permissions.userOrganization
    });
  }

  /**
   * Action handler - process a list of organizations
   */
  @Action(ConsoleActions.ProcessOrganizations)
  async onProcessOrganizations(ctx: StateContext<ConsoleStateModel>,  action: ConsoleActions.ProcessOrganizations) {
    const lastSelectedOrg = localStorage.getItem('selectedOrganization');
    const userLocationAccess = ctx.getState().userLocationAccess;
    const userOrganization = ctx.getState().userOrganization;
    const orgList: Location[] = [];
    let defaultOrgId: string = action.organizationList[0];
    // maintain a list of organizations that the user may access
    for (const currentOrg of action.organizationList) {
      let orgLocationDoc;
      try {
        orgLocationDoc = await this.locationsService.getOrganizationLocation(currentOrg);
      } catch (error) {
        console.log('Firestore error getting organization location: ' + error);
        ctx.dispatch(new Logout());
      }
      if (orgLocationDoc) {
        const location = orgLocationDoc.data();
        if (userOrganization === orgLocationDoc.id || 
          location.access.some(r => userLocationAccess.includes(r)) ||
          userOrganization === GLOBAL_ORG) { // user has access to the organization
          if (lastSelectedOrg === currentOrg) {
            defaultOrgId = currentOrg;
          }
          const orgLocation = {
            id: currentOrg,
            name: location.name,
            parent: location.parent,
            type: location.type
          };
          orgList.push(orgLocation);
        }
      }
    }
    // dispatch the list of organizations that the user may access
    ctx.dispatch(new ConsoleActions.SetOrganizations(orgList));
    ctx.dispatch(new ConsoleActions.SetSelectedOrganization(defaultOrgId));
  }

  /**
   * Action handler - set the organizations the user may access
   */
  @Action(ConsoleActions.SetOrganizations)
  onSetOrganizations(ctx: StateContext<ConsoleStateModel>, action: ConsoleActions.SetOrganizations) {
    ctx.patchState({
      orgAccessList: action.organizations
    });
  }

  /**
   * Action handler - set the selected organization
   */
  @Action(ConsoleActions.SetSelectedOrganization)
  onSetSelectedOrganization(ctx: StateContext<ConsoleStateModel>, { organizationId }: ConsoleActions.SetSelectedOrganization ) {
    localStorage.setItem('selectedOrganization', organizationId);
    const state = ctx.getState();
    const orgs = state.orgAccessList;
    const index = orgs.findIndex(x => x.id === organizationId);
    ctx.patchState({
      selectedOrganization: orgs[index]
    });
  }

  /**
   * Action handler - reset the console state to default
   */
  @Action(ConsoleActions.ResetConsoleState)
  onResetConsoleState(ctx: StateContext<ConsoleStateModel>) {
    ctx.setState({
      isLoading: false,
      orgAccessList: []
     });
  }


}
