import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss']
})
export class ConfirmDeleteDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, message: string},
              private dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>) {}

  onConfirmDelete() {
    this.dialogRef.close(true);
  }

}


