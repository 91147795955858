<div class="wrapper">
  <div class="block-center col-lg-4 col-md-6">
    <div class="brand">
      <img class="img-fluid tenera-logo" src="assets/img/logo.svg" alt="Tenera Logo" />
      <p class="text-center">ADMIN CONSOLE</p>
    </div>

    <!-- Login error message -->
    <div *ngIf="isInvalid" class="alert alert-warning text-center">Invalid username or password</div>

    <!-- START Login form -->
    <form *ngIf="loginForm" [formGroup]="loginForm" name="loginForm" role="form" (submit)="login(loginForm.value)">

      <!-- Username field -->
      <mat-form-field>
        <mat-label>Email Address </mat-label>
        <input matInput autocapitalize="none" autocorrect="off" autocomplete="off" formControlName="username" 
          spellcheck="false" type="text"/>
        <p class="error-message text-center"
          *ngIf="loginForm.controls['username'].hasError('required') && (loginForm.controls['username'].dirty || loginForm.controls['username'].touched)">
            ** This field is required **
        </p>
      </mat-form-field>

      <!-- Password field -->
      <mat-form-field>
        <mat-label>Password </mat-label>
        <input matInput formControlName="password" type="password" autocomplete="off" />
        <p class="error-message text-center"
        *ngIf="loginForm.controls['password'].hasError('required') && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)">
            ** This field is required **
        </p>
      </mat-form-field>

      <!-- <a class="text-muted forgot-password" routerLink="/home" disabled="true">Forgot password</a> -->

      <button class="btn btn-block btn-lg mt-3 login-button" type="submit" [disabled]="!loginForm.valid">
        Login
      </button>
    </form>
    <!-- END Login form -->
  </div>

  <div class="p-3 text-center credit">
    <span>&copy;</span> <span>{{ getAppSetting("year") }}</span>
    <span> - </span> <span>{{ getAppSetting("name") }}</span>
  </div>
</div>
