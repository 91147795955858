const Venues = {
  text: 'Venues',
  link: '/venues/list',
  icon: 'fa fa-building',
  translate: 'sidebar.VENUES',
  // TODO provide translations for the submenu
  submenu: [
    {
        text: 'Edit Venue',
        link: '/venues/edit'
    },
    {
        text: 'Floors',
        link: '/venues/floors'
    },
    {
        text: 'Geofences',
        link: '/venues/geofences'
    },
    {
      text: 'Units',
      link: '/venues/units'
    },
    {
      text: 'Wearables',
      link: '/venues/wearables'
    },
    {
      text: 'Dome Lights',
      link: '/venues/dome-lights'
    },
    {
      text: 'NCS Profiles',
      link: '/venues/ncs-profiles'
    }
  ],
  permission: ['venue-editor']
};

const Locations = {
  text: 'Locations',
  link: '/locations',
  icon: 'fa fa-sitemap',
  translate: 'sidebar.LOCATIONS',
  permission: ['location-editor']
};

const Roles = {
  text: 'Roles',
  link: '/roles',
  icon: 'fa fa-id-card',
  translate: 'sidebar.ROLES',
  permission: ['role-editor']
};

const Positions = {
  text: 'Positions',
  link: '/positions',
  icon: 'fa fa-id-card-alt',
  translate: 'sidebar.POSITIONS',
  permission: ['position-editor']
};

const Adls = {
  text: 'Adls',
  link: '/adls',
  icon: 'far fa-calendar-check',
  translate: 'sidebar.ADLS',
  permission: ['adl-editor']
};

const Users = {
  text: 'Users',
  link: '/users',
  icon: 'fa fa-user',
  translate: 'sidebar.USERS',
  permission: ['user-editor']
};

const Cache = {
  text: 'Cache',
  link: '/cache',
  icon: 'fa fa-list',
  translate: 'sidebar.CACHE',
  permission: ['cache-editor']
};

const Account = {
  text: 'Account',
  link: '/account',
  icon: 'fa fa-edit',
  translate: 'sidebar.ACCOUNT',
  permission: ['any']
};

const Monitoring = {
  text: 'Monitoring',
  link: '/monitoring',
  icon: 'fa fa-chart-line',
  translate: 'sidebar.MONITORING',
  permission: ['monitoring-editor']
};

const Support = {
  text: 'Support',
  link: '/support',
  icon: 'fa fa-comments',
  translate: 'sidebar.SUPPORT',
  permission: ['any']
};

const SupportRequests = {
  text: 'Support Requests',
  link: '/support-requests',
  icon: 'fa fa-info-circle',
  translate: 'sidebar.SUPPORT_REQUESTS',
  permission: ['support-request-viewer']
};

const IssueTracker = {
  text: 'Issue Tracker',
  link: '/issue-tracker',
  icon: 'fas fa-spider',
  translate: 'sidebar.ISSUE_TRACKER',
  permission: ['admin-console-access']
};

const Simulations = {
  text: 'Simulations',
  link: '/testing',
  icon: 'fa fa-flask',
  translate: 'sidebar.TESTING',
  permission: ['venue-editor']
};

const Material = {
  text: 'Material',
  link: '/material',
  icon: 'fa fa-shield-alt',
  submenu: [
    {
      text: 'Cards',
      link: '/material/cards'
    },
    {
      text: 'Forms',
      link: '/material/forms'
    },
    {
      text: 'Inputs',
      link: '/material/inputs'
    },
    {
      text: 'Lists',
      link: '/material/lists'
    },
    {
      text: 'Whiteframe',
      link: '/material/whiteframe'
    },
    {
      text: 'Colors',
      link: '/material/colors'
    },
    {
      text: 'ng2Material',
      link: '/material/ngmaterial'
    }
  ],
  alert: 'new',
  label: 'badge badge-primary',
  permission: ['any']
};

export const menu = [
  Monitoring,
  Venues,
  Users,
  Locations,
  Roles,
  Positions,
  Adls,
  Cache,
  Account,
  SupportRequests,
  IssueTracker,
  Simulations
];
