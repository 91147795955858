import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Injectable()
export class CreateOrganizationFormService {

  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      name: [null, Validators.required],
      parent: [null, Validators.required]
    });
  }

  resetForm() {
    this.form.reset();
  }


}
