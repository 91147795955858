// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiUrl: 'http://localhost:3000/api',
  apiUrl: 'https://api-dev-us.tenera.care/api',
  firebase: {
    apiKey: 'AIzaSyC-QEZOuoOY_I2Bihi5hTuAD3pcvlJCFqM',
    authDomain: 'tenera-dev-us.firebaseapp.com',
    databaseURL: 'https://tenera-dev-us.firebaseio.com',
    projectId: 'tenera-dev-us',
    storageBucket: 'tenera-dev-us.appspot.com',
    messagingSenderId: '300263629496',
    appId: 'TBD'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.