import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Injectable } from '@angular/core';

import { Floor } from '../models/floor.model';

@Injectable()
export class FloorFormService {
  public floorForm: UntypedFormGroup;

  constructor() {
    this.floorForm = new UntypedFormGroup({
      floorName: new UntypedFormControl(null, {validators: [Validators.required]}),
      floorNumber: new UntypedFormControl(null, {validators: [Validators.required]}),
      xMinExtent: new UntypedFormControl(null, {validators: [Validators.required]}),
      xMaxExtent: new UntypedFormControl(null, {validators: [Validators.required]}),
      yMinExtent: new UntypedFormControl(null, {validators: [Validators.required]}),
      yMaxExtent: new UntypedFormControl(null, {validators: [Validators.required]}),
      z_ref: new UntypedFormControl(null, {validators: [Validators.required]}),
      geojson: new UntypedFormControl(null, {validators: [Validators.required]}),
      floorplanImg: new UntypedFormControl(null),
      floorplanAddress: new UntypedFormControl(null),
    });
  }

  /**
   * Reset all form controls to default
   */
  resetForm(): void {
    this.floorForm.reset();
  }

  /**
   * Set form control values with data from the selected floor
   *
   * @param floor The existing floor details
   */
  populateFormWithFloor(floor: Floor): void {
    this.floorForm.get('floorName').setValue(floor.floorName);
    this.floorForm.get('floorNumber').setValue(floor.floorNumber);
    this.floorForm.get('floorplanImg').setValue(floor.floorplanImg);
    this.floorForm.get('xMinExtent').setValue(floor.xMinExtent);
    this.floorForm.get('xMaxExtent').setValue(floor.xMaxExtent);
    this.floorForm.get('yMinExtent').setValue(floor.yMinExtent);
    this.floorForm.get('yMaxExtent').setValue(floor.yMaxExtent);
    this.floorForm.get('z_ref').setValue(floor.z_ref);
    this.floorForm.get('geojson').setValue(JSON.stringify(floor.geojson));
    this.floorForm.get('floorNumber').disable();
    this.floorForm.get('floorplanAddress').setValue(floor.floorplanAddress);
  }

  /**
   * Make the visible form fields read only
   */
  disableFormFields(): void {
    this.floorForm.get('floorName').disable();
    this.floorForm.get('floorNumber').disable();
    this.floorForm.get('xMinExtent').disable();
    this.floorForm.get('xMaxExtent').disable();
    this.floorForm.get('yMinExtent').disable();
    this.floorForm.get('yMaxExtent').disable();
    this.floorForm.get('z_ref').disable();
    this.floorForm.get('floorplanImg').disable();
  }

  /**
   * Set the floor GeoJSON data
   *
   * @param geojson GeoJSON object representing the floor
   */
  setGeojson(geojson: any): void {
    this.floorForm.get('geojson').setValue(geojson);
    this.floorForm.get('geojson').markAsDirty();
  }

  /**
   * Set the floor floorplan Image data
   *
   * @param floorplanImg floorplan Image object representing the floor
   */
  setFloorplanImage(floorplanImg: any): void {
    if (floorplanImg) {
      this.floorForm.get('floorplanImg').setValue(floorplanImg);
      this.floorForm.get('floorplanImg').markAsDirty();
    } else {
      this.floorForm.get('floorplanImg').reset();
      this.floorForm.get('floorplanImg').markAsDirty();
    }
  }

  /**
   * Create a floor ID from the floor name
   *
   * @param name The name of the floor
   */
  createFloorId(name: string): string {
    return name.replace(/[^\w\_]+/g, '');
  }

  /**
   * Return true if the X axis extents are valid, false otherwise
   */
  areXExtentsValid(): boolean {
    if (this.floorForm.get('xMinExtent').value < this.floorForm.get('xMaxExtent').value) {
        return true;
    }
    return false;
  }

  /**
   * Return true if the Y axis extents are valid, false otherwise
   */
  areYExtentsValid(): boolean {
    if (this.floorForm.get('yMinExtent').value < this.floorForm.get('yMaxExtent').value) {
        return true;
    }
    return false;
  }

  /**
   * Return a floor object from the data in the form
   */
  getNewFloorFromForm(): Floor {
    const floorName = this.floorForm.get('floorName').value;
    const floorId = this.createFloorId(floorName);
    return {
      floorId,
      floorName,
      floorNumber: this.floorForm.get('floorNumber').value,
      xMinExtent: this.floorForm.get('xMinExtent').value,
      xMaxExtent: this.floorForm.get('xMaxExtent').value,
      yMinExtent: this.floorForm.get('yMinExtent').value,
      yMaxExtent: this.floorForm.get('yMaxExtent').value,
      z_ref: this.floorForm.get('z_ref').value,
      geojson: this.floorForm.get('geojson').value,
      floorplanImg: this.floorForm.get('floorplanImg').value,
    };
  }

  /**
   * Return an object containing values of the editable fields
   */
  getUpdateFromForm(): any {
    const floorName = this.floorForm.get('floorName').value;
    return {
      floorName,
      xMinExtent: this.floorForm.get('xMinExtent').value,
      xMaxExtent: this.floorForm.get('xMaxExtent').value,
      yMinExtent: this.floorForm.get('yMinExtent').value,
      yMaxExtent: this.floorForm.get('yMaxExtent').value,
      z_ref: this.floorForm.get('z_ref').value,
      geojson: this.floorForm.get('geojson').value,
      floorplanImg: this.floorForm.get('floorplanImg').value,
      floorplanAddress: this.floorForm.get('floorplanAddress').value,
    };
  }


}
