<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>

  <!-- Alert element to display instruction -->
  <alert type="info" class="text-center" [dismissible]="false">
    {{ data.message }}
  </alert>

  <!-- Alert element to display empty list message -->
  <alert *ngIf="dataSource.data.length === 0" type="warning" class="text-center">No sensors activated</alert>

  <!-- START Sensor List -->
  <div class="card card-default" *ngIf="dataSource.data.length > 0">
    <table mat-table [dataSource]="dataSource">

      <!-- Sensor ID Column -->
      <ng-container matColumnDef="sensorId">
        <th mat-header-cell *matHeaderCellDef> Sensor ID </th>
        <td mat-cell *matCellDef="let row; let element" (click)="onSelect(element.id)"
          [ngStyle]="setSensorStyle(element.metadata.activationTime)">
            <div class="sensor">{{element.id}}</div>
        </td>
      </ng-container>

      <!-- Firmware version Column -->
      <ng-container matColumnDef="firmware">
        <th mat-header-cell *matHeaderCellDef> Firmware<br/>Version </th>
        <td mat-cell *matCellDef="let row; let element">
          <span>{{element.metadata?.fw}}</span>
          <span class="text-success" *ngIf="element.metadata?.fw?.includes(latestFirmwareVersion)">
            <i class="fa fa-check"></i> 
          </span>
        </td>
      </ng-container>

      <!-- Reset Column -->
      <ng-container matColumnDef="reset">
        <th mat-header-cell *matHeaderCellDef>Reset Sensor</th>
        <td mat-cell *matCellDef="let row; let element" (click)="resetSensor(element.id)">
          <div *ngIf="setResetButtonVisibile(element.metadata.activationTime)" class="btn btn-block reset">Reset</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <!-- END Sensor List-->

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button type='button' class="ml-auto btn-primary" mat-dialog-close>Cancel</button>
</mat-dialog-actions>
