import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { Logout } from '../../shared/state/auth/auth-state.actions';
import { ConsoleState } from '../../shared/state/console/console.state';
import { menu } from '../../routes/menu';

@Injectable()
export class MenuService {

    menuItems: Array<any>;

    constructor(
        private store: Store
      ) {}

    createMenu(items: Array<{
        text: string,
        heading?: boolean,
        link?: string,     // internal route links
        elink?: string,    // used only for external links
        target?: string,   // anchor target="_blank|_self|_parent|_top|framename"
        icon?: string,
        alert?: string,
        submenu?: Array<any>
        permission?: Array<string>
    }>) {
        const menuItems = [];
        let permissions = this.store.selectSnapshot(ConsoleState.permissions);
        if (permissions === undefined) {
          // user has no permissions so require login again
          console.log('Error with user permissions, requiring re-login');
          this.store.dispatch(new Logout());
        }
        // add each menu item for which the user has permissions
        items.forEach(item => {
          if (item.permission === undefined ||
              item.permission.includes('any') ||
              permissions.some(permission => item.permission.includes(permission))) {
                menuItems.push(item);
          }
        });
        return menuItems;
    }

    getMenu() {
        return this.createMenu(menu);
    }

}
