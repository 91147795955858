import { SortObj } from '../listview-state.model';

/**
 * Action dispatched to set search filter
 */
export class SetWearableFilter {
  static type = '[WearableListView] SetWearableFilter';
  constructor(public filter: string) {}
}

/**
 * Action dispatched to set page index
 */
export class SetWearablePageIndex {
  static type = '[WearableListView] SetWearablePageIndex';
  constructor(public pageIndex: number) {}
}

/**
 * Action dispatched to set page size
 */
export class SetWearablePageSize {
  static type = '[WearableListView] SetWearablePageSize';
  constructor(public pageSize: number) {}
}

/**
 * Action dispatched to set sort order
 */
export class SetWearableSort {
  static type = '[WearableListView] SetWearableSort';
  constructor(public sort: SortObj) {}
}

/**
 * Action dispatched to reset the WearableListView state to default
 */
export class ResetWearableListView {
  static type = '[WearableListView] ResetWearableListView';
}
