import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

import { AppComponent } from './app.component';
import { AuthInterceptor } from './shared/services/auth.interceptor';
import { ConfirmDeleteDialogComponent } from './shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { ConfirmDisableDialogComponent } from './shared/components/confirm-disable-dialog/confirm-disable-dialog.component';
import { CoreModule } from './core/core.module';
import { GeofenceDetailDialogComponent } from './routes/venues/geofences/geofence-detail-dialog/geofence-detail-dialog.component';
import { CreateOrganizationDialogComponent } from './shared/components/create-organization-dialog/create-organization-dialog.component';
import { environment } from '../environments/environment';
import { ErrorDialogComponent } from './shared/components/error-dialog/error-dialog.component';
import { FloorDialogComponent } from './routes/venues/floors/floor-dialog/floor-dialog.component';
import { LayoutModule } from './layout/layout.module';
import { LoginService } from './shared/services/login.service';
import { ManageOrganizationsDialogComponent } from './shared/components/manage-organizations-dialog/manage-organizations-dialog.component';
import { ModifyGeofenceDialogComponent } from './routes/venues/geofences/modify-geofence-dialog/modify-geofence-dialog.component';
import { RoutesModule } from './routes/routes.module';
import { SharedModule } from './shared/shared.module';
import { States } from './shared/state/app.state';
import { UnitGeofenceDetailDialogComponent } from './routes/venues/units/unit-geofence-detail-dialog/unit-geofence-detail-dialog.component';
import { UnitDetailsAddFloorsDialogComponent } from './routes/venues/units/unit-details-add-floors-dialog/unit-details-add-floors-dialog.component';
import { ModifyUnitGeofenceDialogComponent } from './routes/venues/units/modify-unit-geofence-dialog/modify-unit-geofence-dialog.component';
import { UnsavedChangesDialogComponent } from './shared/components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { IssueDetailDialogComponent } from './routes/issue-tracker/issue-tracker-detail-dialog/issue-tracker-detail-dialog.component';
import { ConfirmZHeightDialogComponent } from './shared/components/confirm-z-height-dialog/confirm-z-height-dialog.component';
import { ConfirmCalibrationDialogComponent } from './shared/components/confirm-calibration-dialog/confirm-calibration-dialog.component';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { ChangeConfirmDialogComponent } from './shared/components/change-confirm-dialog/change-confirm-dialog.component';
import { AutoAssignSensorDialogComponent } from './shared/components/auto-assign-sensor-dialog/auto-assign-sensor-dialog.component';
import { AutoAssignFixtureDialogComponent } from './shared/components/auto-assign-fixture-dialog/auto-assign-fixture-dialog.component';

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorDialogComponent,
    FloorDialogComponent,
    GeofenceDetailDialogComponent,
    UnitGeofenceDetailDialogComponent,
    UnitDetailsAddFloorsDialogComponent,
    ModifyUnitGeofenceDialogComponent,
    ModifyGeofenceDialogComponent,
    IssueDetailDialogComponent,
    ChangeConfirmDialogComponent,
    ConfirmDeleteDialogComponent,
    CreateOrganizationDialogComponent,
    ConfirmDisableDialogComponent,
    ConfirmZHeightDialogComponent,
    ConfirmCalibrationDialogComponent,
    ManageOrganizationsDialogComponent,
    UnsavedChangesDialogComponent,
    AutoAssignSensorDialogComponent,
    AutoAssignFixtureDialogComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    LayoutModule,
    SharedModule.forRoot(),
    RoutesModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    NgxsModule.forRoot(States, { 
      developmentMode: !environment.production,
      compatibility: { strictContentSecurityPolicy: true }
    }),
    NgxsRouterPluginModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    LoginService,
    { provide: SETTINGS, useValue: { merge: true } },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
