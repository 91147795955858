<form [formGroup]='form' (ngSubmit)='submit(form)'>
  <h1 mat-dialog-title>Geofence Details</h1>

  <mat-dialog-content>
    <mat-form-field appearance="outline">
      <mat-label>Enter geofence name</mat-label>
      <input matInput formControlName="geofenceName" autocorrect="off" autocomplete="off" spellcheck="true" required>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Select geofence type</mat-label>
      <mat-select formControlName="geofenceType" required (selectionChange)="onGeofenceTypeChange(form)">
        <mat-option *ngFor="let type of geofenceTypes" [value]="type">
          {{type}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="form.controls.geofenceType.value === 'Common'">
      <mat-label>Select activity</mat-label>
      <mat-select formControlName="activity" required>
        <mat-option *ngFor="let activity of activityTypes" [value]="activity">
          {{activity}}
        </mat-option>
      </mat-select>
    </mat-form-field>

  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button type='submit' [disabled]="!form.dirty || !form.valid">Save</button>
    <button mat-button type='button' mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</form>
