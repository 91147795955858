import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import * as SensorsActions from './sensors-state.actions';
import { Sensor } from './../../models/sensor.model';
import { SensorsStateModel } from './sensors-state.model';

/**
 * State class for sensors
 */
@Injectable()
@State<SensorsStateModel>({
  name: 'sensors',
  defaults: {
    activatedSensors: []
  }
})
export class SensorsState {

  /**
   * Get the list of activated sensors
   *
   * @returns SensorsState.activatedSensors
   */
  @Selector()
  static activatedSensors(state: SensorsStateModel): Sensor[] {
    return state.activatedSensors;
  }

  /**
   * Action handler - add an activated sensor to state
   */
  @Action(SensorsActions.AddActivatedSensor)
  onAddActivatedSensor(ctx: StateContext<SensorsStateModel>, action: SensorsActions.AddActivatedSensor) {
    const state = ctx.getState();
    const activatedSensors = [...state.activatedSensors];
    activatedSensors.push(action.sensor);
    ctx.patchState({
      activatedSensors
    });
  }

  /**
   * Action handler - delete an activated sensor from state
   */
  @Action(SensorsActions.DeleteActivatedSensor)
  onDeleteActivatedSensor(ctx: StateContext<SensorsStateModel>, action: SensorsActions.DeleteActivatedSensor) {
    const state = ctx.getState();
    const activatedSensors = [...state.activatedSensors];
    const index = activatedSensors.findIndex(x => x.id === action.sensorId);
    activatedSensors.splice(index, 1);
    ctx.patchState({
      activatedSensors
    });
  }

  /**
   * Action handler - update an activated sensor in state
   */
  @Action(SensorsActions.UpdateActivatedSensor)
  onUpdateActivatedSensor(ctx: StateContext<SensorsStateModel>, action: SensorsActions.UpdateActivatedSensor) {
    const state = ctx.getState();
    const activatedSensors = [...state.activatedSensors];
    const index = activatedSensors.findIndex(x => x.id === action.sensor.id);
    activatedSensors[index] = action.sensor;
    ctx.patchState({
      activatedSensors
    });
  }

  /**
   * Action handler - reset the sensors state to default
   */
  @Action(SensorsActions.ResetSensorsState)
  onResetSensorsState(ctx: StateContext<SensorsStateModel>) {
    ctx.setState({
      activatedSensors: []
    });
  }

}
