import { Store } from '@ngxs/store';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';

import { Logout } from '../state/auth/auth-state.actions';
import { ProcessOrganizations } from './../state/console/console-state.actions';

const GLOBAL_ORG = 'Tenera';

@Injectable({providedIn: 'root'})
export class OrganizationsService {

  constructor(
    private afs: AngularFirestore,
    private store: Store) {}

  /**
   * Fetch organizations and dispatch action to process list
   */
  async fetchOrganizations(userOrganization, userLocationAccess) {
    const orgList: string[] = [];
    this.afs.collection('organizations').get().subscribe( async querySnapshot => {
      for (const currentOrg of querySnapshot.docs) {
        let orgLocationDoc;
        try { // get the access list for the organization from the location document
          orgLocationDoc = await this.getOrganizationLocation(currentOrg.id);
        } catch (error) {
          console.log('Firestore error getting organization location: ' + error);
        }
        
        if (orgLocationDoc) { // a location document was found
          const orgLocation = orgLocationDoc.data();
          if (userOrganization === currentOrg.id || orgLocation.access.some((r: string) => userLocationAccess.includes(r))) { // user has access
              orgList.push(currentOrg.id);
          } else if (userOrganization === GLOBAL_ORG) {
            // if user is Tenera, include all orgs that have access items
            const locDocs = await this.getOrganizationLocationDocs(currentOrg.id);
            if (locDocs.docs.find(doc => userLocationAccess.includes(doc.id))) {
              orgList.push(currentOrg.id);
            }
          }
        }
      }
      if (orgList.length > 0) {
        this.store.dispatch(new ProcessOrganizations(orgList));
      } else {
        console.error('User does not have access to any organizations. Logging out user');
        this.store.dispatch(new Logout());
      }
    });
  }

  /**
   * Get an organization location document
   *
   * @param organizationId The organization identifier
   */
  getOrganizationLocation(organizationId: string) {
    return this.afs.firestore.collection('organizations/' + organizationId + '/locations').doc(organizationId).get();
  }

  /**
   * Get location documents for ogranization
   *
   * @param organizationId The organization identifier
   */
   getOrganizationLocationDocs(organizationId: string) {
    return this.afs.firestore.collection('organizations/' + organizationId + '/locations').get();
  }

  // /**
  //  * Create an organization document to act as organization database root
  //  *
  //  * @param organizationId  The unique organization identifier
  //  * @param parent The parent organization
  //  */
  // createOrganization(organizationId: string, parent: string) {
  //   return this.afs.firestore.collection('organizations').doc(organizationId).set({ parent });
  // }


}
