import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AuthGuard } from '../shared/services/auth.guard';
import { LoginComponent } from './login/login.component';
import { menu } from './menu';
import { MenuService } from '../core/menu/menu.service';
import { routes } from './routes';
import { SharedModule } from '../shared/shared.module';
import { TranslatorService } from '../core/translator/translator.service';
import { CanDeactivateGuard } from '../shared/services/deactivate.guard';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forRoot(routes, { })
  ],
  declarations: [LoginComponent],
  exports: [
    RouterModule
  ],
  providers: [AuthGuard, CanDeactivateGuard]
})

export class RoutesModule {
  constructor(public menuService: MenuService, tr: TranslatorService) {
  }
}
