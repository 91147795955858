import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UnsavedChangesDialogService } from '../../services/unsaved-changes-dialog.service';

@Component({
  selector: 'app-unsaved-changes-dialog',
  templateUrl: './unsaved-changes-dialog.component.html',
  styleUrls: ['./unsaved-changes-dialog.component.scss']
})
export class UnsavedChangesDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {title: string, message: string},
    private dialogRef: MatDialogRef<UnsavedChangesDialogComponent>,
    private dialogService: UnsavedChangesDialogService) {}

  choose(choice: boolean): void {
    this.dialogService.navigateAwaySelection$.next(choice);
    this.dialogRef.close(choice);
  }

}
