import { SortObj } from '../listview-state.model';

/**
 * Action dispatched to set search filter
 */
export class SetUnitFilter {
  static type = '[UnitListView] SetUnitFilter';
  constructor(public filter: string) {}
}

/**
 * Action dispatched to set page index
 */
export class SetUnitPageIndex {
  static type = '[UnitListView] SetUnitPageIndex';
  constructor(public pageIndex: number) {}
}

/**
 * Action dispatched to set page size
 */
export class SetUnitPageSize {
  static type = '[UnitListView] SetUnitPageSize';
  constructor(public pageSize: number) {}
}

/**
 * Action dispatched to set sort order
 */
export class SetUnitSort {
  static type = '[UnitListView] SetUnitSort';
  constructor(public sort: SortObj) {}
}

/**
 * Action dispatched to reset the UnitListView state to default
 */
export class ResetUnitListView {
  static type = '[UnitListView] ResetUnitListView';
}
