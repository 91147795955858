import { Injectable } from '@angular/core';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Unit } from '../../../models/unit.model';
import { UnitDetailsFloor } from '../../models/unit-details-floor.model';

import * as UnitDetailsActions from './unit-details-state.actions';
import { UnitDetailsStateModel } from './unit-details-state.model';

/**
 * State class for unit detail
 */
@State<UnitDetailsStateModel>({
  name: 'unitDetail',
  defaults: {
    mode: null,
    isEditingGeofence: false,
    unsavedFloors: [],
    unsavedUnit: null
  }
})
@Injectable()
export class UnitDetailsState {

  /**
   * Get the mode
   *
   * @returns UnitDetailsStateModel.mode
   */
  @Selector()
  static mode(state: UnitDetailsStateModel): string {
    return state.mode;
  }

  /**
   * Get the mode
   *
   * @returns UnitDetailsStateModel.isEditingGeofence
   */
  @Selector()
  static isEditingGeofence(state: UnitDetailsStateModel): boolean {
    return state.isEditingGeofence;
  }

  /**
   * Get the list of unsaved rules
   *
   * @returns UnitDetailsStateModel.unsavedFloors
   */
  @Selector()
  static unsavedFloors(state: UnitDetailsStateModel): UnitDetailsFloor[] {
    return state.unsavedFloors;
  }

  /**
   * Get the unsaved unit
   *
   * @returns UnitDetailsStateModel.unsavedUnit
   */
  @Selector()
  static unsavedUnit(state: UnitDetailsStateModel): Unit {
    return state.unsavedUnit;
  }

  // Actions handlers

   /**
    * Action handler - set the unsaved unit
    */
   @Action(UnitDetailsActions.SetUnsavedUnit)
   onSetUnsavedUnit(ctx: StateContext<UnitDetailsStateModel>, action: UnitDetailsActions.SetUnsavedUnit) {
     ctx.patchState({
       unsavedUnit: action.unsavedUnit
     });
   }

  /**
   * Action handler - set the saved floors
   */
  @Action(UnitDetailsActions.SetUnsavedFloors)
  onSetUnsavedFloors(ctx: StateContext<UnitDetailsStateModel>, action: UnitDetailsActions.SetUnsavedFloors) {
    const floors = action.payload.floors;
    const unitDetailsFloors = [];
    if (action.payload.saved) {
      for (const floor of floors) {
        unitDetailsFloors.push({
          ...floor,
          saved: true
        });
      }
    } else {
      for (const floor of floors) {
        unitDetailsFloors.push({
          ...floor,
          saved: false
        });
      }
    }
    ctx.patchState({
      unsavedFloors: unitDetailsFloors,
    });
  }

  /**
   * Action handler - set the mode
   */
  @Action(UnitDetailsActions.AddUnsavedFloor)
  onAddUnsavedFloors(ctx: StateContext<UnitDetailsStateModel>, action: UnitDetailsActions.AddUnsavedFloor) {
    const unsavedFloors = [...ctx.getState().unsavedFloors, action.floor];

    ctx.patchState({
      unsavedFloors: unsavedFloors,
    });
  }

  /**
   * Action handler - delete an unsaved floor
   */
  @Action(UnitDetailsActions.DeleteUnsavedFloorById)
  onDeleteUnsavedFloorById(ctx: StateContext<UnitDetailsStateModel>, action: UnitDetailsActions.DeleteUnsavedFloorById) {
    let unsavedFloors = ctx.getState().unsavedFloors.slice();
    unsavedFloors = unsavedFloors.filter(item => item.floorId != action.floorId);
    ctx.patchState({
      unsavedFloors: unsavedFloors
    });
  }

  /**
   * Action handler - update an unsaved floor
   */
  @Action(UnitDetailsActions.UpdateUnsavedFloor)
  onUpdateUnsavedFloor(ctx: StateContext<UnitDetailsStateModel>, action: UnitDetailsActions.UpdateUnsavedFloor) {
    const unsavedFloors = ctx.getState().unsavedFloors.slice();
    unsavedFloors[action.payload.index] = action.payload.floor;
    ctx.patchState({
      unsavedFloors: unsavedFloors,
    });
  }

  /**
   * Action handler - update an unsaved floor
   */
  @Action(UnitDetailsActions.UpdateFloorGeofence)
  onUpdateFloorGeofence(ctx: StateContext<UnitDetailsStateModel>, action: UnitDetailsActions.UpdateFloorGeofence) {
    const unsavedFloors = ctx.getState().unsavedFloors.slice();
    unsavedFloors[action.payload.index] = {
      ...unsavedFloors[action.payload.index],
      geofence: action.payload.geofence
    };
    ctx.patchState({
      unsavedFloors: unsavedFloors,
    });
  }

  /**
   * Action handler - update an unsaved floors extent
   */
  @Action(UnitDetailsActions.UpdateFloorExtentById)
  onUpdateFloorExtentById(ctx: StateContext<UnitDetailsStateModel>, action: UnitDetailsActions.UpdateFloorExtentById) {
    const unsavedFloors = ctx.getState().unsavedFloors.slice();
    const floorIdx = unsavedFloors.findIndex(item => item.floorId === action.payload.floorId);
    unsavedFloors[floorIdx] = {
      ...unsavedFloors[floorIdx],
      extent: action.payload.extent
    };
    ctx.patchState({
      unsavedFloors: unsavedFloors,
    });
  }

  /**
   * Action handler - set the mode
   */
  @Action(UnitDetailsActions.SetMode)
  onSetMode(ctx: StateContext<UnitDetailsStateModel>, action: UnitDetailsActions.SetMode) {
    ctx.patchState({
      mode: action.mode
    });
  }

  /**
   * Action handler - set the editing geofence
   */
  @Action(UnitDetailsActions.SetIsEditingGeofence)
  onSetIsEditingGeofence(ctx: StateContext<UnitDetailsStateModel>, action: UnitDetailsActions.SetIsEditingGeofence) {
    ctx.patchState({
      isEditingGeofence: action.isEditingGeofence
    });
  }

  /**
   * Action handler - reset the units state to default
   */
  @Action(UnitDetailsActions.ResetUnitDetailsModeState)
  onResetUnitDetailsModeState(ctx: StateContext<UnitDetailsStateModel>) {
    ctx.setState({
      mode: null,
      unsavedFloors: [],
      unsavedUnit: null,
      isEditingGeofence: false
    });
  }

  /**
   * Action handler - reset the units state to default
   */
  @Action(UnitDetailsActions.ResetUnitDetailsState)
  onResetUnitDetailsState(ctx: StateContext<UnitDetailsStateModel>) {
    ctx.patchState({
      unsavedFloors: [],
      unsavedUnit: null,
      isEditingGeofence: false
    });
  }


}
