import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';

import { ConsoleState } from './../../../../shared/state/console/console.state';
import { ConfirmDeleteDialogComponent } from './../../../../shared/components/confirm-delete-dialog/confirm-delete-dialog.component';
import { Floor } from '../../models/floor.model';
import { FloorFormService } from '../floor-form.service';
import { take } from 'rxjs/operators';
import { VenuesService } from '../../venues.service';
import { VenuesState } from '../../../../shared/state/venues/venues.state';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FloorDxfFormService } from '../floor-dxf-form.service';

@Component({
  selector: 'app-floor-dialog',
  templateUrl: './floor-dialog.component.html',
  styleUrls: ['./floor-dialog.component.scss'],
  providers: [FloorFormService, FloorDxfFormService]
})
export class FloorDialogComponent implements OnInit {

  @ViewChild('fileFloorplanPicker', {static: false}) fileFloorplanPicker: any;
  @ViewChild('magicPlanPngPicker', {static: false}) magicPlanPngPicker: any;
  @ViewChild('fileDxfPicker', {static: false}) fileDxfPicker: any;
  @ViewChild('filePicker', {static: false}) filePicker: any;

  floorForm: UntypedFormGroup;
  floorDxfForm: UntypedFormGroup;
  fileName: string;
  floorplanFileName: string;
  magicPlanPngFileName: string;
  dxfFileName: string;
  floorplanExists: boolean = false;
  magicPlanPngExists: boolean = false;
  floorplanDelete: boolean = false;
  submitting: boolean = false;
  endpointType: string = 'geojson';

  constructor(@Inject(MAT_DIALOG_DATA) public data: {floor: Floor, mode: string},
              private dialogRef: MatDialogRef<FloorDialogComponent>,
              private floorFormService: FloorFormService,
              private floorDxfFormService: FloorDxfFormService,
              private dialog: MatDialog,
              private store: Store,
              private venuesService: VenuesService) { }

  ngOnInit() {
    this.floorForm = this.floorFormService.floorForm;
    this.floorDxfForm = this.floorDxfFormService.floorDxfForm;
    if (this.data.floor) {
      this.floorFormService.populateFormWithFloor(this.data.floor);
      this.floorDxfFormService.populateFormWithFloor(this.data.floor);
      const floorplanAddress = this.floorForm.get('floorplanAddress').value;
      if (floorplanAddress) {
        this.floorplanExists = true;
        const path = floorplanAddress.split('/');
        this.floorplanFileName = `${path[path.length - 1]} Floorplan`;
      }
      if (this.floorForm.get('geojson').value) {
        this.fileName = `${this.floorForm.get('floorName').value} GeoJSON`;
      }
    }
  }

  /**
   * Set all form values to their default
   */
  resetForm(): void {
    this.floorFormService.resetForm();
    this.floorDxfFormService.resetForm();
  }

  /**
   * Triggered when a file is picked
   *
   * @param event The event containing a reference to selected file
   */
  onFilePicked(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    this.floorForm.get('geojson').markAsTouched();
    if (!file) { // prevent error on cancel picking file
      return;
    }
    this.fileName = file.name;
    const fileReader = new FileReader();
    fileReader.readAsText(file, 'UTF-8');
    fileReader.onload = () => {
      this.floorFormService.setGeojson(fileReader.result);
    };
    fileReader.onerror = (error) => {
      console.log(error);
    };
  }

  /**
   * Triggered when a file is picked
   *
   * @param event The event containing a reference to selected file
   */
  onDxfFilePicked(event: Event): void {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file) { // prevent error on cancel picking file
      return;
    }

    this.dxfFileName = file.name;
    this.floorDxfFormService.setDxf(file);
  }

  /**
   * Triggered when a file is picked
   *
   * @param event The event containing a reference to selected file
   */
  async onFloorplanFilePicked(event: Event): Promise<void> {
    const file = (event.target as HTMLInputElement).files[0];
    if (!file) { // prevent error on cancel picking file
      return;
    }

    this.floorplanFileName = file.name;
    this.floorFormService.setFloorplanImage(file);
    this.floorplanDelete = false;
  }

    /**
   * Triggered when a file is picked
   *
   * @param event The event containing a reference to selected file
   */
    async onMagicPlanPngFilePicked(event: Event): Promise<void> {
      const file = (event.target as HTMLInputElement).files[0];
      if (!file) { // prevent error on cancel picking file
        return;
      }
  
      this.magicPlanPngFileName = file.name;
      this.floorDxfFormService.setFloorplanImage(file);
      this.floorplanDelete = false;
    }
  

  /**
   * Triggered when floorplan remove button is clicked
   *
   */
  onRemoveFloorPlan() {
    if (this.data.mode === 'Update' && this.floorplanExists) {
      // confirm delete action
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.data = {
        title: 'Delete Floor',
        message: 'Are you sure you want to delete the existing floorplan image with this update?'
      };
      const confirmRemoveFloorplanRef = this.dialog.open(ConfirmDeleteDialogComponent, dialogConfig);
      confirmRemoveFloorplanRef.afterClosed().pipe(take(1)).subscribe(async removeResult => {
        if (removeResult) { // if 'true' is passed, the floorplan will be deleted on update
          this.floorFormService.setFloorplanImage(null);
          this.floorplanFileName = '';
          this.floorForm.get('floorplanAddress').setValue(null);
          if (this.fileFloorplanPicker) {
            this.fileFloorplanPicker.nativeElement.value = '';
          }
          this.floorplanDelete = true;
        }
      });
    } else {
      this.floorFormService.setFloorplanImage(null);
      this.floorplanFileName = '';
      if (this.fileFloorplanPicker) {
        this.fileFloorplanPicker.nativeElement.value = '';
      }
    }
  }

  /**
   * Triggered when floorplan remove button is clicked
   *
   */
  onRemoveMagicplanFloorPlan() {
    this.floorDxfFormService.setFloorplanImage(null);
    this.floorDxfForm.get('floorplanAddress').setValue(null);
    this.magicPlanPngFileName = '';
    if (this.magicPlanPngPicker) {
      this.magicPlanPngPicker.nativeElement.value = '';
    }
  }

  /**
   * Triggered when dxf remove button is clicked
   *
   */
  onRemoveDxf() {
      this.floorDxfFormService.setDxf(null);
      this.dxfFileName = '';
      if (this.fileDxfPicker) {
        this.fileDxfPicker.nativeElement.value = '';
      }
  }

  onRemoveGeoJSON() {
    this.floorForm.get('geojson').markAsTouched();
    if (this.data.mode === 'Update' && this.floorplanExists) {
      // confirm delete action
      const dialogConfig = new MatDialogConfig();
      dialogConfig.autoFocus = false;
      dialogConfig.data = {
        title: 'Delete GeoJSON',
        message: 'Are you sure you want to delete the existing floorplan geoJSON?'
      };
      const confirmRemoveFloorplanRef = this.dialog.open(ConfirmDeleteDialogComponent, dialogConfig);
      confirmRemoveFloorplanRef.afterClosed().pipe(take(1)).subscribe(async removeResult => {
        if (removeResult) {
          this.floorForm.get('geojson').setValue(null);
          this.floorForm.get('geojson').markAsPristine();
          this.fileName = '';
          this.filePicker.nativeElement.value = '';
        }
      });
    } else {
      this.floorForm.get('geojson').setValue(null);
      this.floorForm.get('geojson').markAsPristine();
      this.fileName = '';
      this.filePicker.nativeElement.value = '';
    }
  }

  onChangeEndpoint(type: string) {
    this.endpointType = type;
  }

  /**
   * Create a new floor or update an existing one
   */
  async submit() {
    this.submitting = true;
    const organization = this.store.selectSnapshot(ConsoleState.selectedOrganizationId);
    const venueId = this.store.selectSnapshot(VenuesState.selectedVenueId);
    if (!this.floorFormService.areXExtentsValid()) {
      this.venuesService.onError('Invalid Extents', 'East extent must be greater than West extent');
      this.submitting = false;
    } else if (!this.floorFormService.areYExtentsValid()) {
      this.venuesService.onError('Invalid Extents', 'North extent must be greater than South extent');
      this.submitting = false;
    } else {
      if (this.data.mode === 'Create') {
        const newFloor = this.floorFormService.getNewFloorFromForm();
        newFloor.venueId = venueId;
        try {
          await this.venuesService.createFloor(organization, venueId, newFloor);

          this.dialogRef.close();
          this.resetForm();
          this.submitting = false;
          } catch (error) {
            console.error(error);
            this.venuesService.onError('Floor cound not be created', error);
            this.submitting = false;
          }
      } else if (this.data.mode === 'Update') {
        const updatedFloor = this.floorFormService.getUpdateFromForm();
        if (this.floorplanDelete) {
          updatedFloor.floorplanDelete = this.floorplanDelete;
        }
        try {
          await this.venuesService.updateFloor(organization, venueId, this.data.floor.floorId, updatedFloor);
          // TODO this.store.dispatch(new SetSelectedFloor());
          this.dialogRef.close();
          this.resetForm();
          this.submitting = false;
        } catch (error) {
          console.error(error);
          this.venuesService.onError('Floor cound not be updated', error);
          this.submitting = false;
        }
      }
    }
  }

  /**
   * Create a new floor or update an existing one
   */
  async submitDxf() {
    this.submitting = true;
    const organization = this.store.selectSnapshot(ConsoleState.selectedOrganizationId);
    const venueId = this.store.selectSnapshot(VenuesState.selectedVenueId);
    let dxfName: string, pngName: string;

    try {
      dxfName = await this.venuesService.storeMagicPlanFile(
        organization, 
        venueId, 
        this.floorDxfForm.get('dxf').value,
        'dxf');
      pngName = await this.venuesService.storeMagicPlanFile(
        organization, 
        venueId, 
        this.floorDxfForm.get('floorplanImg').value,
        'png');
    } catch (error) {
      console.error(error);
      this.venuesService.onError('Files could not be uploaded', error);
      this.submitting = false;
      return;
    }

    if (this.data.mode === 'Create') {
      try {
        const newFloor = this.floorDxfFormService.getNewFloorFromForm(dxfName, pngName);
      
        await this.venuesService.createFloorByDxf(organization, venueId, newFloor);

        this.dialogRef.close();
        this.resetForm();
        this.submitting = false;
        } catch (error) {
          console.error(error);
          this.venuesService.onError('Floor cound not be created', error);
          this.submitting = false;
        }
    } else if (this.data.mode === 'Update') {
      try {
        const updatedFloor = this.floorDxfFormService.getUpdateFromForm(dxfName, pngName);
        await this.venuesService.updateFloorByDxf(organization, venueId, this.data.floor.floorId, updatedFloor);

        this.dialogRef.close();
        this.resetForm();
        this.submitting = false;
      } catch (error) {
        console.error(error);
        const msg = error?.error?.message ?? error;
        this.venuesService.onError('Floor cound not be updated', msg);
        this.submitting = false;
      }
    }
  }


}
