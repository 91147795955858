import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import * as SupportRequestListViewActions from './support-request-listview-state.actions';
import { ListViewStateModel } from '../listview-state.model';

/**
 * State class for list views
 */
@Injectable()
@State<ListViewStateModel>({
  name: 'supportRequestListview',
  defaults: {
    filter: '',
    pageIndex: 0,
    pageSize: 10,
    sort: { active: 'ticketNumber', direction: 'asc' }
  }
})
export class SupportRequestListViewState {

  /**
   * Get the filter string applied to the support request list
   *
   * @returns SupportRequestListViewState.filter
   */
   @Selector()
   static filter(state: ListViewStateModel): string {
     return state.filter;
   }

  /**
   * Get the sort property applied to the support request list
   *
   * @returns SupportRequestListViewState.sort.active
   */
  @Selector()
  static sortActive(state: ListViewStateModel): string {
    return state.sort.active;
  }

  /**
   * Get the sort direction applied to the support request list
   *
   * @returns SupportRequestListViewState.sort.direction
   */
  @Selector()
  static sortDirection(state: ListViewStateModel): string {
    return state.sort.direction;
  }

  /**
   * Get the page index of the support request list
   *
   * @returns SupportRequestListViewState.pageIndex
   */
  @Selector()
  static pageIndex(state: ListViewStateModel): number {
    return state.pageIndex;
  }

  /**
   * Get the page size of the support request list
   *
   * @returns SupportRequestListViewState.pageIndex
   */
  @Selector()
  static pageSize(state: ListViewStateModel): number {
    return state.pageSize;
  }

  /**
   * Action handler - set filter
   */
  @Action(SupportRequestListViewActions.SetSupportRequestFilter)
  onSetSupportRequestFilter(ctx: StateContext<ListViewStateModel>, { filter }: SupportRequestListViewActions.SetSupportRequestFilter) {
    ctx.patchState({ filter });
  }

  /**
   * Action handler - set page index
   */
  @Action(SupportRequestListViewActions.SetSupportRequestPageIndex)
  onSetSupportRequestPageIndex(ctx: StateContext<ListViewStateModel>,
                               { pageIndex }: SupportRequestListViewActions.SetSupportRequestPageIndex) {
    ctx.patchState({ pageIndex });
  }

  /**
   * Action handler - set page size
   */
  @Action(SupportRequestListViewActions.SetSupportRequestPageSize)
  onSetSupportRequestPageSize(ctx: StateContext<ListViewStateModel>,
                              { pageSize }: SupportRequestListViewActions.SetSupportRequestPageSize) {
    ctx.patchState({ pageSize });
  }

  /**
   * Action handler - set sort
   */
  @Action(SupportRequestListViewActions.SetSupportRequestSort)
  onSetSupportRequestSort(ctx: StateContext<ListViewStateModel>, { sort }: SupportRequestListViewActions.SetSupportRequestSort) {
    ctx.patchState({ sort });
  }

  /**
   * Action handler - reset listview
   */
  @Action(SupportRequestListViewActions.ResetSupportRequestListView)
  onResetSupportRequestListView(ctx: StateContext<ListViewStateModel>) {
    ctx.setState({
      filter: '',
      pageIndex: 0,
      pageSize: 10,
      sort: { active: 'ticketNumber', direction: 'asc' }
    });
  }

}
