import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import * as IssueTrackerListViewActions from './issue-tracker-listview-state.actions';
import { ListViewStateModel } from '../listview-state.model';

/**
 * State class for list views
 */
@Injectable()
@State<ListViewStateModel>({
  name: 'issueTrackerListview',
  defaults: {
    filter: '',
    pageIndex: 0,
    pageSize: 10,
    sort: { active: 'dateCreated', direction: 'desc' }
  }
})
export class IssueTrackerListViewState {

  /**
   * Get the filter string applied to the support request list
   *
   * @returns IssueTrackerListViewState.filter
   */
   @Selector()
   static filter(state: ListViewStateModel): string {
     return state.filter;
   }

  /**
   * Get the sort property applied to the support request list
   *
   * @returns IssueTrackerListViewState.sort.active
   */
  @Selector()
  static sortActive(state: ListViewStateModel): string {
    return state.sort.active;
  }

  /**
   * Get the sort direction applied to the support request list
   *
   * @returns IssueTrackerListViewState.sort.direction
   */
  @Selector()
  static sortDirection(state: ListViewStateModel): string {
    return state.sort.direction;
  }

  /**
   * Get the page index of the support request list
   *
   * @returns IssueTrackerListViewState.pageIndex
   */
  @Selector()
  static pageIndex(state: ListViewStateModel): number {
    return state.pageIndex;
  }

  /**
   * Get the page size of the support request list
   *
   * @returns IssueTrackerListViewState.pageIndex
   */
  @Selector()
  static pageSize(state: ListViewStateModel): number {
    return state.pageSize;
  }

  /**
   * Action handler - set filter
   */
  @Action(IssueTrackerListViewActions.SetIssueTrackerFilter)
  onSetIssueTrackerFilter(ctx: StateContext<ListViewStateModel>, { filter }: IssueTrackerListViewActions.SetIssueTrackerFilter) {
    ctx.patchState({ filter });
  }

  /**
   * Action handler - set page index
   */
  @Action(IssueTrackerListViewActions.SetIssueTrackerPageIndex)
  onSetIssueTrackerPageIndex(ctx: StateContext<ListViewStateModel>,
                               { pageIndex }: IssueTrackerListViewActions.SetIssueTrackerPageIndex) {
    ctx.patchState({ pageIndex });
  }

  /**
   * Action handler - set page size
   */
  @Action(IssueTrackerListViewActions.SetIssueTrackerPageSize)
  onSetIssueTrackerPageSize(ctx: StateContext<ListViewStateModel>,
                              { pageSize }: IssueTrackerListViewActions.SetIssueTrackerPageSize) {
    ctx.patchState({ pageSize });
  }

  /**
   * Action handler - set sort
   */
  @Action(IssueTrackerListViewActions.SetIssueTrackerSort)
  onSetIssueTrackerSort(ctx: StateContext<ListViewStateModel>, { sort }: IssueTrackerListViewActions.SetIssueTrackerSort) {
    ctx.patchState({ sort });
  }

  /**
   * Action handler - reset listview
   */
  @Action(IssueTrackerListViewActions.ResetIssueTrackerListView)
  onResetIssueTrackerListView(ctx: StateContext<ListViewStateModel>) {
    ctx.setState({
      filter: '',
      pageIndex: 0,
      pageSize: 10,
      sort: { active: 'dateCreated', direction: 'asc' }
    });
  }

}
