import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import * as LocationsListViewActions from './location-listview-state.actions';
import { ListViewStateModel } from '../listview-state.model';

/**
 * State class for list views
 */
@Injectable()
@State<ListViewStateModel>({
  name: 'locationListview',
  defaults: {
    filter: '',
    pageIndex: 0,
    pageSize: 10,
    sort: { active: 'name', direction: 'asc' }
  }
})
export class LocationListViewState {

  /**
   * Get the filter string applied to the location list
   *
   * @returns LocationListViewState.filter
   */
  @Selector()
  static filter(state: ListViewStateModel): string {
    return state.filter;
  }

  /**
   * Get the sort property applied to the location list
   *
   * @returns LocationListViewState.sort.active
   */
  @Selector()
  static sortActive(state: ListViewStateModel): string {
    return state.sort.active;
  }

  /**
   * Get the sort direction applied to the location list
   *
   * @returns LocationListViewState.sort.direction
   */
  @Selector()
  static sortDirection(state: ListViewStateModel): string {
    return state.sort.direction;
  }

  /**
   * Get the page index of the location list
   *
   * @returns LocationListViewState.pageIndex
   */
  @Selector()
  static pageIndex(state: ListViewStateModel): number {
    return state.pageIndex;
  }

  /**
   * Get the page size of the location list
   *
   * @returns LocationListViewState.pageIndex
   */
  @Selector()
  static pageSize(state: ListViewStateModel): number {
    return state.pageSize;
  }

  /**
   * Action handler - set filter
   */
  @Action(LocationsListViewActions.SetLocationFilter)
  onSetLocationsFilter(ctx: StateContext<ListViewStateModel>, { filter }: LocationsListViewActions.SetLocationFilter) {
    ctx.patchState({ filter });
  }

  /**
   * Action handler - set page index
   */
  @Action(LocationsListViewActions.SetLocationPageIndex)
  onSetLocationsPageIndex(ctx: StateContext<ListViewStateModel>, { pageIndex }: LocationsListViewActions.SetLocationPageIndex) {
    ctx.patchState({ pageIndex });
  }

  /**
   * Action handler - set page size
   */
  @Action(LocationsListViewActions.SetLocationPageSize)
  onSetLocationsPageSize(ctx: StateContext<ListViewStateModel>, { pageSize }: LocationsListViewActions.SetLocationPageSize) {
    ctx.patchState({ pageSize });
  }

  /**
   * Action handler - set sort
   */
  @Action(LocationsListViewActions.SetLocationSort)
  onSetLocationsSort(ctx: StateContext<ListViewStateModel>, { sort }: LocationsListViewActions.SetLocationSort) {
    ctx.patchState({ sort });
  }

  /**
   * Action handler - reset listview
   */
  @Action(LocationsListViewActions.ResetLocationListView)
  onResetLocationsListView(ctx: StateContext<ListViewStateModel>) {
    ctx.setState({
      filter: '',
      pageIndex: 0,
      pageSize: 10,
      sort: { active: 'name', direction: 'asc' }
    });
  }

}
