<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>
  <div class="warning-message">
    <em class="fa fa-exclamation-triangle"></em>
    {{ data.message }}
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button type='button' class="ml-auto" mat-dialog-close>Cancel</button>
  <button class="btn btn-danger" (click)="onConfirmDelete()">Delete</button>
</mat-dialog-actions>

