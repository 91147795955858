export class AuthUtils {

  constructor() { }

  /**
   * Retrieve auth data from local storage
   */
  static getAuthData() {
   const token = localStorage.getItem('token');
   const refreshToken = localStorage.getItem('refreshToken');
   const expirationDate = localStorage.getItem('expiration');
   const userId = localStorage.getItem('userId');
   if (!token || !refreshToken || !expirationDate || !userId ) {
     return; // no auth data found, so return
   }
   return { // return the auth data
     token,
     refreshToken,
     expirationDate: new Date(expirationDate),
     userId
   };
 }

  /**
   * Save auth data to local storage
   *
   * @param token The JWT token
   * @param refreshToken The refresh token
   * @param expirationDate The token expiration date
   * @param userId The ID of the authenticated user
   */
  static saveAuthData(token: string, refreshToken: string, expirationDate: Date, userId: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('expiration', expirationDate.toISOString());
    localStorage.setItem('userId', userId);
  }

  /**
   * Refresh auth data in local storage
   *
   * @param token The JWT token
   * @param expirationDate The token expiration date
   */
  static refreshAuthData(token: string, expirationDate: Date) {
    localStorage.setItem('token', token);
    localStorage.setItem('expiration', expirationDate.toISOString());
  }

  /**
   * Remove auth data from local storage
   */
  static clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiration');
    localStorage.removeItem('userId');
  }


}
