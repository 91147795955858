import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Navigate } from '@ngxs/router-plugin';
import { Subscription } from 'rxjs';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthState } from './../../shared/state/auth/auth.state';
import { Login, LoginFailed } from '../../shared/state/auth/auth-state.actions';
import { LoginCredentials } from './login-credentials.model';
import { LoginFormService } from './login-form.service';
import { SettingsService } from './../../core/settings/settings.service';
import { TranslatorService } from '../../core/translator/translator.service';
import { ConsoleState } from '../../shared/state/console/console.state';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginFormService]
})
export class LoginComponent implements OnInit, OnDestroy {
  isInvalid: boolean;
  loginForm: UntypedFormGroup;
  private loginFailedSubscription: Subscription;
  private eventSubscriptions: Subscription[] = [];

  constructor(
    private actions: Actions,
    private firebaseAuth: AngularFireAuth,
    private loginFormService: LoginFormService,
    private settings: SettingsService,
    private store: Store,
    private translator: TranslatorService) { }

  async ngOnInit() {
    // prevent users from accessing login page if logged in
    if (this.store.selectSnapshot(AuthState.token)) {
      this.eventSubscriptions.push(this.store.select(ConsoleState.isMonitoringEditor).subscribe(permission => {
        if (permission === false) {
          this.store.dispatch(new Navigate(['venues']));
        } else if (permission) {
          this.store.dispatch(new Navigate(['monitoring']));
        }
      }))
    }

    // detect device language and use for default language
    this.firebaseAuth.useDeviceLanguage();
    let language = await this.firebaseAuth.languageCode;
    if (!language) {
      language = 'en';
    } else if (language.indexOf('-') > 0) {
      language = language.split('-')[0];
    }
    this.translator.useLanguage(language);

    // set up subscription to LoginFailed action
    this.loginFailedSubscription = this.actions.pipe(ofActionDispatched(LoginFailed)).subscribe(() => {
      this.isInvalid = true;
      this.loginFormService.resetForm();
    });

    // initialize form
    this.loginForm = this.loginFormService.form;
  }

  /**
   * Get an app setting value from the settings service
   *
   * @param name Name of the app setting to retrieve
   */
  getAppSetting(name: string) {
    return this.settings.getAppSetting(name);
  }

  /**
   * Attempt login
   *
   * @param credentials Username and password collected from the login form
   */
  async login(credentials: LoginCredentials) {
    this.store.dispatch(new Login( {username: credentials.username, password: credentials.password} ));
  }

  ngOnDestroy() {
    if (this.loginFailedSubscription) {
      this.loginFailedSubscription.unsubscribe();
    }
    this.eventSubscriptions.forEach(sub => sub.unsubscribe());
  }


}
