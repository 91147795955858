import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';

import { AuthState } from '../state/auth/auth.state';

@Injectable()
export class AuthGuard  {

  constructor(private router: Router, private store: Store) {}
​
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.store.selectSnapshot(AuthState.token);
    if (!token) {
      // store deep link in local storage
      if (state.url !== '/' && state.url !== '/login') {
        localStorage.setItem('redirectUrl', state.url);
      }
      this.router.navigateByUrl('/login');
    }

    return true;
  }
​
}
