import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';

@Injectable()
export class GeofenceFormService {

  public form: UntypedFormGroup;
  public geofenceTypes = [
    'Bathroom',
    'Bath Tub',
    'Bed',
    'Bedroom',
    'Chair',
    'Common',
    'Community Door',
    'Exit',
    'Outside',
    'Refrigerator',
    'Restricted',
    'Sink',
    'Stove',
    'Toilet',
    'TV Chair',
    'Living Room',
    'Kitchen'
  ];
  public activityTypes = [
    'Social',
    'Dining',
    'Personal Care',
    'Fitness',
    'Other'
  ];

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      geofenceName: [null, Validators.required],
      geofenceType: [null, Validators.required],
      activity: [null, Validators.required]
    });
  }

  /**
   * Set form control values with data from the selected geofence
   *
   * @param name The name of the geofence
   * @param type The geofence type
   * @param activity The geofence activity
   */
  populateGeofenceDetail(name: string, type: string, activity: string): void {
    this.form.get('geofenceName').setValue(name);
    this.form.get('geofenceType').setValue(type);
    this.form.get('activity').setValue(activity);
  }

  resetForm() {
    this.form.reset();
  }


}
