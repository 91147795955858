

// Events - Actions that result from an event

import { Unit } from "../../../models/unit.model";
import { UnitDetailsFloor } from "../../models/unit-details-floor.model";


/**
 * Action dispatched to set the mode
 */
export class SetMode {
  static type = '[UnitDetails] SetMode';
  constructor(public mode: string) {}
}

/**
 * Action dispatched to set the list of unsaved floors
 */
export class SetUnsavedFloors {
  static type = '[UnitDetails] SetUnsavedFloors';
  constructor(public payload: {floors: UnitDetailsFloor[], saved?: boolean}) {}
}

/**
 * Action dispatched to add a floor to unsaved floors
 */
export class AddUnsavedFloor {
  static type = '[UnitDetails] AddUnsavedFloor';
  constructor(public floor: UnitDetailsFloor) {}
}

/**
 * Action dispatched to remove a floor from unsaved floors
 */
 export class DeleteUnsavedFloorById {
  static type = '[UnitDetails] DeleteUnsavedFloorById';
  constructor(public floorId: string) {}
}

/**
 * Action dispatched to updatea a floor from unsaved floors
 */
 export class UpdateUnsavedFloor {
  static type = '[UnitDetails] UpdateUnsavedFloor';
  constructor(public payload: {index: number, floor: UnitDetailsFloor}) {}
}

/**
 * Action dispatched to updatea a floor geofence
 */
 export class UpdateFloorGeofence {
  static type = '[UnitDetails] UpdateFloorGeofence';
  constructor(public payload: {index: number, geofence: {id?: number, name: string, geometry: {type: string, coordinates: Array<any>}}}) {}
}

/**
 * Action dispatched to updatea a floor extent
 */
 export class UpdateFloorExtentById {
  static type = '[UnitDetails] UpdateFloorExtentById';
  constructor(public payload: {floorId: string, extent: Array<number>}) {}
}

/**
 * Action dispatched to reset the unit detail state to default
 */
export class ResetUnitDetailsState {
  static type = '[UnitDetails] ResetUnitDetailsState';
}

/**
 * Action dispatched to reset the unit detail and mode state to default
 */
export class ResetUnitDetailsModeState {
  static type = '[UnitDetails] ResetUnitDetailsModeState';
}

/**
 * Action dispatched to set
 */
export class SetIsEditingGeofence {
  static type = '[UnitDetails] SetIsEditingGeofence';
  constructor(public isEditingGeofence: boolean) {}
}

/**
 * Action dispatched to reset the profile detail state to default
 */
export class SetUnsavedUnit {
  static type = '[UnitDetails] SetUnsavedUnit';
  constructor(public unsavedUnit: Unit) {}
}


