import { Geofence } from '../models/geofence.model';

/**
 * Action dispatched to add a geofence
 */
export class AddGeofence {
  static type = '[Geofences] AddGeofence';
  constructor(public geofence: Geofence) {}
}

/**
 * Action dispatched to add a selected geofence
 */
export class AddSelectedGeofence {
  static type = '[Geofences] AddSelectedGeofence';
  constructor(public geofenceId: number) {}
}

/**
 * Action dispatched to delete a geofence
 */
export class DeleteGeofence {
  static type = '[Geofences] DeleteGeofence';
  constructor(public geofenceId: number) {}
}

/**
 * Action dispatched to update geofence details
 */
export class UpdateGeofenceDetails {
  static type = '[Geofences] UpdateGeofenceDetails';
  constructor(public payload: { geofenceId: number, name: string, type: string, activity: string }) {}
}

/**
 * Action dispatched to update geofence geometry
 */
export class UpdateGeofenceGeometry {
  static type = '[Geofences] UpdateGeofenceGeometry';
  constructor(public payload: { geofenceId: number, geometry: {} }) {}
}

/**
 * Action dispatched to set the Geofence mode - default is 'display'
 */
export class SetGeofenceMode {
  static type = '[Geofences] SetGeofenceMode';
  constructor(public geofenceMode: string ) {}
}

/**
 * Action dispatched to set the selected geofence. This
 *  is set when a geofence geometry is being edited.
 */
export class SetSelectedGeofence {
  static type = '[Geofences] SetSelectedGeofence';
  constructor(public selectedGeofenceId: number ) {}
}

/**
 * Action dispatched to reset geofence data
 */
export class ResetGeofenceData {
  static type = '[Geofences] ResetGeofenceData';
}

/**
 * Action dispatched to reset the selected geofence
 */
export class ResetSelectedGeofence {
  static type = '[Geofences] ResetSelectedGeofence';
}

/**
 * Action dispatched to reset the selected geofences to default
 */
export class ResetSelectedGeofences {
  static type = '[Geofences] ResetSelectedGeofences';
}

/**
 * Action dispatched to reset the geofences state to default
 */
export class ResetGeofencesState {
  static type = '[Geofences] ResetGeofencesState';
}
