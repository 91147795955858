import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import * as NcsChannelsActions from './ncsChannels-state.actions';
import { NcsChannel } from './../../models/ncsChannel.model';
import { NcsChannelsStateModel } from './ncsChannels-state.model';

/**
 * State class for ncsChannels
 */
@Injectable()
@State<NcsChannelsStateModel>({
  name: 'ncsChannels',
  defaults: {
    activatedChannels: []
  }
})
export class NcsChannelsState {

  /**
   * Get the list of activated ncsChannels
   *
   * @returns NcsChannelsState.activatedNcsChannels
   */
  @Selector()
  static activatedNcsChannels(state: NcsChannelsStateModel): NcsChannel[] {
    return state.activatedChannels;
  }

  /**
   * Action handler - add an activated ncsChannel to state
   */
  @Action(NcsChannelsActions.AddActivatedNcsChannel)
  onAddActivatedNcsChannel(ctx: StateContext<NcsChannelsStateModel>, action: NcsChannelsActions.AddActivatedNcsChannel) {
    const state = ctx.getState();
    const activatedChannels = [...state.activatedChannels];
    activatedChannels.push(action.ncsChannel);
    ctx.patchState({
      activatedChannels
    });
  }

  /**
   * Action handler - delete an activated ncsChannel from state
   */
  @Action(NcsChannelsActions.DeleteActivatedNcsChannel)
  onDeleteActivatedNcsChannel(ctx: StateContext<NcsChannelsStateModel>, action: NcsChannelsActions.DeleteActivatedNcsChannel) {
    const state = ctx.getState();
    const activatedChannels = [...state.activatedChannels];
    const index = activatedChannels.findIndex(x => x.id === action.ncsChannelId);
    activatedChannels.splice(index, 1);
    ctx.patchState({
      activatedChannels
    });
  }

  /**
   * Action handler - update an activated ncsChannel in state
   */
  @Action(NcsChannelsActions.UpdateActivatedNcsChannel)
  onUpdateActivatedNcsChannel(ctx: StateContext<NcsChannelsStateModel>, action: NcsChannelsActions.UpdateActivatedNcsChannel) {
    const state = ctx.getState();
    const activatedChannels = [...state.activatedChannels];
    const index = activatedChannels.findIndex(x => x.id === action.ncsChannel.id);
    activatedChannels[index] = action.ncsChannel;
    ctx.patchState({
      activatedChannels
    });
  }

  /**
   * Action handler - reset the ncsChannels state to default
   */
  @Action(NcsChannelsActions.ResetNcsChannelsState)
  onResetNcsChannelsState(ctx: StateContext<NcsChannelsStateModel>) {
    ctx.setState({
      activatedChannels: []
    });
  }

}
