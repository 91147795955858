
  <h1 mat-dialog-title>Copy Issue Details</h1>

  <mat-dialog-content>
    
    <label for="clipboard-example-textarea">Issue details:</label>
    <textarea id="clipboard-example-textarea" [(ngModel)]="formattedIssue"></textarea>
    <button mat-raised-button color="primary" class="btn btn-bloc float-right" (click)="onClickCopy()">
      <span *ngIf="copied"><i class="fas fa-check"></i></span>
      <span *ngIf="!copied"><i class="fas fa-copy"></i></span>
      Copy to clipboard
    </button>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button type='button' mat-dialog-close>Close</button>
  </mat-dialog-actions>

