import { Sensor } from './../../models/sensor.model';

/**
 * Action dispatched to add an activated sensor to state
 */
export class AddActivatedSensor {
  static type = '[Sensors] AddActivatedSensor';
  constructor(public sensor: Sensor) {}
}

/**
 * Action dispatched to delete an activated sensor from state
 */
export class DeleteActivatedSensor {
  static type = '[Sensors] DeleteActivatedSensor';
  constructor(public sensorId: string) {}
}

/**
 * Action dispatched to update an activated sensor in state
 */
export class UpdateActivatedSensor {
  static type = '[Sensors] UpdateActivatedSensor';
  constructor(public sensor: Sensor) {}
}

/**
 * Action dispatched to reset the sensors state to default
 */
export class ResetSensorsState {
  static type = '[Sensors] ResetSensorsState';
}
