import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';

import { Location } from './../models/location.model';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ErrorDialogComponent } from '../components/error-dialog/error-dialog.component';
import { Store } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
const API_URL_PREFIX = environment.apiUrl + '/organizations/';

@Injectable({providedIn: 'root'})
export class LocationsService {

  constructor(
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private http: HttpClient,
    private store: Store
    ) { }

  /**
   * Get the organization location document
   *
   * @param organizationId The organization identifier
   */
  getOrganizationLocation(organizationId: string) {
    return this.afs.firestore.collection('organizations/' + organizationId + '/locations').doc(organizationId).get();
  }

  /**
   * Get a location document
   *
   * @param organizationId The organization identifier
   * @param venueId The venue identifier
   */
  getLocation(organizationId: string, venueId: string) {
    return this.afs.firestore.collection('organizations/' + organizationId + '/locations').doc(venueId).get();
  }

  /**
   * Subscribe to locations for an organization
   *
   * @param organization The selected organization
   */
   fetchLocations(organization: string): Observable<Location[]> {
    const locationsCollection = this.afs.collection<Location>('organizations/' + organization + '/locations');
    return locationsCollection.snapshotChanges().pipe(map(locationDocs => {
      return locationDocs.map(locationDoc => {
        const data = locationDoc.payload.doc.data() as Location;
        const id = locationDoc.payload.doc.id;
        return {id: id, ...data};
      });
    }));
  }

  /**
   * Subscribe to locations for an organization
   *
   * @param organization The selected organization
   */
   fetchDistrictCampusLocations(organization: string): Observable<Location[]> {
    const locationsCollection = this.afs.collection<Location>('organizations/' + organization + '/locations', ref => ref.where('type', 'in', ['district', 'campus']));
    return locationsCollection.snapshotChanges().pipe(map(locationDocs => {
      return locationDocs.map(locationDoc => {
        const data = locationDoc.payload.doc.data() as Location;
        const id = locationDoc.payload.doc.id;
        return {id: id, ...data};
      });
    }));
  }

  /**
   * Display a dialog with an error
   *
   * @param title The error title
   * @param message The error message
   */
   onError(title: string, message: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = { title, message };
    this.dialog.open(ErrorDialogComponent, dialogConfig);
  }

  /**
   * Create a location
   * 
   * @param organization The selected organization
   * @param location The location creation body requires {name, parentLocation, timezone}
   */
  async createDistrictCampusLocation(organization: string, location: Location) {
    const locationUrl = API_URL_PREFIX + organization + '/locations';
    try {
      await this.http.post<any>(locationUrl, location).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      return error.error.message;
    }
  }

  /**
   * Update a location
   * 
   * @param organization The selected organization
   * @param location The location creation body requires {name, parentLocation, timezone}
   * @param locationId the location id
   */
  async updateLocation(organization: string, locationId: string, location: Location) {
    const locationUrl = API_URL_PREFIX + organization + '/locations/' + locationId;
    try {
      await this.http.put<any>(locationUrl, location).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      return error.error.message;
    }
  
  }

  /**
   * Delete a location
   * 
   * @param organization The selected organization
   * @param locationId the location id
   */
  async deleteLocation(organization: string, locationId: string) {
    const locationUrl = API_URL_PREFIX + organization + '/locations/' + locationId;
    try {
      await this.http.delete<any>(locationUrl).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      return error.error.message;
    }
  }

  /**
   * Fetch a location document
   *
   * @param organization The organization identifier
   * @param locationId The venue identifier
   */
   async fetchLocation(organization: string, locationId: string) {
    const locationUrl = API_URL_PREFIX + organization + '/locations/' + locationId;
    try {
      const locationData = await this.http.get<any>(locationUrl).toPromise();
      return {
        name: locationData.name,
        type: locationData.type,
        parent: locationData.parent,
        access: locationData.access
      };
    } catch (error) {
      console.log(error);
      return error.error.message;
    }
  }
}
