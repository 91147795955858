import { SortObj } from '../listview-state.model';

/**
 * Action dispatched to set search filter
 */
export class SetIssueTrackerFilter {
  static type = '[IssueTrackerListView] SetIssueTrackerFilter';
  constructor(public filter: string) {}
}

/**
 * Action dispatched to set page index
 */
export class SetIssueTrackerPageIndex {
  static type = '[IssueTrackerListView] SetIssueTrackerPageIndex';
  constructor(public pageIndex: number) {}
}

/**
 * Action dispatched to set page size
 */
export class SetIssueTrackerPageSize {
  static type = '[IssueTrackerListView] SetIssueTrackerPageSize';
  constructor(public pageSize: number) {}
}

/**
 * Action dispatched to set sort order
 */
export class SetIssueTrackerSort {
  static type = '[IssueTrackerListView] SetIssueTrackerSort';
  constructor(public sort: SortObj) {}
}

/**
 * Action dispatched to reset the IssueTrackerListView state to default
 */
export class ResetIssueTrackerListView {
  static type = '[IssueTrackerListView] ResetIssueTrackerListView';
}
