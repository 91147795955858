import { AuthGuard } from '../shared/services/auth.guard';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from '../layout/layout.component';
import { Routes } from "@angular/router";

export const routes: Routes = [

  // Not lazy-loaded routes
  { path: 'login', component: LoginComponent },

  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: '/monitoring', pathMatch: 'full' },
      { path: 'venues', loadChildren: () => import('./venues/venues.module').then(m => m.VenuesModule) },
      { path: 'locations', loadChildren: () => import('./locations/locations.module').then(m => m.LocationsModule) },
      { path: 'roles', loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule) },
      { path: 'users', loadChildren: () => import('./users/users.module').then(m => m.UsersModule) },
      { path: 'cache', loadChildren: () => import('./cache/cache.module').then(m => m.CacheModule) },
      { path: 'account', loadChildren: () => import('./account/account.module').then(m => m.AccountModule) },
      { path: 'support', loadChildren: () => import('./support/support.module').then(m => m.SupportModule) },
      { path: 'issue-tracker', loadChildren: () => import('./issue-tracker/issue-tracker.module').then(m => m.IssueTrackerModule) },
      { path: 'positions', loadChildren: () => import('./positions/positions.module').then(m => m.PositionsModule) },
      { path: 'adls', loadChildren: () => import('./adls/adls.module').then(m => m.AdlsModule) },
      { path: 'support-requests', loadChildren: () => import('./support-requests/support-request.module').then(m => m.SupportRequestModule) },
      { path: 'monitoring', loadChildren: () => import('./monitoring/monitoring.module').then(m => m.MonitoringModule) },
      { path: 'testing', loadChildren: () => import('./testing/testing.module').then(m => m.TestingModule) }
    ]
  },

  // Not found
  { path: '**', redirectTo: '/venues/floors' }

];
