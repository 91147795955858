<h1 mat-dialog-title>{{ data.title }}</h1>
<mat-dialog-content>

  <!-- Alert element to display instruction -->
  <alert type="info" class="text-center" [dismissible]="false">
    {{ data.message }}
  </alert>

  <!-- Alert element to display empty list message -->
  <alert *ngIf="!dataSource?.data || dataSource.data.length === 0" type="warning" class="text-center">No channels activated</alert>

  <!-- START Sensor List -->
  <div class="card card-default" *ngIf="dataSource?.data?.length > 0">
    <table mat-table [dataSource]="dataSource">

      <!-- Beacon ID Column -->
      <ng-container matColumnDef="beaconId">
        <th mat-header-cell *matHeaderCellDef>  Beacon ID </th>
        <td mat-cell *matCellDef="let row; let element" (click)="onSelect(element.id)"
          [ngStyle]="setNcsChannelStyle(element.provisionTime)">
            <div class="sensor">{{element.beaconId}}</div>
        </td>
      </ng-container>

      <!-- Channel ID Column -->
      <ng-container matColumnDef="channelId">
        <th mat-header-cell *matHeaderCellDef>  Channel ID </th>
        <td mat-cell *matCellDef="let row; let element" (click)="onSelect(element.id)"
          [ngStyle]="setNcsChannelStyle(element.provisionTime)">
            <div class="sensor">{{element.channelId}}</div>
        </td>
      </ng-container>

      <!-- Channel in use Column -->
      <ng-container matColumnDef="clearProfileIds">
        <th mat-header-cell *matHeaderCellDef>  Assigned Clearer </th>
        <td mat-cell *matCellDef="let row; let element" (click)="onSelect(element.id)"
          [ngStyle]="setNcsChannelStyle(element.provisionTime)">
            <div class="sensor"><i class="fas fa-check" *ngIf="element.clearProfileIds?.length > 0"></i></div>
        </td>
      </ng-container>

      <!-- Reset Column -->
      <ng-container matColumnDef="reset">
        <th mat-header-cell *matHeaderCellDef>Reset</th>
        <td mat-cell *matCellDef="let row; let element" (click)="resetChannel(element)">
          <div class="btn btn-block reset">Reset</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <!-- END Sensor List-->

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button type='button' class="ml-auto btn-primary" mat-dialog-close>Cancel</button>
</mat-dialog-actions>
