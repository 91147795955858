import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import * as RoleListViewActions from './role-listview-state.actions';
import { ListViewStateModel } from '../listview-state.model';

/**
 * State class for list views
 */
@Injectable()
@State<ListViewStateModel>({
  name: 'roleListview',
  defaults: {
    filter: '',
    pageIndex: 0,
    pageSize: 10,
    sort: { active: 'name', direction: 'asc' }
  }
})
export class RoleListViewState {

  /**
   * Get the filter string applied to the role list
   *
   * @returns RoleListViewState.filter
   */
  @Selector()
  static filter(state: ListViewStateModel): string {
    return state.filter;
  }

  /**
   * Get the sort property applied to the role list
   *
   * @returns RoleListViewState.sort.active
   */
  @Selector()
  static sortActive(state: ListViewStateModel): string {
    return state.sort.active;
  }

  /**
   * Get the sort direction applied to the role list
   *
   * @returns RoleListViewState.sort.direction
   */
  @Selector()
  static sortDirection(state: ListViewStateModel): string {
    return state.sort.direction;
  }


  /**
   * Get the page index of the role list
   *
   * @returns RoleListViewState.pageIndex
   */
  @Selector()
  static pageIndex(state: ListViewStateModel): number {
    return state.pageIndex;
  }

  /**
   * Get the page size of the role list
   *
   * @returns RoleListViewState.pageIndex
   */
  @Selector()
  static pageSize(state: ListViewStateModel): number {
    return state.pageSize;
  }

  /**
   * Action handler - set filter
   */
  @Action(RoleListViewActions.SetRoleFilter)
  onSetRoleFilter(ctx: StateContext<ListViewStateModel>, { filter }: RoleListViewActions.SetRoleFilter) {
    ctx.patchState({ filter });
  }

  /**
   * Action handler - set page index
   */
  @Action(RoleListViewActions.SetRolePageIndex)
  onSetRolePageIndex(ctx: StateContext<ListViewStateModel>, { pageIndex }: RoleListViewActions.SetRolePageIndex) {
    ctx.patchState({ pageIndex });
  }

  /**
   * Action handler - set page size
   */
  @Action(RoleListViewActions.SetRolePageSize)
  onSetRolePageSize(ctx: StateContext<ListViewStateModel>, { pageSize }: RoleListViewActions.SetRolePageSize) {
    ctx.patchState({ pageSize });
  }

  /**
   * Action handler - set sort
   */
  @Action(RoleListViewActions.SetRoleSort)
  onSetRoleSort(ctx: StateContext<ListViewStateModel>, { sort }: RoleListViewActions.SetRoleSort) {
    ctx.patchState({ sort });
  }

  /**
   * Action handler - reset listview
   */
  @Action(RoleListViewActions.ResetRoleListView)
  onResetRoleListView(ctx: StateContext<ListViewStateModel>) {
    ctx.setState({
      filter: '',
      pageIndex: 0,
      pageSize: 10,
      sort: { active: 'name', direction: 'asc' }
    });
  }

}
