import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngxs/store';

import { ConsoleState } from '../../state/console/console.state';
import { CreateOrganizationFormService } from './create-organization-form.service';
import { Location } from '../../models/location.model';

@Component({
  selector: 'app-create-organization-dialog',
  templateUrl: './create-organization-dialog.component.html',
  styleUrls: ['./create-organization-dialog.component.scss'],
  providers: [CreateOrganizationFormService]
})
export class CreateOrganizationDialogComponent implements OnInit {

  createOrganizationForm: UntypedFormGroup;
  parentOptions: Location[];

  constructor(
    private createOrganizationFormService: CreateOrganizationFormService,
    private dialogRef: MatDialogRef<CreateOrganizationDialogComponent>,
    private store: Store) { }

  ngOnInit() {
    this.createOrganizationForm = this.createOrganizationFormService.form;
    this.parentOptions = this.store.selectSnapshot(ConsoleState.orgAccessList);

  }

  submit(form: UntypedFormGroup) {
    this.dialogRef.close(form.value);
  }

}
