import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

@Injectable()
export class UnitGeofenceFormService {

  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      geofenceName: [null, Validators.required],
    });
  }

  /**
   * Set form control values with data from the selected geofence
   *
   * @param name The name of the geofence
   * @param type The geofence type
   */
  populateGeofenceDetail(name: string): void {
    this.form.get('geofenceName').setValue(name);
  }

  resetForm() {
    this.form.reset();
  }


}
