import { NcsChannel } from './../../models/ncsChannel.model';

/**
 * Action dispatched to add an activated ncsChannel to state
 */
export class AddActivatedNcsChannel {
  static type = '[ncsChannels] AddActivatedNcsChannel';
  constructor(public ncsChannel: NcsChannel) {}
}

/**
 * Action dispatched to delete an activated ncsChannel from state
 */
export class DeleteActivatedNcsChannel {
  static type = '[ncsChannels] DeleteActivatedNcsChannel';
  constructor(public ncsChannelId: string) {}
}

/**
 * Action dispatched to update an activated ncsChannel in state
 */
export class UpdateActivatedNcsChannel {
  static type = '[ncsChannels] UpdateActivatedNcsChannel';
  constructor(public ncsChannel: NcsChannel) {}
}

/**
 * Action dispatched to reset the ncsChannels state to default
 */
export class ResetNcsChannelsState {
  static type = '[ncsChannels] ResetNcsChannelsState';
}
