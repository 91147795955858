import { Geofence } from '../../../routes/venues/geofences/models/geofence.model';
import { Floor } from '../../../routes/venues/models/floor.model';
import { FloorListItem } from '../../../routes/venues/models/floor-list-item.model';
import { Unit } from '../../../routes/venues/models/unit.model';
import { Venue } from './../../../routes/venues/models/venue.model';
import { Wearable } from '../../models/wearable.model';
import { DomeLight } from '../../../routes/venues/models/dome-light.model';
import { NcsProfile } from '../../../shared/models/ncs-profile.models';

/**
 * Action dispatched to set the floor list
 */
export class SetFloorList {
 static type = '[Venues] SetFloorList';
 constructor(public floorList: FloorListItem[]) {}
}

/**
 * Action dispatched to process the selected floor
 */
export class ProcessSelectedFloor {
  static type = '[Venues] ProcessSelectedFloor';
  constructor(public floorId: string) {}
}

/**
 * Action dispatched to set the selected floor
 */
export class SetSelectedFloor {
  static type = '[Venues] SetSelectedFloor';
  constructor(public floor: FloorListItem) {}
}

/**
 * Action dispatched to set the selected floor
 *  as a result of some other action
 */
export class SetByproductFloor {
  static type = '[Venues] SetByproductFloor';
  constructor(public floor: FloorListItem) {}
}

/**
 * Action dispatched to set the selected floor data
 */
export class SetFloorData {
  static type = '[Venues] SetFloorData';
  constructor(public floor: Floor) {}
}

/**
 * Action dispatched to set the units
 */
export class SetUnits {
  static type = '[Venues] SetUnits';
  constructor(public units: Unit[]) {}
}

/**
 * Action dispatched to set the wearables
 */
export class SetWearables {
  static type = '[Venues] SetWearables';
  constructor(public wearables: Wearable[]) {}
}

/**
 * Action dispatched to add a dome light
 */
export class AddDomeLight {
  static type = '[Venues] AddDomeLight';
  constructor(public domeLight: DomeLight) {}
}

/**
 * Action dispatched to remove a dome light
 */
export class RemoveDomeLight {
  static type = '[Venues] RemoveDomeLights';
  constructor(public domeLight: DomeLight) {}
}

/**
 * Action dispatched to update a dome light
 */
export class UpdateDomeLight {
  static type = '[Venues] UpdateDomeLights';
  constructor(public domeLight: DomeLight) {}
}

/**
 * Action dispatch to reset domelight
 */
export class ResetDomeLights {
  static type = '[Venues] ResetDomeLights';
  constructor() {}
}

/**
 * Action dispatched to add a ncs profile
 */
export class AddNcsProfile {
  static type = '[Venues] AddNcsProfile';
  constructor(public ncsProfile: NcsProfile) {}
}

/**
 * Action dispatched to remove a ncs profile
 */
export class RemoveNcsProfile {
  static type = '[Venues] RemoveNcsProfile';
  constructor(public ncsProfile: NcsProfile) {}
}

/**
 * Action dispatched to update a ncs profile
 */
export class UpdateNcsProfile {
  static type = '[Venues] UpdateNcsProfile';
  constructor(public ncsProfile: NcsProfile) {}
}

/**
 * Action dispatch to reset ncs profiles
 */
export class ResetNcsProfiles {
  static type = '[Venues] ResetNcsProfiles';
  constructor() {}
}

/**
 * Action dispatched to process the selected unit
 */
export class ProcessSelectedUnit {
  static type = '[Venues] ProcessSelectedUnit';
  constructor(public unitId: string) {}
}

/**
 * Action dispatched to set the selected unit
 */
export class SetSelectedUnit {
  static type = '[Venues] SetSelectedUnit';
  constructor(public unit: Unit) {}
}

/**
 * Action dispatched to set the selected unit
 *  as a result of some other action
 */
export class SetByproductUnit {
  static type = '[Venues] SetByproductUnit';
  constructor(public unit: Unit) {}
}

/**
 * Action dispatched to set the unit geofence
 */
export class SetUnitGeofence {
  static type = '[Venues] SetUnitGeofence';
  constructor(public geofence: Geofence) {}
}

/**
 * Action dispatched to process a list of venues
 */
export class ProcessVenues {
  static type = '[Venues] ProcessVenues';
  constructor(public venuesList: Venue[]) {}
}

/**
 * Action dispatched to set the venues the user may access
 */
export class SetVenues {
  static type = '[Venues] SetVenues';
  constructor(public venues: Venue[]) {}
}

/**
 * Action dispatched to set the selected venue
 */
export class SetSelectedVenue {
  static type = '[Venues] SetSelectedVenue';
  constructor(public venueId: string) {}
}

/**
 * Action dispatched to set the monitoring map position
 */
export class SetMonitoringMapPosition {
  static type = '[Venues] SetMonitoringMapPosition';
  constructor(public monitoringMapPosition: number[]) {}
}

/**
 * Action dispatched to reset the venues state to default
 */
export class ResetVenuesState {
  static type = '[Venues] ResetVenuesState';
}