import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable  } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ErrorDialogComponent } from './../../shared/components/error-dialog/error-dialog.component';
import { Floor, FloorMagicPlanPayload } from './models/floor.model';
import { FloorListItem } from './models/floor-list-item.model';
import { Unit } from './models/unit.model';
import { environment } from '../../../environments/environment';
import { Venue } from './models/venue.model';
import { AddDomeLight, AddNcsProfile, ProcessVenues, RemoveDomeLight, RemoveNcsProfile, ResetDomeLights, SetFloorData, SetFloorList, SetUnits, SetWearables, UpdateDomeLight, UpdateNcsProfile } from '../../shared/state/venues/venues-state.actions';
import { Role } from '../../shared/models/role.model';
import { Position } from '../../shared/models/position.model';
import { Wearable } from '../../shared/models/wearable.model';
import { SetIsLoading } from '../../shared/state/console/console-state.actions';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { DomeLight } from './models/dome-light.model';
import { Geofence } from './geofences/models/geofence.model';
import { NcsProfile } from '../../shared/models/ncs-profile.models';

const API_URL_PREFIX = environment.apiUrl + '/organizations/';
const FIRMWARE_LATEST = '2.1.2'; // This is hard coded for now
const DXF_SIZE_LIMIT = 10000000;

@Injectable({providedIn: 'root'})
export class VenuesService {

  wearablesSubscription: Subscription;
  unitsSubscription: Subscription;
  domeLightSubscription: Subscription;
  ncsProfileSubscription: Subscription;

  constructor(
    private afs: AngularFirestore,
    private dialog: MatDialog,
    private http: HttpClient,
    private storage: AngularFireStorage,
    private store: Store) {}

  /**
   * Fetch venues for an organization
   *
   * @param organization The selected organization
   */
  async fetchVenues(organization: string) {
    const venuesUrl = API_URL_PREFIX + organization + '/venues';
    try {
      const venueData = await this.http.get<any>(venuesUrl).toPromise();
      const venues = venueData.map(venue => {
        return {
          id: venue.id,
          name: venue.name,
          floors: venue.floors,
          parentLocation: venue.parentLocation,
          timezone: venue.timezone,
          contactName: venue.contactName,
          contactPhone: venue.contactPhone,
          status: venue.status,
          deletedDate: Date.parse(venue.deletedDate),
        };
      });
      if (venues.length > 0) {
        this.store.dispatch(new ProcessVenues(venues));
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Subscribe to floors for an organization and venue ID
   *
   * @param organization The selected organization
   * @param venueId The ID of the selected venue
   */
  fetchFloors(organization: string, venueId: string) {
    const floorUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/floors';
    this.http.get<{floors: FloorListItem[]}>(floorUrl).subscribe(floorList => {
      this.store.dispatch(new SetFloorList(floorList.floors));
    }, error => { // TODO update API to not return an error here
      this.store.dispatch(new SetFloorList([]));
    });
  }

  /**
   * Fetch a floor
   *
   * @param organization The organization associated with the floor
   * @param venueId The venue associated with the floor
   * @param floorId The floor identifier
   */
  fetchFloor(organization: string, venueId: string, floorId: string): void {
    const floorUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/floors/' + floorId;
    this.http.get<Floor>(floorUrl).subscribe(floor => {
      this.store.dispatch(new SetFloorData(floor));
    });
  }

  /**
   * Fetch a floor image
   *
   * @param organization The organization associated with the floor
   * @param venueId The venue associated with the floor
   * @param floorId The floor identifier
   */
  async fetchFloorImage(organization: string, venueId: string, floorId: string): Promise<{floorplan: {flatName: string, image: string}}> {
    const floorUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/floors/' + floorId + '/image';
    return await this.http.get<{floorplan: {flatName: string, image: string}}>(floorUrl).toPromise();
  }

  /**
   * Create a new floor
   *
   * @param organization The ID of the organization
   * @param venueId The ID of the venue
   * @param floor The floor data to be saved
   */
  createFloor(organization: string, venueId: string, floor: Floor) {
    const floorsUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/floors';
    const {floorPost, options} = this.floorToMultiPartForm(floor);

    return new Promise<void>((resolve, reject) => {
      this.http.post<{ message: string }>(floorsUrl, floorPost, options).subscribe(() => {
        this.fetchFloors(organization, venueId);
        resolve();
      }, (error) => {
        console.log(error);
        reject(error.error.message);
      });
    });
  }

  /**
   * Create a new floor
   *
   * @param organization The ID of the organization
   * @param venueId The ID of the venue
   * @param floor The floor data to be saved
   */
  createFloorByDxf(organization: string, venueId: string, floor: FloorMagicPlanPayload) {
    const floorsUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/floors/from/dxf';

    return new Promise<void>((resolve, reject) => {
      this.http.post<{ message: string }>(floorsUrl, floor).subscribe(() => {
        this.fetchFloors(organization, venueId);
        resolve();
      }, (error) => {
        console.log(error);
        reject(error.error.message);
      });
    });
  }

  /**
   * Update a floor
   *
   * @param organization The ID of the organization
   * @param venueId The ID of the venue
   * @param floorId The floor identifier of the floor to be updated
   * @param update An object containing the updated fields
   */
  updateFloor(organization: string, venueId: string, floorId: string, update: any) {
    const floorsUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/floors/' + floorId;
    const {floorPost, options} = this.floorToMultiPartForm(update);
    return new Promise<void>((resolve, reject) => {
      this.http.put<{ message: string }>(floorsUrl, floorPost, options).subscribe(() => {
        this.fetchFloors(organization, venueId);
        resolve();
      }, (error) => {
        reject(error.error.message);
      });
    });
  }

  /**
   * Update a floor
   *
   * @param organization The ID of the organization
   * @param venueId The ID of the venue
   * @param floorId The floor identifier of the floor to be updated
   * @param update An object containing the updated fields
   */
  updateFloorByDxf(organization: string, venueId: string, floorId: string, update: any) {
    // perform floor update
    const floorsUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/floors/' + floorId + '/from/dxf';
    return new Promise<void>((resolve, reject) => {
      this.http.put<{ message: string }>(floorsUrl, update).subscribe(() => {
        this.fetchFloors(organization, venueId);
        resolve();
      }, (error) => {
        reject(error.error.message);
      });
    });
  }

  /**
   * Delete a floor
   *
   * @param organization The ID of the organization
   * @param venueId The ID of the venue
   * @param floorId The floor identifier of the floor to be deleted
   */
  deleteFloor(organization: string, venueId: string, floorId: string) {
    return new Promise<void>((resolve, reject) => {
      // check for profiles or geofences assigned to the floor before deleting
      const profilesCollection = this.afs.collection<any>('organizations/' + organization + '/profiles',
        ref => ref.where('profileData.floorId', '==', floorId).where('venueId', '==', venueId));
      profilesCollection.get().subscribe( async querySnapshot => {
        if (querySnapshot.docs.length > 0) { // do not proceed if floor is assigned
          this.onError('Floor not deleted', 'This floor is assigned to a profile and cannot be deleted.');
          resolve();
        } else { // no profiles assigned, so check for geofences
          try {
            const count = await this.getFloorGeofenceCount(organization, venueId, floorId);
            if (count > 0) {
              this.onError('Floor not deleted', 'This floor contains geofences and cannot be deleted.');
              resolve();
            } else { // no geofences on floor, so proceed to delete the floor
              const floorsUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/floors/' + floorId;
              this.http.delete(floorsUrl).subscribe(() => {
                this.fetchFloors(organization, venueId);
                resolve();
              }, (error) => {
                reject(error.error);
              });
            }
          } catch (error) {
            reject({message: error});
          }
        }
      });
    });
  }

  /**
   * @param organization The ID of the organization
   * @param venueId The ID of the venue
   * @param floorId The ID of the floor
   */
  getFloorGeofenceCount(organization: string, venueId: string, floorId: string) {
    const geofencesUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/floors/' + floorId + '/geofenceCount';
    return new Promise<number>((resolve, reject) => {
      this.http.get<{count: number}>(geofencesUrl).subscribe((response) => {
        resolve(response.count);
      }, (error) => {
        reject(error.error.message);
      });
    });
  }

  /**
   * Fetch geofences for a venue unit and floor
   *
   * @param organization The selected organization
   * @param venueId The ID of the selected venue
   * @param floorId The ID of the selected floor
   * @param unitId The ID of the selected unit
   */
  async fetchFloorGeofences(organization: string, venueId: string, unitId: string, floorId: string): Promise<Geofence[]> {
    const geofencesUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/units/' + unitId + '/floors/' + floorId + '/geofences/full/floor';
    try {
      const geoData = await this.http.get<{geofences: Geofence[]}>(geofencesUrl).toPromise();
      return geoData.geofences;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Fetch units for unit view
   *
   * @param organization The selected organization
   * @param venueId The ID of the selected venue
   */
  fetchUnits(organization: string, venueId: string) {
    const unitsList: Unit[] = [];
    const unitsCollection = this.afs.collection<Unit>('organizations/' + organization + '/venues/' + venueId + '/units');
    this.unitsSubscription = unitsCollection.get().subscribe( querySnapshot => {
      for (const currentUnit of querySnapshot.docs) {
        unitsList.push({
          unitId: currentUnit.id,
          name: currentUnit.data().name,
          careLevel: currentUnit.data().careLevel,
          floors: currentUnit.data().floors,
          isShared: currentUnit.data().isShared,
          includeShared: currentUnit.data().includeShared,
          defaultFloorId: currentUnit.data().defaultFloorId,
          featureConfig: currentUnit.data().featureConfig
        });
      }
      if (unitsList.length > 0) {
        this.store.dispatch(new SetUnits(unitsList));
      }
    });
  }

  /**
   * Fetch dome lights for dome light list view
   *
   * @param organization The selected organization
   * @param venueId The ID of the selected venue
   */
  fetchDomeLights(organization: string, venueId: string) {
    try {
      const docCollection = this.afs.collection<DomeLight>('organizations/' + organization + '/venues/' + venueId + '/domeLights');
      this.domeLightSubscription = docCollection.stateChanges().subscribe( querySnapshot => {
        for (const currentDomeLightChange of querySnapshot) {
          const currentDomeLight = currentDomeLightChange.payload.doc;
          const domeLight = {
            id: currentDomeLight.id,
            name: currentDomeLight.data().name,
            geofenceIds: currentDomeLight.data().geofenceIds,
            channelId: currentDomeLight.data().channelId,
            beaconId: currentDomeLight.data().beaconId,
            x: currentDomeLight.data().x,
            y: currentDomeLight.data().y,
            z: currentDomeLight.data().z,
            floorId: currentDomeLight.data().floorId,
            active: currentDomeLight.data().active,
            activeNotificationIds: currentDomeLight.data().activeNotificationIds,
          }
          if (currentDomeLightChange.type === 'added') {
            this.store.dispatch(new AddDomeLight(domeLight));
          } else if (currentDomeLightChange.type === 'modified') {
            this.store.dispatch(new UpdateDomeLight(domeLight));
          }  else if (currentDomeLightChange.type === 'removed') {
            this.store.dispatch(new RemoveDomeLight(domeLight));
          }
        }
      });
    } catch (error) {
      this.store.dispatch(new ResetDomeLights());
    }
  }

  /**
   * Fetch ncs profiles for monitoring view
   *
   * @param organization The selected organization
   * @param venueId The ID of the selected venue
   * @param floorId the selected floor id
   */
  fetchNcsProfiles(organization: string, venueId: string) {
    try {
      const docCollection = this.afs.collection<NcsProfile>('organizations/' + organization + '/profiles',
      ref => ref
        // .where('profileData.floorId', '==', floorId)
        .where('venueId', '==', venueId)
        .where('profileType', '==', 'fixture')
        .where('active', '==', true)
        );
      this.ncsProfileSubscription = docCollection.stateChanges().subscribe( querySnapshot => {
        for (const currentProfileChange of querySnapshot) {
          const currentProfile = currentProfileChange.payload.doc;
          const profile = {id: currentProfile.id, ...currentProfile.data()};
          if (currentProfileChange.type === 'added') {
            this.store.dispatch(new AddNcsProfile(profile));
          } else if (currentProfileChange.type === 'modified') {
            this.store.dispatch(new UpdateNcsProfile(profile));
          }  else if (currentProfileChange.type === 'removed') {
            this.store.dispatch(new RemoveNcsProfile(profile));
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Fetch dome light for dome light list view
   *
   * @param organization The selected organization
   * @param venueId The ID of the selected venue
   */
  async fetchDomeLight(organization: string, venueId: string, domeLightId: string) {
    try {
      const unitsCollection = this.afs.collection<DomeLight>('organizations/' + organization + '/venues/' + venueId + '/domeLights');
      const domeLight = await unitsCollection.doc(domeLightId).get().toPromise();
      return {id: domeLight.id, ...domeLight.data()};
    } catch (error) {
      return null;
    }
  }

  async fetchVenueGeofences(organization: string, venueId: string) {
    const geofenceUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/geofences';
    try {
      const geoData = await this.http.get<{geofences: Geofence[]}>(geofenceUrl).toPromise();
      return geoData.geofences;
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * Display a dialog with an error
   *
   * @param title The error title
   * @param message The error message
   */
  onError(title: string, message: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = { title, message };
    this.dialog.open(ErrorDialogComponent, dialogConfig);
  }

  /**
   * Update a venue
   *
   * @param organization The selected organization
   * @param venueId the venue id
   */
  async fetchVenue(organization: string, venueId: string) {
    const venueUrl = API_URL_PREFIX + organization + '/venues/' + venueId;
    try {
      const venueData = await this.http.get<any>(venueUrl).toPromise();
      return {
        id: venueData.id,
        name: venueData.name,
        floors: venueData.floors,
        parentLocation: venueData.parentLocation,
        timezone: venueData.timezone,
        contactName: venueData.contactName,
        contactPhone: venueData.contactPhone,
        status: venueData.status,
        deletedDate: Date.parse(venueData.deletedDate),
        featureConfig: venueData.featureConfig,
        notes: venueData.notes
      };
    } catch (error) {
      console.log(error);
    }
  }


  /**
   * Create a venue
   *
   * @param organization The selected organization
   * @param venue The venue creation body requires {name, parentLocation, timezone}
   */
  async createVenue(organization: string, venue: Venue) {
    const venueUrl = API_URL_PREFIX + organization + '/venues';
    try {
      await this.http.post<any>(venueUrl, venue).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      return error.error.message;
    }
  }

  /**
   * Update a venue
   *
   * @param organization The selected organization
   * @param venue The venue creation body requires {name, parentLocation, timezone}
   * @param venueId the venue id
   */
  async updateVenue(organization: string, venueId: string, venue: Venue) {
    const venueUrl = API_URL_PREFIX + organization + '/venues/' + venueId;
    try {
      await this.http.put<any>(venueUrl, venue).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      return error.error.message;
    }

  }

  /**
   * Delete a venue
   *
   * @param organization The selected organization
   * @param venueId the venue id
   */
  async deleteVenue(organization: string, venueId: string) {
    const venueUrl = API_URL_PREFIX + organization + '/venues/' + venueId;
    try {
      await this.http.delete<any>(venueUrl).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      return error.error.message;
    }
  }

  /**
   * Convert a floor object to a multipart form object for json and binary transfer
   *
   * @param floor The Floor object
   */
  floorToMultiPartForm(floor: Floor) {
    const httpHeaders = new HttpHeaders({
      "enctype": "multipart/form-data",
      'Content-Type': 'multipart/form-data'
    });

    const options = {
          headers: httpHeaders
        };

    const floorPost = new FormData();

    if (floor.floorId) {
      floorPost.append('floorId', floor.floorId);
    }
    if (floor.floorName) {
      floorPost.append('floorName', floor.floorName);
    }
    floorPost.append('geojson', floor.geojson);
    if (floor.venueId) {
      floorPost.append('venueId', floor.venueId);
    }
    if (floor.floorplanImg) {
      floorPost.append('floorplanImg', floor.floorplanImg);
    }
    if (floor.floorplanAddress) {
      floorPost.append('floorplanAddress', floor.floorplanAddress);
    }
    if (floor.floorplanDelete) {
      floorPost.append('floorplanDelete', `${floor.floorplanDelete}`);
    }

    floorPost.append('floorNumber', `${floor.floorNumber}`);
    floorPost.append('xMaxExtent', `${floor.xMaxExtent}`);
    floorPost.append('xMinExtent', `${floor.xMinExtent}`);
    floorPost.append('yMaxExtent', `${floor.yMaxExtent}`);
    floorPost.append('yMinExtent', `${floor.yMinExtent}`);
    floorPost.append('z_ref', `${floor.z_ref}`);

    return {floorPost, options};
  }

  /**
   * Convert a floor object to a multipart form object for json and binary transfer
   *
   * @param floor The Floor object
   */
  floorFilesToMultiPartForm(file: File) {
    const httpHeaders = new HttpHeaders({
      "enctype": "multipart/form-data",
      'Content-Type': 'multipart/form-data'
    });

    const options = {
          headers: httpHeaders
        };

    const floorPost = new FormData();

    floorPost.append('file', file);

    return {floorPost, options};
  }

  /**
   * Update a unit
   *
   * @param organization The selected organization
   * @param venueId the venue id
   * @param unitId the unit id
   * @param unit the unit changes
   */
  async updateUnit(organization: string, venueId: string, unitId: string, unit: Unit) {
    const unitUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/units/' + unitId;
    try {
      await this.http.put<any>(unitUrl, unit).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      return error.error.message;
    }
  }

  /**
   * Create a unit
   *
   * @param organization The selected organization
   * @param venueId the venue id
   * @param unit the unit changes
   */
  async createUnit(organization: string, venueId: string, unit: Unit) {
    const unitUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/units';
    try {
      await this.http.post<any>(unitUrl, unit).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      return error.error.message;
    }
  }

  /**
   * Delete a unit
   *
   * @param organization The selected organization
   * @param venueId the venue id
   * @param unitId the unit id
   */
   async deleteUnit(organization: string, venueId: string, unitId: string) {
    const unitUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/units/' + unitId;
    try {
      await this.http.delete<any>(unitUrl).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      return error.error.message;
    }
  }

  /**
   * Create a dome light document
   * @param organization org id
   * @param venueId venue id
   * @param domeLight dome light object
   * @returns 
   */
  async createDomeLight(organization: string, venueId: string, domeLight: DomeLight) {
    const domeLightUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/dome-lights';
    try {
      await this.http.post<any>(domeLightUrl, domeLight).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      return error.error.message;
    }
  }

  /**
   * Update a dome light document
   * @param organization org id
   * @param venueId venue id
   * @param domeLightId dome light ID
   * @param domeLight dome light object
   * @returns 
   */
  async updateDomeLight(organization: string, venueId: string, domeLightId: string, domeLight: DomeLight) {
    const domeLightUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/dome-lights/' + domeLightId;
    try {
      await this.http.put<any>(domeLightUrl, domeLight).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      return error.error.message;
    }
  }

  /**
   * Update a dome light document
   * @param organization org id
   * @param venueId venue id
   * @param domeLightId dome light ID
   * @param active boolean to turn on or off
   * @returns 
   */
  async publishToDomeLightGlobal(organization: string, venueId: string, domeLightId: string, active: boolean) {
    const domeLightUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/dome-lights/' + domeLightId + '/override';
    try {
      await this.http.put<any>(domeLightUrl, {active: active}).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      return error.error.message;
    }
  }

  /**
   * Delete a dome light document
   * @param organization org id
   * @param venueId venue id
   * @param domeLightId dome light ID
   * @returns 
   */
  async deleteDomeLight(organization: string, venueId: string, domeLightId: string) {
    const domeLightUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/dome-lights/' + domeLightId;
    try {
      await this.http.delete<any>(domeLightUrl).toPromise();
      return null;
    } catch (error) {
      console.log(error);
      return error.error.message;
    }
  }

  /**
   * check for open notifications
   * @param organization org id
   * @param venueId venue id
   * @param floorId floor id
   * @param geofenceNames geofence name strings
   * @returns 
   */
  async fetchDomeLightNotifications(organization: string, venueId: string, floorId: string, geofenceNames: string[]): Promise<number> {
    const notificationUrl = 'organizations/' + organization + '/venues/' + venueId + '/notifications';
    try {
      const notificationDocs = await this.afs.collection<any>(notificationUrl, ref => ref
        .where('geofence', 'in', geofenceNames)
        .where('clearedTime', '==', null)
        .where('floorId', '==', floorId))
        .get().toPromise();
        return notificationDocs.size;
    } catch (error) {
      throw error;
    }
  }

  async fetchPositions(organization: string): Promise<Position[]> {
    const positionsCollection = this.afs.collection<Position>('organizations/' + organization + '/positions');
    const positionDocs = await positionsCollection.get().toPromise();
    return positionDocs.docs.map(doc => 
      {
        return {id: doc.id, ...doc.data()}
      });
  }

  async fetchRoles(): Promise<Role[]> {
    const rolesCollection = this.afs.collection<Role>('roles');
    const rolesDocs = await rolesCollection.get().toPromise();
    return rolesDocs.docs.map(doc => 
      {
        return {id: doc.id, ...doc.data()}
      });
  }

  /**
   * Get the sensors for a venue
   *
   * @param venueId The venue ID used to query sensors
   */
   async fetchSensors(organization: string, venueId: string) {

    try {
      this.store.dispatch(new SetIsLoading(true));
      const metadataDocs = await this.afs.collection<{fw_num: string, fw_url: string}>('firmware-metadata', ref => ref.orderBy('fw_num')).get().toPromise();
      const stableVersion = await this.afs.collection<{fw_ver: string, fw_url: string}>('firmware-metadata').doc('tag-stable').get().toPromise();
      const profiles = await this.afs.collection<any>('organizations/' + organization + '/profiles', ref => ref.orderBy('sensorId')).get().toPromise();
      
      const metadata = metadataDocs.docs.map(doc => {
        const fwDisplayName = doc.id.replace('tag-', '').replace('-rc', '').replace('-dev', '').replace('stable', '1.x.x');
        return {label: fwDisplayName, value: doc.data().fw_num, stable: false, latest: fwDisplayName.includes(FIRMWARE_LATEST) }
      });
      if (stableVersion.exists) {
        const idx = metadata.findIndex(d => d.value === stableVersion.data().fw_ver);
        if (idx >= 0) {
          metadata[idx].stable = true;
        } else {
          metadata.push({label: '1.x.x', value: stableVersion.data().fw_ver, stable: true, latest: false});
        }
      }
      
      const wearablesList: Wearable[] = [];
      const wearablesCollection = this.afs.collection<Wearable>('tags',
      ref => ref
        .where('metadata.venue', '==', venueId));
      this.wearablesSubscription = wearablesCollection.get().subscribe( querySnapshot => {
        for (const currentWearable of querySnapshot.docs) {
          const firmware = metadata.find(d => d.value === currentWearable.data().metadata.fw_ver);
          const profile = profiles.docs?.filter(prof => prof.data().sensorId === currentWearable.id).map(pr => {return {id: pr.id, ...pr.data()}});
          wearablesList.push({
            id: currentWearable.id,
            ... currentWearable.data(),
            firmware: firmware?.label ? firmware?.label : currentWearable.data().metadata.fw_ver,
            latestFirmwareVersion: firmware?.latest,
            stableFirmwareVersion: firmware?.stable,
            profileLabel: profile?.length > 1 ? profile.map(pr => pr.label).join(', ') : profile[0]?.label,
            profileType: profile?.length > 1 ? profile.map(pr => pr.profileType).join(', ') : profile[0]?.profileType,
            profileId: profile?.length > 1 ? profile.map(pr => pr.id).join(', ') : profile[0]?.id,
          });
        }

        this.store.dispatch(new SetWearables(wearablesList));
      });
    } catch (error) {
      console.log(error);
    }
  }

  downloadFile(fileName, data) {
    // wrapped special chars in quotes
    data.forEach((row, i) => {
      row.forEach((el, j) => {
        if (el && (el.indexOf(",") > -1 || el.indexOf("\n") > -1)) {
          data[i][j] = '"' + el + '"';
        }
      });
    });
    const a = document.createElement('a');
    const blob = new Blob([data.map(e => e.join(",")).join("\n")], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  /**
   * Save a magic plan file - promise resolves to the file URL
   *
   * @param organization The organization associated with the profile
   * @param venueId The venue ID
   * @param file The magic plan file to store
   * @param fileType DXF or Image type metadata
   * @param route If provided, the view to navigate to when complete
   */
  async storeMagicPlanFile(organization: string, venueId: string,  file: File, fileType: string): Promise<string> {
    const fileName = file.name;
    const dxfSize = file.size;
    const dxfText = await file.text();

    if (dxfSize > DXF_SIZE_LIMIT) {
      throw Error('File is too large');
    }
    const floorsUrl = API_URL_PREFIX + organization + '/venues/' + venueId + '/floors/upload/file';
    const {floorPost, options} = this.floorFilesToMultiPartForm(file);
    const result = await this.http.post<{ message: string, fileName: string }>(floorsUrl, floorPost, options).toPromise();
    const storedName = result.fileName;
    if (!storedName) {
      throw Error('Failed to store file');
    }
    return storedName;
  }

  cancelDomeLightSubscription() {
    if (this.domeLightSubscription) {
      this.domeLightSubscription.unsubscribe();
      this.domeLightSubscription = null;
    }
  }

  cancelNcsProfileSubscription() {
    if (this.ncsProfileSubscription) {
      this.ncsProfileSubscription.unsubscribe();
      this.ncsProfileSubscription = null;
    }
  }

  cancelVenueSubscriptions() {
    if (this.unitsSubscription) {
      this.unitsSubscription.unsubscribe();
      this.unitsSubscription = null;
    }
    if (this.domeLightSubscription) {
      this.domeLightSubscription.unsubscribe();
      this.domeLightSubscription = null;
    }
    if (this.ncsProfileSubscription) {
      this.ncsProfileSubscription.unsubscribe();
      this.ncsProfileSubscription = null;
    }
    if (this.wearablesSubscription) {
      this.wearablesSubscription.unsubscribe();
      this.wearablesSubscription = null;
    }
  }

}
