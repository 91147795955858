import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-z-height-dialog',
  templateUrl: './confirm-z-height-dialog.component.html',
  styleUrls: ['./confirm-z-height-dialog.component.scss']
})
export class ConfirmZHeightDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data,
              private dialogRef: MatDialogRef<ConfirmZHeightDialogComponent>) {}

  ngOnInit() {

  }

  onConfirm() {
    this.dialogRef.close(true);
  }

}
