<div class="md-card">
  <div class="md-card-heading bg-primary shadow-z1">
      <div class="md-card-title">Modify Geofence</div>
  </div>
</div>

<mat-dialog-content>
  <div class="warning-message">
    <em class="fa fa-exclamation-triangle"></em>
    This action allows you to edit or delete selected geofences.
  </div>
  <div class="list-group-item" *ngFor="let geofence of (selectedGeofences$ | async)">
    <div class="md-card-body">
      <div class="clearfix">
        <div class="float-left">
          <p class="m-0">
            <span>Name: {{ geofence.name }}</span>
          </p>
          <p class="m-0">
            <span>Type: {{ geofence.type }}</span>
          </p>
          <p class="m-0" *ngIf="geofence.activity">
            <span>Activity: {{ geofence.activity }}</span>
          </p>
        </div>
        <div class="float-right">
          <button class="btn btn-sm btn-primary" matTooltip="{{ 'common.EDIT_DETAILS' | translate }}"
            (click)="onEditDetails(geofence.geofenceId, geofence.name, geofence.type, geofence.activity)">
            <em class="fa fa-pencil-alt"></em>
          </button>
          <button class="btn btn-sm btn-primary" matTooltip="{{ 'common.EDIT_GEOMETRY' | translate }}"
            (click)="onEditGeometry(geofence.geofenceId)">
            <em class="fa fa-vector-square"></em>
          </button>
          <button class="btn btn-sm btn-primary" matTooltip="{{ 'common.DUPLICATE_GEOFENCE' | translate }}"
            (click)="onDuplicateGeometry(geofence.geofenceId)">
            <em class="fa fa-copy"></em>
          </button>
          <button class="btn btn-sm btn-danger" matTooltip="{{ 'common.DELETE' | translate }}"
            (click)="onDeleteGeofence(geofence.geofenceId)">
            <em class="fa fa-trash"></em>
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button type='button' class="ml-auto" cdkFocusInitial mat-dialog-close>Cancel</button>
</mat-dialog-actions>


