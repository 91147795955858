import { SortObj } from '../listview-state.model';

/**
 * Action dispatched to set search filter
 */
export class SetSupportRequestFilter {
  static type = '[SupportRequestListView] SetSupportRequestFilter';
  constructor(public filter: string) {}
}

/**
 * Action dispatched to set page index
 */
export class SetSupportRequestPageIndex {
  static type = '[SupportRequestListView] SetSupportRequestPageIndex';
  constructor(public pageIndex: number) {}
}

/**
 * Action dispatched to set page size
 */
export class SetSupportRequestPageSize {
  static type = '[SupportRequestListView] SetSupportRequestPageSize';
  constructor(public pageSize: number) {}
}

/**
 * Action dispatched to set sort order
 */
export class SetSupportRequestSort {
  static type = '[SupportRequestListView] SetSupportRequestSort';
  constructor(public sort: SortObj) {}
}

/**
 * Action dispatched to reset the SupportRequestListView state to default
 */
export class ResetSupportRequestListView {
  static type = '[SupportRequestListView] ResetSupportRequestListView';
}
