<!-- START Top Navbar-->
<nav class="navbar topnavbar" role="navigation">

  <!-- START Left navbar-->
  <ul class="navbar-nav mr-auto flex-row">
    <li class="nav-item">
      <!-- Button used to collapse the left sidebar. Only visible on tablet and desktops-->
      <a *ngIf="!isCollapsedText()" class="nav-link d-none d-md-block d-lg-block d-xl-block menu-toggle"
          matTooltip="{{ 'header.MENU' | translate }}" trigger-resize="" (click)="toggleCollapsedSideabar()" >
        <em class="fas fa-bars"></em>
      </a>

      <!-- Button to show/hide the sidebar on mobile. Visible on mobile only.-->
      <a class="nav-link sidebar-toggle d-md-none" (click)="settings.toggleLayoutSetting('asideToggled'); $event.stopPropagation()">
        <em class="fas fa-bars"></em>
      </a>
    </li>

    <!-- Logo -->
    <li class="nav-item">
      <a class="nav-link" routerLink="/">
        <div class="brand-logo">
          <img class="img-fluid" src="assets/img/logo.png" alt="Tenera Logo" />
        </div>
      </a>
    </li>
  </ul>
  <!-- END Left navbar-->

  <!-- START Right Navbar-->
  <ul class="navbar-nav flex-row">
    <!-- START Location menu-->
    <li class="nav-item dropdown dropdown-list" dropdown>
        <a class="nav-link dropdown-toggle" matTooltip="{{ 'header.ORGANIZATIONS' | translate }}" dropdownToggle>
          <span class="selected-org">{{ selectedOrganizationName$ | async }}</span>
        </a>
        <!-- START Dropdown menu-->
        <div *dropdownMenu class="dropdown-menu dropdown-menu-right animated flipInX">
          <div class="dropdown-item">
            <!-- START list group-->
            <div class="list-group">
              <!-- organization list item-->
              <div class="list-group-item list-group-item-action" *ngFor="let organization of organizations$ | async"
                  (click)="setSelectedOrganization(organization)">
                <div class="media">
                  <div class="media-body">
                    <p class="m-0">{{ organization.name }}</p>
                  </div>
                  <div class="align-self-start mr-2">
                    <em *ngIf="(selectedOrganization$ | async) === organization.id" class="fa fa-check text-success"></em>
                  </div>
                </div>
              </div>
              <!-- <div class="list-group-item list-group-item-action new-org" (click)="manageOrganizations()">
                <div class="media">
                  <div class="media-body">
                    <p class="m-0">Manage Organizations</p>
                  </div>
                </div>
              </div> -->
              <!-- END organization list item-->
            </div>
            <!-- END list group-->
          </div>
        </div>
        <!-- END Dropdown menu-->
      </li>
      <!-- END Location menu-->
    <!-- Fullscreen (only desktops)-->
    <li class="nav-item d-none d-md-block">
      <a class="nav-link" #fsbutton matTooltip="{{ 'header.FULLSCREEN' | translate }}" (click)="toggleFullScreen($event)">
        <em class="fa fa-expand"></em>
      </a>
    </li>

    <!-- Logout -->
    <li class="nav-item">
      <a class="nav-link" matTooltip="{{ 'header.SIGNOUT' | translate }}" (click)="logout()">
        <em class="fa fa-sign-out-alt"></em>
      </a>
    </li>
  </ul>
  <!-- END Right Navbar-->

</nav>
<!-- END Top Navbar-->
