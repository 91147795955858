<div class="md-card">
  <div class="md-card-heading bg-primary shadow-z1">
      <div class="md-card-title">Modify Unit Geofence</div>
  </div>
</div>

<mat-dialog-content>
  <div class="warning-message">
    <em class="fa fa-exclamation-triangle"></em>
    This action allows you to edit the name or geometry of a unit geofence.
  </div>
    <div class="md-card-body">
      <div class="clearfix">
        <div class="float-left">
          <p class="m-0">
            <span>Name: {{ geofence.geofenceName }}</span>
          </p>
        </div>
        <div class="float-right">
          <button class="btn btn-sm btn-primary" matTooltip="{{ 'common.EDIT_DETAILS' | translate }}"
            (click)="onEditDetails(geofence.geofenceId, geofence.geofenceName)">
            <em class="fa fa-pencil-alt"></em>
          </button>
          <button class="btn btn-sm btn-primary" matTooltip="{{ 'common.EDIT_GEOMETRY' | translate }}"
            (click)="onEditGeometry(geofence.geofenceId)">
            <em class="fa fa-vector-square"></em>
          </button>
        </div>
      </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button type='button' class="ml-auto" cdkFocusInitial mat-dialog-close>Cancel</button>
</mat-dialog-actions>


