import { Injectable } from '@angular/core';
import { State, Selector, StateContext, Action } from '@ngxs/store';

import * as UnitsListViewActions from './unit-listview-state.actions';
import { ListViewStateModel } from '../listview-state.model';

/**
 * State class for list views
 */
@Injectable()
@State<ListViewStateModel>({
  name: 'unitListview',
  defaults: {
    filter: '',
    pageIndex: 0,
    pageSize: 10,
    sort: { active: 'name', direction: 'asc' }
  }
})
export class UnitListViewState {

  /**
   * Get the filter string applied to the unit list
   *
   * @returns UnitListViewState.filter
   */
  @Selector()
  static filter(state: ListViewStateModel): string {
    return state.filter;
  }

  /**
   * Get the sort property applied to the unit list
   *
   * @returns UnitListViewState.sort.active
   */
  @Selector()
  static sortActive(state: ListViewStateModel): string {
    return state.sort.active;
  }

  /**
   * Get the sort direction applied to the unit list
   *
   * @returns UnitListViewState.sort.direction
   */
  @Selector()
  static sortDirection(state: ListViewStateModel): string {
    return state.sort.direction;
  }

  /**
   * Get the page index of the unit list
   *
   * @returns UnitListViewState.pageIndex
   */
  @Selector()
  static pageIndex(state: ListViewStateModel): number {
    return state.pageIndex;
  }

  /**
   * Get the page size of the unit list
   *
   * @returns UnitListViewState.pageIndex
   */
  @Selector()
  static pageSize(state: ListViewStateModel): number {
    return state.pageSize;
  }

  /**
   * Action handler - set filter
   */
  @Action(UnitsListViewActions.SetUnitFilter)
  onSetUnitsFilter(ctx: StateContext<ListViewStateModel>, { filter }: UnitsListViewActions.SetUnitFilter) {
    ctx.patchState({ filter });
  }

  /**
   * Action handler - set page index
   */
  @Action(UnitsListViewActions.SetUnitPageIndex)
  onSetUnitsPageIndex(ctx: StateContext<ListViewStateModel>, { pageIndex }: UnitsListViewActions.SetUnitPageIndex) {
    ctx.patchState({ pageIndex });
  }

  /**
   * Action handler - set page size
   */
  @Action(UnitsListViewActions.SetUnitPageSize)
  onSetUnitsPageSize(ctx: StateContext<ListViewStateModel>, { pageSize }: UnitsListViewActions.SetUnitPageSize) {
    ctx.patchState({ pageSize });
  }

  /**
   * Action handler - set sort
   */
  @Action(UnitsListViewActions.SetUnitSort)
  onSetUnitsSort(ctx: StateContext<ListViewStateModel>, { sort }: UnitsListViewActions.SetUnitSort) {
    ctx.patchState({ sort });
  }

  /**
   * Action handler - reset listview
   */
  @Action(UnitsListViewActions.ResetUnitListView)
  onResetUnitsListView(ctx: StateContext<ListViewStateModel>) {
    ctx.setState({
      filter: '',
      pageIndex: 0,
      pageSize: 10,
      sort: { active: 'name', direction: 'asc' }
    });
  }

}
