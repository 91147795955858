import { SortObj } from '../listview-state.model';

/**
 * Action dispatched to set search filter
 */
export class SetVenueFilter {
  static type = '[VenueListView] SetVenueFilter';
  constructor(public filter: string) {}
}

/**
 * Action dispatched to set page index
 */
export class SetVenuePageIndex {
  static type = '[VenueListView] SetVenuePageIndex';
  constructor(public pageIndex: number) {}
}

/**
 * Action dispatched to set page size
 */
export class SetVenuePageSize {
  static type = '[VenueListView] SetVenuePageSize';
  constructor(public pageSize: number) {}
}

/**
 * Action dispatched to set sort order
 */
export class SetVenueSort {
  static type = '[VenueListView] SetVenueSort';
  constructor(public sort: SortObj) {}
}

/**
 * Action dispatched to reset the VenueListView state to default
 */
export class ResetVenueListView {
  static type = '[VenueListView] ResetVenueListView';
}
