import { Injectable } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { UnitDetailsState } from '../unit-details/state/unit-details.state';

@Injectable()
export class UnitDetailsAddFloorsFormService {

  public form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) {
    this.form = this.fb.group({
      selectedFloors: [null, Validators.required],
    });
  }

  /**
   * Set form control values with data
   *
   * @param floors The selected floors
   * @param type The geofence type
   */
  populateGeofenceDetail(floors: string): void {
    this.form.get('selectedFloors').setValue(floors);
  }

  resetForm() {
    this.form.reset();
  }

}
