import screenfull from 'screenfull';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, Select } from '@ngxs/store';

import { ConsoleState } from './../../shared/state/console/console.state';
import { CreateOrganizationDialogComponent } from '../../shared/components/create-organization-dialog/create-organization-dialog.component';
import { Location } from './../../shared/models/location.model';
import { Logout } from '../../shared/state/auth/auth-state.actions';
import { SetSelectedOrganization } from './../../shared/state/console/console-state.actions';
import { SettingsService } from '../../core/settings/settings.service';
import { ResetSupportRequestListView } from '../../shared/state/listviews/support-request/support-request-listview-state.actions';
import { ResetVenuesState } from '../../shared/state/venues/venues-state.actions';
import { ResetLocationListView } from '../../shared/state/listviews/location/location-listview-state.actions';
import { ResetRoleListView } from '../../shared/state/listviews/role/role-listview-state.actions';
import { ResetUserListView } from '../../shared/state/listviews/user/user-listview-state.actions';
import { ResetVenueListView } from '../../shared/state/listviews/venue/venue-listview-state.actions';
import { ResetIssueTrackerListView } from '../../shared/state/listviews/issue-tracker/issue-tracker-listview-state.actions';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  // Observables on console state selectors
  @Select(ConsoleState.orgAccessList) organizations$: Observable<Location[]>;
  @Select(ConsoleState.selectedOrganizationId) selectedOrganization$: Observable<string>;
  @Select(ConsoleState.selectedOrganizationName) selectedOrganizationName$: Observable<string>;

  @ViewChild('fsbutton', { static: true }) fsbutton;  // the fullscreen button

  constructor(private dialog: MatDialog, public settings: SettingsService, private store: Store) { }

  ngOnInit() {
    const ua = window.navigator.userAgent;
    if (ua.indexOf('MSIE ') > 0 || !!ua.match(/Trident.*rv\:11\./)) { // Not supported under IE
      this.fsbutton.nativeElement.style.display = 'none';
    }

    // Switch fullscreen icon indicator
    const el = this.fsbutton.nativeElement.firstElementChild;
    screenfull.on('change', () => {
      if (el) {
        el.className = screenfull.isFullscreen ? 'fa fa-compress' : 'fa fa-expand';
      }
    });

  }

  toggleCollapsedSideabar() {
    this.settings.toggleSidebar();
  }

  isCollapsedText() {
    return this.settings.getLayoutSetting('isCollapsedText');
  }

  toggleFullScreen(event) {
    if (screenfull.isEnabled) {
      screenfull.toggle();
    }
  }

  setSelectedOrganization(organization: Location) {
    // only dispatch action if organization has changed
    if (this.store.selectSnapshot(ConsoleState.selectedOrganizationId) !== organization.id) {
      this.store.dispatch(new SetSelectedOrganization(organization.id));
      this.resetViewState();
    }
  }

  createOrganization() {
    const createOrgDialogRef = this.dialog.open(CreateOrganizationDialogComponent);
    createOrgDialogRef.afterClosed().subscribe(async result => {
      if (result) {
        console.log(result);
      }
    });
  }

  logout() {
    this.store.dispatch(new Logout());
  }

  resetViewState() {
    this.store.dispatch(new ResetVenuesState());
    this.store.dispatch(new ResetLocationListView());
    this.store.dispatch(new ResetRoleListView());
    this.store.dispatch(new ResetSupportRequestListView());
    this.store.dispatch(new ResetIssueTrackerListView());
    this.store.dispatch(new ResetUserListView());
    this.store.dispatch(new ResetVenueListView());
  }

}
