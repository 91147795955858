import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { GeofenceFormService } from './geofence-form.service';

@Component({
  selector: 'app-create-geofence-dialog',
  templateUrl: './geofence-detail-dialog.component.html',
  styleUrls: ['./geofence-detail-dialog.component.scss'],
  providers: [GeofenceFormService]
})
export class GeofenceDetailDialogComponent implements OnInit {

  form: UntypedFormGroup;
  geofenceTypes: string[];
  activityTypes: string[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: {geofenceName: string, geofenceType: string, activity: string},
              private geofenceFormService: GeofenceFormService,
              private dialogRef: MatDialogRef<GeofenceDetailDialogComponent>) {}

  ngOnInit() {
    this.form = this.geofenceFormService.form;
    this.geofenceTypes = this.geofenceFormService.geofenceTypes;
    this.activityTypes = this.geofenceFormService.activityTypes;
    if (this.data) {
      this.geofenceFormService.populateGeofenceDetail(this.data.geofenceName, this.data.geofenceType, this.data.activity);
    }
    this.onGeofenceTypeChange(this.form);
  }

  onGeofenceTypeChange(form: UntypedFormGroup) {
    if (form.value.geofenceType === 'Common')
      form.get('activity').enable();
    else 
      form.get('activity').disable();
  }

  submit(form: UntypedFormGroup) {
    if (form.value.geofenceType !== 'Common') {
      form.value.activity = null;
    }
    this.dialogRef.close(form.value);
  }

}
