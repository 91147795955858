import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup } from '@angular/forms';

import { UnitGeofenceFormService } from './unit-geofence-form.service';

@Component({
  selector: 'app-create-unit-geofence-dialog',
  templateUrl: './unit-geofence-detail-dialog.component.html',
  styleUrls: ['./unit-geofence-detail-dialog.component.scss'],
  providers: [UnitGeofenceFormService]
})
export class UnitGeofenceDetailDialogComponent implements OnInit {

  form: UntypedFormGroup;
  geofenceTypes: string[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: {geofenceName: string, geofenceType: string},
              private geofenceFormService: UnitGeofenceFormService,
              private dialogRef: MatDialogRef<UnitGeofenceDetailDialogComponent>) {}

  ngOnInit() {
    this.form = this.geofenceFormService.form;
    if (this.data) {
      this.geofenceFormService.populateGeofenceDetail(this.data.geofenceName);
    }
  }

  submit(form) {
    this.dialogRef.close(form.value);
  }

}
