import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-modify-unit-geofence-dialog',
  templateUrl: './modify-unit-geofence-dialog.component.html',
  styleUrls: ['./modify-unit-geofence-dialog.component.scss']
})
export class ModifyUnitGeofenceDialogComponent {

  // Observables on state selectors
  geofence: {geofenceName: string, geofenceId: number};

  constructor(@Inject(MAT_DIALOG_DATA) public data: {geofenceName: string, geofenceId: number},
              private dialogRef: MatDialogRef<ModifyUnitGeofenceDialogComponent>) {
    this.geofence = {geofenceName: data.geofenceName, geofenceId: data.geofenceId};
   }
  /**
   * Respond to edit details button pressed on the modify geofence dialog
   *
   * @param geofenceId The unique database object identifier
   * @param geofenceName The geofence name
   */
  onEditDetails(geofenceId: number, geofenceName: string): void {
    this.dialogRef.close({
      action: 'details',
      geofenceId,
      geofenceName
    });
  }

  /**
   * Respond to edit geometry button pressed on the modify geofence dialog
   *
   * @param geofenceId The unique database object identifier
   */
  onEditGeometry(geofenceId: number): void {
    this.dialogRef.close({
      action: 'geometry',
      geofenceId
    });
  }
}
