import { SetIsLoading } from './../../../shared/state/console/console-state.actions';
import { State, Selector, Action, StateContext, Store } from '@ngxs/store';

import * as VenuesActions from './venues-state.actions';
import { ConsoleState } from './../../../shared/state/console/console.state';
import { Floor } from './../../../routes/venues/models/floor.model';
import { FloorListItem } from './../../../routes/venues/models/floor-list-item.model';
import { Geofence } from './../../../routes/venues/geofences/models/geofence.model';
import { LocationsService } from './../../../shared/services/locations.service';
import { Logout } from './../../../shared/state/auth/auth-state.actions';
import { Unit } from './../../../routes/venues/models/unit.model';
import { Venue } from './../../../routes/venues/models/venue.model';
import { VenuesService } from './../../../routes/venues/venues.service';
import { VenuesStateModel } from './venues-state.model';
import { Injectable } from '@angular/core';
import { Wearable } from '../../models/wearable.model';
import { DomeLight } from '../../../routes/venues/models/dome-light.model';
import { NcsProfile } from '../../../shared/models/ncs-profile.models';

/**
 * State class for venues
 */
@State<VenuesStateModel>({
  name: 'venues',
  defaults: {
    floors: [],
    units: [],
    venueList: [],
    wearables: null,
    domeLights: null,
    ncsProfiles: null
  }
})
@Injectable()
export class VenuesState {

  /**
   * Get the list of floors that are available for the selected venue
   *
   * @returns VenuesState.floors
   */
  @Selector()
  static floors(state: VenuesStateModel): FloorListItem[] {
    return state.floors;
  }

  /**
   * Get data for the selected floor
   *
   * @returns VenueState.floorData
   */
  @Selector()
  static floorData(state: VenuesStateModel): Floor {
    return state.floorData;
  }

  /**
   * Get the selected floor
   *
   * @returns VenueState.selectedFloor
   */
  @Selector()
  static selectedFloor(state: VenuesStateModel): FloorListItem {
    return state.selectedFloor;
  }

  /**
   * Get the name of the selected floor
   *
   * @returns VenueState.selectedFloor.floorName
   */
  @Selector()
  static selectedFloorName(state: VenuesStateModel): string {
    return state.selectedFloor.floorName;
  }

  /**
   * Get the selected unit
   *
   * @returns VenueState.selectedUnit
   */
  @Selector()
  static selectedUnit(state: VenuesStateModel): Unit {
    return state.selectedUnit;
  }

  /**
   * Get the name of the selected unit
   *
   * @returns VenueState.selectedUnit.name
   */
  @Selector()
  static selectedUnitName(state: VenuesStateModel): string {
    return state.selectedUnit.name;
  }

  /**
   * Get the floors comprising a unit
   *
   * @returns VenueState.selectedUnit.floors
   */
  @Selector()
  static selectedUnitFloors(state: VenuesStateModel): FloorListItem[] {
    return state.selectedUnit.floors;
  }

  /**
   * Get the list of units that are available for the selected venue
   *
   * @returns VenueState.units
   */
  @Selector()
  static units(state: VenuesStateModel): Unit[] {
    return state.units;
  }

  /**
   * Get the list of wearables that are available for the selected venue
   *
   * @returns VenueState.wearables
   */
  @Selector()
  static wearables(state: VenuesStateModel): Wearable[] {
    return state.wearables;
  }

  /**
   * Get the list of domeLights that are available for the selected venue
   *
   * @returns VenueState.domeLights
   */
  @Selector()
  static domeLights(state: VenuesStateModel): DomeLight[] {
    return state.domeLights;
  }

  /**
   * Get the list of domeLights that are available for the selected venue
   *
   * @returns VenueState.domeLights
   */
  @Selector()
  static ncsProfiles(state: VenuesStateModel): NcsProfile[] {
    return state.ncsProfiles;
  }

  /**
   * Get whether the list of units is empty
   *
   * @returns VenueState.units.length is 0
   */
  @Selector()
  static unitsIsEmpty(state: VenuesStateModel): boolean {
    return state.units.length === 0;
  }

  /**
   * Get the unit geofence
   *
   * @returns VenueState.unitGeofence
   */
  @Selector()
  static unitGeofence(state: VenuesStateModel): Geofence {
    return state.unitGeofence;
  }

  /**
   * Get the selected venue ID
   *
   * @returns VenuesState.selectedVenueId
   */
  @Selector()
  static selectedVenueId(state: VenuesStateModel): string {
    return state.selectedVenueId;
  }

  /**
   * Get the list of venues available to the logged in user
   *
   * @returns VenuesState.venueList
   */
  @Selector()
  static venueList(state: VenuesStateModel): Venue[] {
    return state.venueList;
  }

  /**
   * Get the list of venues available to the logged in user
   *
   * @returns VenuesState.venueList
   */
  @Selector()
  static monitoringMapPosition(state: VenuesStateModel): number[] {
    return state.monitoringMapPosition;
  }

  constructor(
    private locationsService: LocationsService,
    private venuesService: VenuesService,
    private store: Store) { }

  /**
   * Action handler - set the floor list
   */
  @Action(VenuesActions.SetFloorList)
  onSetFloorList(ctx: StateContext<VenuesStateModel>, action: VenuesActions.SetFloorList) {
    const lastSelectedFloor = localStorage.getItem('selectedFloor');
    let defaultFloor = action.floorList[0];
    for (const currentFloor of action.floorList) {
      if (currentFloor.floorId === lastSelectedFloor) {
        defaultFloor = currentFloor;
      }
    }
    ctx.patchState({
      floors: action.floorList
    });
    if (defaultFloor) {
      ctx.dispatch(new VenuesActions.SetSelectedFloor(defaultFloor));
      this.venuesService.fetchUnits(
        this.store.selectSnapshot(ConsoleState.selectedOrganizationId),
        this.store.selectSnapshot(VenuesState.selectedVenueId));
    } else {
      this.store.dispatch(new SetIsLoading(false));
    }
  }

  /**
   * Action handler - process the selected floor
   */
  @Action(VenuesActions.ProcessSelectedFloor)
  onProcessSelectedFloor(ctx: StateContext<VenuesStateModel>, { floorId }: VenuesActions.ProcessSelectedFloor ) {
    const state = ctx.getState();
    const floors = [...state.floors];
    const index = floors.findIndex(x => x.floorId === floorId);
    const selectedFloor = floors[index];

    // determine whether to set a different selected unit
    // get the selectedUnit and see whether it spans the selected floor
    let shouldChangeUnit = true;
    let unitToSelect: Unit;
    const selectedUnit = this.store.selectSnapshot(VenuesState.selectedUnit);
    if (selectedUnit && selectedFloor) {
      selectedUnit.floors.forEach(unitFloor => {
        if (unitFloor.floorId === selectedFloor.floorId) {
          shouldChangeUnit = false;
        }
      });
    }
    if (shouldChangeUnit) { // find a unit on the selected floor
      const units = [...state.units];
      units.forEach(unit => {
        unit.floors.forEach(unitFloor => {
          if (unitFloor.floorId === selectedFloor.floorId) {
            unitToSelect = unit;
          }
        });
      });
    }

    // dispatch actions to set state
    this.store.dispatch(new VenuesActions.SetSelectedFloor(selectedFloor));
    if (selectedUnit && shouldChangeUnit && unitToSelect) {
      this.store.dispatch(new VenuesActions.SetByproductUnit(unitToSelect));
    }
  }

  /**
   * Action handler - set the selected floor
   */
  @Action(VenuesActions.SetSelectedFloor)
  onSetSelectedFloor(ctx: StateContext<VenuesStateModel>, { floor }: VenuesActions.SetSelectedFloor ) {
    localStorage.setItem('selectedFloor', floor.floorId);
    const state = ctx.getState();
    const currentFloor = state.selectedFloor;
    ctx.patchState({
      selectedFloor: floor,
    });
    if (!floor || (currentFloor && currentFloor.floorId != floor.floorId)) {
      ctx.patchState({
        monitoringMapPosition: null
      });
    }
    this.venuesService.fetchFloor(this.store.selectSnapshot(ConsoleState.selectedOrganizationId), state.selectedVenueId, floor.floorId);
  }

  /**
   * Action handler - set the selected floor as a byproduct of some other action
   */
  @Action(VenuesActions.SetByproductFloor)
  onSetByproductFloor(ctx: StateContext<VenuesStateModel>, { floor }: VenuesActions.SetByproductFloor ) {
    localStorage.setItem('selectedFloor', floor.floorId);
    const state = ctx.getState();
    ctx.patchState({
      selectedFloor: floor
    });
    this.venuesService.fetchFloor(this.store.selectSnapshot(ConsoleState.selectedOrganizationId), state.selectedVenueId, floor.floorId);
  }

  /**
   * Action handler - set the selected floor data
   */
  @Action(VenuesActions.SetFloorData)
  onSetFloorData(ctx: StateContext<VenuesStateModel>, action: VenuesActions.SetFloorData) {
    ctx.patchState({
      floorData: action.floor
    });
  }

  /**
   * Action handler - set the selected monitoringMapPosition
   */
  @Action(VenuesActions.SetMonitoringMapPosition)
  onSetMonitoringMapPosition(ctx: StateContext<VenuesStateModel>, action: VenuesActions.SetMonitoringMapPosition) {
    ctx.patchState({
      monitoringMapPosition: action.monitoringMapPosition
    });
  }

  /**
   * Action handler - set wearables
   */
  @Action(VenuesActions.SetWearables)
  onSetWearables(ctx: StateContext<VenuesStateModel>, action: VenuesActions.SetWearables) {
    // set the wearables list
    ctx.patchState({
      wearables: action.wearables
    });
  }

  /**
   * Action handler - set domeLights
   */
  @Action(VenuesActions.AddDomeLight)
  onAddDomeLight(ctx: StateContext<VenuesStateModel>, action: VenuesActions.AddDomeLight) {
    // update dome light list
    const state = ctx.getState();
    ctx.patchState({
      domeLights: [...state.domeLights ?? [], action.domeLight].sort((a, b) => {
        if (a.beaconId < b.beaconId) {
          return -1;
        }
        if ( a.beaconId > b.beaconId) {
          return 1;
        }
        return 0;
      })
    });
  }

  /**
   * Action handler - set domeLights
   */
  @Action(VenuesActions.RemoveDomeLight)
  onRemoveDomeLight(ctx: StateContext<VenuesStateModel>, action: VenuesActions.RemoveDomeLight) {
    // update dome light list
    const state = ctx.getState();
    ctx.patchState({
      domeLights: [...state.domeLights.filter(dl => dl.id != action.domeLight.id)]
    });
  }

  /**
   * Action handler - reset domeLights
   */
  @Action(VenuesActions.ResetDomeLights)
  onResetDomeLights(ctx: StateContext<VenuesStateModel>, action: VenuesActions.ResetDomeLights) {
    // update dome light list
    const state = ctx.getState();
    ctx.patchState({
      domeLights: []
    });
  }

  /**
   * Action handler - set domeLights
   */
  @Action(VenuesActions.UpdateDomeLight)
  onUpdateDomeLight(ctx: StateContext<VenuesStateModel>, action: VenuesActions.UpdateDomeLight) {
    // update dome light list
    const state = ctx.getState();
    const updated = [...state.domeLights];
    const idx = updated.findIndex(dl => dl.id === action.domeLight.id);
    updated[idx] = action.domeLight;
    ctx.patchState({
      domeLights: updated
    });
  }

  /**
   * Action handler - set ncs profiles
   */
  @Action(VenuesActions.AddNcsProfile)
  onAddNcsProfile(ctx: StateContext<VenuesStateModel>, action: VenuesActions.AddNcsProfile) {
    // update dome light list
    const state = ctx.getState();
    ctx.patchState({
      ncsProfiles: [...state.ncsProfiles ?? [], action.ncsProfile].sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if ( a.label > b.label) {
          return 1;
        }
        return 0;
      })
    });
  }

  /**
   * Action handler - set domeLights
   */
  @Action(VenuesActions.RemoveNcsProfile)
  onRemoveNcsProfile(ctx: StateContext<VenuesStateModel>, action: VenuesActions.RemoveNcsProfile) {
    // update dome light list
    const state = ctx.getState();
    ctx.patchState({
      ncsProfiles: [...state.ncsProfiles.filter(prof => prof.id != action.ncsProfile.id)]
    });
  }

  /**
   * Action handler - set domeLights
   */
  @Action(VenuesActions.UpdateNcsProfile)
  onUpdateNcsProfile(ctx: StateContext<VenuesStateModel>, action: VenuesActions.UpdateNcsProfile) {
    // update dome light list
    const state = ctx.getState();
    const updated = [...state.ncsProfiles];
    const idx = updated.findIndex(dl => dl.id === action.ncsProfile.id);
    updated[idx] = action.ncsProfile;
    ctx.patchState({
      ncsProfiles: updated
    });
  }

  /**
   * Action handler - reset ncs profiles
   */
  @Action(VenuesActions.ResetNcsProfiles)
  onResetNcsProfiles(ctx: StateContext<VenuesStateModel>, action: VenuesActions.ResetNcsProfiles) {
    // update dome light list
    const state = ctx.getState();
    ctx.patchState({
      ncsProfiles: []
    });
  }

  /**
   * Action handler - set the units
   */
  @Action(VenuesActions.SetUnits)
  onSetUnits(ctx: StateContext<VenuesStateModel>, action: VenuesActions.SetUnits) {
    // set the units in state
    ctx.patchState({
      units: action.units
    });
    // set a default selected unit
    const lastSelectedUnit = localStorage.getItem('selectedUnit');
    const selectedFloor = this.store.selectSnapshot(VenuesState.selectedFloor);
    let isDefaultUnitSet = false;
    // make sure unit is on the selected floor, if possible
    if (selectedFloor) {
      // first check to see if the last selected unit can be reset
      if (lastSelectedUnit) {
        for (const currentUnit of action.units) {
          const floors = currentUnit.floors;
          for (const currentFloor of floors) {
            if (currentFloor.floorId === selectedFloor.floorId && lastSelectedUnit === currentUnit.unitId) {
              ctx.dispatch(new VenuesActions.SetSelectedUnit(currentUnit));
              isDefaultUnitSet = true;
            }
          }
        }
      }
      // if default is not set yet, see whether there is a unit on the selected floor
      if (!isDefaultUnitSet) {
        for (const currentUnit of action.units) {
          const floors = currentUnit.floors;
          for (const currentFloor of floors) {
            if (!isDefaultUnitSet && currentFloor.floorId === selectedFloor.floorId) {
              ctx.dispatch(new VenuesActions.SetSelectedUnit(currentUnit));
              isDefaultUnitSet = true;
            }
          }
        }
      }
    }
    // no floor selected, so default to the first unit and switch floors if necessary
    if (!isDefaultUnitSet) {
      // default to the first unit
      ctx.dispatch(new VenuesActions.ProcessSelectedUnit(action.units[0].unitId));
    }
  }

  /**
   * Action handler - process the selected unit
   */
  @Action(VenuesActions.ProcessSelectedUnit)
  onProcessSelectedUnit(ctx: StateContext<VenuesStateModel>, { unitId }: VenuesActions.ProcessSelectedUnit ) {
    // locate the selected unit object
    const state = ctx.getState();
    const units = [...state.units];
    const index = units.findIndex(x => x.unitId === unitId);
    const selectedUnit = units[index];

    // determine whether to set a different selected floor
    // get the selectedFloor and see whether the selected unit spans the selected floor
    let shouldChangeFloor = true;
    const selectedFloor = this.store.selectSnapshot(VenuesState.selectedFloor);
    const unitFloors = selectedUnit.floors;
    if (selectedFloor) {
      for (const currentFloor of unitFloors) {
        if (currentFloor.floorId === selectedFloor.floorId) {
          shouldChangeFloor = false;
        }
      }
    }
    // dispatch actions to set state
    if (selectedFloor && shouldChangeFloor) {
      this.store.dispatch(new VenuesActions.SetByproductFloor(unitFloors[0]));
    }
    this.store.dispatch(new VenuesActions.SetSelectedUnit(selectedUnit));
  }

  /**
   * Action handler - set the selected unit
   */
  @Action(VenuesActions.SetSelectedUnit)
  onSetSelectedUnit(ctx: StateContext<VenuesStateModel>, { unit }: VenuesActions.SetSelectedUnit ) {
    localStorage.setItem('selectedUnit', unit.unitId);
    ctx.patchState({
      selectedUnit: unit
    });
  }

  /**
   * Action handler - set the selected unit as a result of some other action
   */
  @Action(VenuesActions.SetByproductUnit)
  onSetByproductUnit(ctx: StateContext<VenuesStateModel>, { unit }: VenuesActions.SetByproductUnit ) {
    localStorage.setItem('selectedUnit', unit.unitId);
    ctx.patchState({
      selectedUnit: unit
    });
  }

  /**
   * Action handler - set the unit geofence
   */
  @Action(VenuesActions.SetUnitGeofence)
  onSetUnitGeofence(ctx: StateContext<VenuesStateModel>, { geofence }: VenuesActions.SetUnitGeofence) {
    ctx.patchState({
      unitGeofence: geofence
    });
  }

  /**
   * Action handler - process a list of venues
   */
  @Action(VenuesActions.ProcessVenues)
  async onProcessVenues(ctx: StateContext<VenuesStateModel>,  action: VenuesActions.ProcessVenues) {
    const lastSelectedVenue = localStorage.getItem('selectedVenue');
    const selectedOrg = this.store.selectSnapshot(ConsoleState.selectedOrganizationId);
    const userLocationAccess = this.store.selectSnapshot(ConsoleState.userLocationAccess);
    const venueList: Venue[] = [];
    let defaultVenueId: string = null;

    // maintain a list of venues that the user may access
    for (const currentVenue of action.venuesList) {
      let venueLocationDoc;
      try {
        venueLocationDoc = await this.locationsService.getLocation(selectedOrg, currentVenue.id);
      } catch (error) {
        this.store.dispatch(new Logout());
      }
      if (venueLocationDoc) {
        const location = venueLocationDoc.data();
        if (location && location.access.some(r => userLocationAccess.includes(r))) { // user has access to the venue
          if (lastSelectedVenue === currentVenue.id) {
            defaultVenueId = currentVenue.id;
          }
          venueList.push(currentVenue);
        }
      }
    }
    if (!defaultVenueId && venueList && venueList.length > 0) {
      defaultVenueId = venueList[0].id;
    }

    // dispatch the list of venues that the user may access
    ctx.dispatch(new VenuesActions.SetVenues(venueList));
    ctx.dispatch(new VenuesActions.SetSelectedVenue(defaultVenueId));
  }

  /**
   * Action handler - set the venues the user may access
   */
  @Action(VenuesActions.SetVenues)
  onSetVenues(ctx: StateContext<VenuesStateModel>, action: VenuesActions.SetVenues) {
    ctx.patchState({
      venueList: action.venues
    });
  }

  /**
   * Action handler - set the selected venue
   */
  @Action(VenuesActions.SetSelectedVenue)
  async onSetSelectedVenue(ctx: StateContext<VenuesStateModel>, { venueId }: VenuesActions.SetSelectedVenue) {
    const state = ctx.getState();
    const currentVenueId = state.selectedVenueId;
    localStorage.setItem('selectedVenue', venueId);
    ctx.patchState({
      floors: [],
      floorData: null,
      selectedFloor: null,
      selectedUnit: null,
      selectedVenueId: venueId,
      units: [],
      unitGeofence: null,
    });
    if (currentVenueId != venueId) {
      ctx.patchState({
        monitoringMapPosition: null
      });
    }
    if (venueId) {
      this.venuesService.fetchFloors(this.store.selectSnapshot(ConsoleState.selectedOrganizationId), venueId);
    } else {
      this.store.dispatch(new VenuesActions.SetFloorList([]));
    }
  }

  /**
   * Action handler - reset the venues state to default
   */
  @Action(VenuesActions.ResetVenuesState)
  onResetVenuesState(ctx: StateContext<VenuesStateModel>) {
    ctx.setState({
      floors: [],
      units: [],
      venueList: [],
      monitoringMapPosition: null,
      wearables: null,
      domeLights: null,
      ncsProfiles: null
    });
    this.venuesService.cancelVenueSubscriptions();
  }


}
